import React from 'react';
import { useMutation } from 'react-apollo';

import Signup from './Components/SignupDetails';
import { SIGNUP } from './Signup-gql';
import Container from '../../Components/Container';

export default () => {
  const [signup] = useMutation(SIGNUP);

  return (
    <Container>
      <Signup signup={signup} />
    </Container>
  );
};
