import {
  LOGIN,
  LOGOUT,
  LANGUAGE,
  CHANGE_CURRENCY,
  SIGNUP_DATA,
  SIGNUP_CLEAR,
  ADD_LENGTH,
} from '../Action';

const initialState = {
  user: {},
  language: 'en',
  currency: {
    value: 'usd',
    label: 'USA',
  },
  productLength: 0,
  signupData: {
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    password: '',
    confirmPassword: '',
    country: '',
    privacyPolicy: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        user: payload.user,
      };
    case LOGOUT:
      return {
        ...state,
        user: {},
      };
    case LANGUAGE: {
      return {
        ...state,
        language: payload.lang,
      };
    }
    case CHANGE_CURRENCY:
      return {
        ...state,
        currency: payload,
      };
    case SIGNUP_DATA:
      return {
        ...state,
        signupData: payload,
      };
    case SIGNUP_CLEAR:
      return {
        ...state,
        signupData: {},
      };
    case ADD_LENGTH:
      return {
        ...state,
        productLength: payload,
      };
    default:
      return state;
  }
};
