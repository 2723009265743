import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import ar from 'react-phone-input-2/lang/ar.json';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';

import { Translate } from 'react-localize-redux';
import { toast } from 'react-toastify';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import BG from '../../../Styles/images/backgrounds/login-bg.jpg';
import { loginUser } from '../../../Action/index';
import LoadingScreen from '../../../Components/LoadingScreen';

const ValidationSchema = Yup.object().shape({
  email: Yup.string().required('email'),
  firstName: Yup.string().required('firstName'),
  lastName: Yup.string().required('lastName'),
  mobile: Yup.number().required('mobile'),
  password: Yup.string()
    .min(8)
    .required('password'),
  confirmPassword: Yup.string()
    .required('No confirm password provided')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  privacyPolicy: Yup.boolean()
    .required('The terms and conditions must be accepted.')
    .oneOf([true], 'The terms and conditions must be accepted.'),
});

const mapDispathToProps = {
  signupUser: loginUser,
};

const mapToProps = state => ({
  account: state.Account,
});

export default connect(
  mapToProps,
  mapDispathToProps,
)(({ account: { language }, signup, signupUser }) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      mobile: '',
      email: '',
      password: '',
      confirmPassword: '',
      country: '',
      privacyPolicy: false,
    },
    onSubmit: () => handleSubmits(),
    validationSchema: ValidationSchema,
  });

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = formik;

  const handleSubmits = () => {
    if (values.password === values.confirmPassword) {
      setLoading(true);
      signup({
        variables: {
          user: {
            facebook: '',
            google: '',
            account: {
              firstName: values.firstName,
              lastName: values.lastName,
              mobile: values.mobile.replace(/ /g, ''),
              country: values.country,
              email: values.email,
              password: values.password,
            },
          },
        },
      })
        .then(async ({ data: { createUser } }) => {
          if (createUser) {
            setLoading(false);
            signupUser(createUser);
            await localStorage.setItem('token', createUser.account.token);

            toast.success(<Translate id="SignupSuccessfully" />, {
              onClose: () => (window.location.href = '/'),
            });
          }
        })
        .catch(error => {
          setLoading(false);

          return error.graphQLErrors.length > 0
            ? toast.error(error.graphQLErrors[0].message)
            : toast.error(<Translate id="somethingWentWrong" />);
        });
    }
  };

  const responseFacebook = async response => {
    if (response.status === 'unknown') {
      return;
    } else {
      setLoading(true);
      await signup({
        variables: {
          user: {
            facebook: response.id,
            google: '',
            account: {
              firstName: response.first_name,
              lastName: response.last_name,
              email: response.email,
            },
          },
        },
      })
        .then(({ data: { createUser } }) => {
          if (createUser) {
            setLoading(false);
            signupUser(createUser);
            toast.success(<Translate id="SignupSuccessfully" />, {
              onClose: () => (window.location.href = '/'),
            });
          }
        })
        .catch(error => {
          setLoading(false);
          error.graphQLErrors.length > 0
            ? toast.error(error.graphQLErrors[0].message)
            : toast.error(<Translate id="somethingWentWrong" />);
        });
    }
  };

  const responseGoogle = async response => {
    if (response.error) {
      //
    } else {
      setLoading(true);
      await signup({
        variables: {
          user: {
            facebook: '',
            google: response.profileObj.googleId,
            account: {
              firstName: response.profileObj.givenName,
              lastName: response.profileObj.familyName,
              email: response.profileObj.email,
            },
          },
        },
      })
        .then(({ data: { createUser } }) => {
          if (createUser) {
            setLoading(false);
            signupUser(createUser);
            toast.success(<Translate id="SignupSuccessfully" />, {
              onClose: () => (window.location.href = '/new-product'),
            });
          }
        })
        .catch(error => {
          setLoading(false);
          error.graphQLErrors.length > 0
            ? toast.error(error.graphQLErrors[0].message)
            : toast.error(<Translate id="somethingWentWrong" />);
        });
    }
  };

  return (
    <div className="page-wrapper signup">
      <main className="main">
        {loading && <LoadingScreen />}

        <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">
                  <Translate id="home" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <Translate id="Signup" />
              </li>
            </ol>
          </div>
        </nav>

        <div
          className="login-page bg-image pt-8 pb-8 pt-md-12 pb-md-12 pt-lg-17 pb-lg-17"
          style={{ backgroundImage: `url(${BG})` }}>
          <div className="container">
            <div className="form-box">
              <div className="form-tab">
                <ul className="nav nav-pills nav-fill" role="tablist">
                  <li className="nav-item">
                    <div className="nav-link active in">
                      <Translate id="register" />
                    </div>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active in"
                    id="register-2"
                    role="tabpanel"
                    aria-labelledby="register-tab-2">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="form-group col-sm-6">
                          <label htmlFor="firstName">
                            <Translate id="firstname" /> *
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            required
                            onChange={handleChange}
                            value={values.firstName}
                          />
                        </div>

                        <div className="form-group col-sm-6">
                          <label htmlFor="lastName">
                            <Translate id="lastname" /> *
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            id="lastName"
                            name="lastName"
                            required
                            onChange={handleChange}
                            value={values.lastName}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-sm-6">
                          <label htmlFor="lastName">
                            <Translate id="mobile" /> *
                          </label>
                          <PhoneInput
                            stlye={{
                              paddingLeft: '48px',
                            }}
                            className="form-control"
                            country="jo"
                            onChange={(e, country) =>
                              setFieldValue('mobile', e) &&
                              setFieldValue('country', country.countryCode)
                            }
                            localization={language === 'ar' && ar}
                            value={values.mobile}
                            excludeCountries={['il']}
                            required
                          />
                        </div>

                        <div className="form-group col-sm-6">
                          <label htmlFor="email">
                            <Translate id="email" /> *
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            required
                            onChange={handleChange}
                            value={values.email}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col-sm-6">
                          <label htmlFor="password">
                            <Translate id="password" /> *
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            required
                            onChange={handleChange}
                            value={values.password}
                          />
                          {errors.password && touched.password && (
                            <div
                              style={{
                                color: 'red',
                              }}>
                              <Translate id="8character" />
                            </div>
                          )}
                        </div>

                        <div className="form-group col-sm-6">
                          <label htmlFor="confirmPassword">
                            <Translate id="confirmpassword" /> *
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="confirmPassword"
                            name="confirmPassword"
                            required
                            onChange={handleChange}
                            value={values.confirmPassword}
                          />
                          {errors.confirmPassword && touched.confirmPassword && (
                            <div
                              style={{
                                color: 'red',
                              }}>
                              <Translate id={errors.confirmPassword} />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-footer">
                        <button
                          type="submit"
                          className="btn btn-outline-primary-2">
                          <span>
                            <Translate id="register" />
                          </span>
                          {loading ? (
                            <i className="fa fa-spinner fa-pulse" />
                          ) : (
                            <i className="icon-long-arrow-right"></i>
                          )}
                        </button>
                        <div className="custom-control custom-checkbox">
                          <label
                            htmlFor="privacyPolicy"
                            className="container-checkbox"
                            style={{ margin: 0 }}>
                            <Translate id="agreeuseragreement" />{' '}
                            <a
                              style={{
                                color:
                                  touched.privacyPolicy && errors.privacyPolicy
                                    ? '#c10000'
                                    : '#c96',
                              }}
                              target="_blank"
                              href="/privacy-policy">
                              <Translate id="useragreement" />{' '}
                            </a>{' '}
                            *
                            <input
                              id="privacyPolicy"
                              name="privacyPolicy"
                              onChange={handleChange}
                              type="checkbox"
                            />
                            <span
                              style={{
                                borderColor:
                                  touched.privacyPolicy &&
                                  errors.privacyPolicy &&
                                  '#c10000',
                              }}
                              className="checkmark-checkbox"></span>
                          </label>
                        </div>
                      </div>

                      <div
                        className="row"
                        style={{
                          justifyContent: 'center',
                          borderBottom: '0.1rem solid #ebebeb',
                        }}>
                        <p className="text-center">
                          <Translate id="HaveAccount" />
                        </p>
                        <a
                          href="/login"
                          style={{ color: '#c96', marginLeft: '5px' }}>
                          <Translate id="Login" />
                        </a>
                      </div>
                    </form>
                    <div className="form-choice">
                      <p className="text-center">
                        <Translate id="or sign in with" />
                      </p>
                      <div className="row">
                        <div className="col-sm-6">
                          <GoogleLogin
                            clientId="42282094691-pu17lekjgvcc1426g0ggrs9doln7gpe4.apps.googleusercontent.com"
                            buttonText="LOGIN WITH GOOGLE"
                            render={renderProps => (
                              <div
                                onClick={() => renderProps.onClick()}
                                className="btn btn-login btn-g">
                                <i className="icon-google"></i>
                                <Translate id="Login With Google" />
                              </div>
                            )}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                          />
                        </div>
                        <div className="col-sm-6">
                          <FacebookLogin
                            appId="134773307824627"
                            callback={response => responseFacebook(response)}
                            fields="email,picture, first_name, last_name, local_news_megaphone_dismiss_status"
                            render={renderProps => (
                              <div
                                onClick={renderProps.onClick}
                                className="btn btn-login  btn-f">
                                <i className="icon-facebook-f"></i>
                                <Translate id="Login With Facebook" />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
});
