import gql from 'graphql-tag';
import { USER_GQL } from '../../Navigation/Router-gql';

export const LOGIN = gql`
  mutation loginUser($username: String, $password: String) {
    loginUser(username: $username, password: $password) {
      ${USER_GQL}
    }
  }
`;

export const FACEBOOK_LOGIN = gql`
  mutation loginFacebook($id: String) {
    loginFacebook(id: $id) {
      ${USER_GQL}
    }
  }
`;

export const GOOGLE_LOGIN = gql`
  mutation loginGoogle($id: String) {
    loginGoogle(id: $id) {
      ${USER_GQL}
    }
  }
`;
