import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

const ScrollUp = ({ location }) => {
  const [scroll, setScroll] = useState({
    x: document.body.getBoundingClientRect().left,
    y: document.body.getBoundingClientRect().top,
    direction: '',
  });

  const listener = e => {
    setScroll(prev => ({
      x: document.body.getBoundingClientRect().left,
      y: -document.body.getBoundingClientRect().top,
      // Here we’re comparing the previous state to the current state to get the scroll direction
      direction:
        prev.y > -document.body.getBoundingClientRect().top ? 'up' : 'down',
    }));
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }, 100);
    return () => window.removeEventListener('scroll', listener);
  }, [location]);

  return (
    <Link
      to="#"
      id={`scroll-top`}
      className={`${scroll.y >= 400 ? 'show' : ''}`}
      title="Back to Top">
      <i className="icon-arrow-up"></i>
    </Link>
  );
};

export default withRouter(ScrollUp);
