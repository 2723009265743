import React from 'react';
import { Col, Container } from 'reactstrap';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-apollo';

import { ALLPRODUCTS } from '../../AllProducts/AllProducts-gql';
import ProductCard from '../../../Components/ProductCard';
import ErrorPage from '../../../Components/ErrorPage';
import Loading from '../../../Components/Loading';

export default () => {
  const { data, error, loading } = useQuery(ALLPRODUCTS, {
    variables: {
      page: 8,
    },
  });

  return error ? (
    <ErrorPage />
  ) : loading ? (
    <Loading />
  ) : (
    <Container
      className="new-arrivals all-product"
      style={{
        marginTop: '50px',
      }}>
      <div className="heading heading-center mb-3">
        <h2 className="title">
          <Translate id="New Arrivals" />
        </h2>
      </div>

      <div className="tab-content">
        <div className="tab-pane p-0 fade active in">
          <div className="products">
            <div className="row">
              {data.products.length > 0 ? (
                data.products.map((product, index1) => (
                  <Col md={4} lg={3} xs={6} key={index1}>
                    <ProductCard product={product} DomLink={Link} />
                  </Col>
                ))
              ) : (
                <div>
                  <Translate id="No products yet." />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="more-container text-center mt-1 mb-3">
        <a
          href="/all-product"
          className="btn btn-outline-primary-2 btn-round btn-more">
          <Translate id="See More" />
        </a>
      </div>
    </Container>
  );
};
