import React from 'react';

import ContactUsDetails from './Components/ContactUsDetails';
import Container from '../../Components/Container';

export default () => {
  return (
    <Container>
      <ContactUsDetails />
    </Container>
  );
};
