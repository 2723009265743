import gql from 'graphql-tag';

export const SEARCH = gql`
  mutation searchProduct($word: String) {
    searchProduct(word: $word) {
      id
      name
      type
      oldPrice
      price
      pictures {
        path
      }
      color
    }
  }
`;
