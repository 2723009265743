import React from 'react';

import AboutUsDetails from './Components/AboutUsDetails';
import Container from '../../Components/Container';

export default () => {
  return (
    <Container>
      <AboutUsDetails />
    </Container>
  );
};
