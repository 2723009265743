import gql from 'graphql-tag';
import { PRODUCT_GQL } from '../AllProducts/AllProducts-gql';

export const PRODUCT_BY_ID = gql`
  query productById($id: ID) {
    productById(id: $id) {
      ${PRODUCT_GQL}
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($product: ProductUpdateInput) {
    updateProduct(product: $product)
  }
`;
