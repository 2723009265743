import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

import { PUBLICURI } from '../Configs';

const mapToProps = state => ({
  account: state.Account,
});

export default connect(mapToProps)(
  ({ product, account: { language, currency }, deleteProduct }) => {
    const [openModal, setModal] = useState(false);
    const [openModalDone, setModalDone] = useState(false);

    const hideAlert = () => {
      setModal(false);
      setModalDone(false);
    };

    const deleteAlert = () => {
      hideAlert();
    };

    return (
      <Translate>
        {({ translate }) => (
          <>
            <SweetAlert
              show={openModal}
              warning
              title={translate('Are you sure?')}
              showCancel
              cancelBtnText={translate('Close')}
              confirmBtnText={translate('Yes')}
              onCancel={hideAlert}
              onConfirm={() => {
                deleteProduct({
                  variables: {
                    id: product.id,
                  },
                  refetchQueries: [`userDashboard`],
                }).then(({ data }) => {
                  if (data) {
                    setModalDone(true);
                  }
                });
              }}></SweetAlert>
            <SweetAlert
              show={openModalDone}
              success
              title={translate('Deleted')}
              confirmBtnText={translate('Okay')}
              onCancel={hideAlert}
              onConfirm={deleteAlert}></SweetAlert>

            <div className="col-6 col-md-4 col-lg-3">
              <div className="product">
                <figure
                  className="product-media"
                  style={{
                    width: '100%',
                    height: 277,
                  }}>
                  {product?.preview ? (
                    product?.approve ? (
                      product?.sold ? (
                        <span className="out-of-stock">
                          <Translate id="outOfStock" />
                        </span>
                      ) : (
                        <span className="product-label label-new">
                          <Translate id="Approved" />
                        </span>
                      )
                    ) : (
                      <span className="product-label label-sale">
                        <Translate id="Disapproved" />
                      </span>
                    )
                  ) : (
                    <span className="product-label label-sale">
                      <Translate id="NotPreviewed" />
                    </span>
                  )}

                  <Link
                    to={{
                      pathname: `/product/${product.id}`,
                      state: { id: product.id },
                    }}>
                    <img
                      src={`${PUBLICURI}${product?.pictures[0]?.path}`}
                      alt="Product"
                      className="product-image"
                    />
                    <img
                      src={`${PUBLICURI}${product?.pictures[1]?.path}`}
                      alt="Product"
                      className="product-image-hover"
                    />
                  </Link>

                  <div className="product-action-vertical">
                    <Link
                      style={{ cursor: 'pointer' }}
                      to={`/edit-product/${product.id}`}
                      className="btn-product-icon btn-wishlist btn-expandable btn-edit">
                      <span>
                        <Translate id="EditProduct" />
                      </span>
                    </Link>
                  </div>
                  <div
                    className="product-action-vertical"
                    style={{
                      marginTop: 35,
                    }}>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => setModal(true)}
                      className="btn-product-icon btn-expandable">
                      <i className="fa fa-trash"></i>
                      <span>
                        <Translate id="Delete" />
                      </span>
                    </div>
                  </div>
                </figure>

                <div className="product-body">
                  <div className="product-cat">
                    <a href={`/product/${product.id}`}>
                      {product?.category?.name[language] || ''}
                    </a>
                  </div>
                  <h3 className="product-title">
                    <a href={`/product/${product.id}`}>{product.name}</a>
                  </h3>
                  <div className="product-price">
                    {product.new ? (
                      <>
                        <span className="new-price">
                          {Intl.NumberFormat(`${language}-JD`, {
                            style: 'currency',
                            currency: currency.value,
                          })
                            .format(product?.priceCurrency[currency.value])
                            .replace('.000', '')}
                        </span>
                        <span className="old-price">
                          {Intl.NumberFormat(`${language}-JD`, {
                            style: 'currency',
                            currency: currency.value,
                          })
                            .format(product?.oldPriceCurrency[currency.value])
                            .replace('.000', '')}
                        </span>
                      </>
                    ) : (
                      <>
                        {Intl.NumberFormat(`${language}-JD`, {
                          style: 'currency',
                          currency: currency.value,
                        })
                          .format(product?.priceCurrency[currency.value])
                          .replace('.000', '')}
                      </>
                    )}
                  </div>

                  {/* <div className="product-nav product-nav-dots">
              <a href="#r" style={{ background: product?.color }}>
                <span className="sr-only">{product?.color}</span>
              </a>
              {product?.color?.map((color, index2) => (
            <a key={index2} href="#r" style={{ background: color }}>
              <span className="sr-only">{color}</span>
            </a>
          ))}
            </div> */}
                </div>
              </div>
            </div>
          </>
        )}
      </Translate>
    );
  },
);
