import React, { useState, useEffect } from 'react';
import Link from '@material-ui/core/Link';

import coverImage from '../../../Styles/images/page-header-bg.jpg';
import { PUBLICURI } from '../../../Configs';
import { Translate } from 'react-localize-redux';
import moment from 'moment';

export default ({
  cart,
  removeFromCart,
  deleteFromCart,
  DomLink,
  language,
  user,
  currency,
}) => {
  const [state, setState] = useState({});
  useEffect(() => {
    const now = Date.now();
    if (moment(cart.timer).isBefore(now)) {
      removeFromCart(user.id);
    }

    setTimeout(
      () =>
        cart.cartItems.forEach((item, index) => {
          setState(prevState => ({ ...prevState, [index]: item.quantity }));
        }),
      0,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteCart = items => {
    deleteFromCart(items.product, null, items.quantity);
  };

  const totalCartPrice = () => {
    let plus = 0;
    cart.cartItems.forEach((items, index) => {
      if (
        items.product.user.id !==
        (cart.cartItems[index + 1] && cart.cartItems[index + 1].product.user.id)
      ) {
        ++plus;
      }
    });
    return cart.totalPrice + 5 * plus;
  };

  return (
    <main className="main cart-container">
      <div
        className="page-header text-center"
        style={{ backgroundImage: `url(${coverImage})` }}>
        <div className="container">
          <h1 className="page-title">
            <Translate id="shoppingcart" />
            <span>
              <Translate id="shop" />
            </span>
          </h1>
        </div>
      </div>
      <nav aria-label="breadcrumb" className="breadcrumb-nav">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">
                <Translate id="home" />
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <Translate id="shoppingcart" />
            </li>
          </ol>
        </div>
      </nav>

      <div className="page-content">
        <div className="cart">
          <div className="container">
            {cart.cartItems.length === 0 ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  flexDirection: 'column',
                }}>
                <div className="empty-cart-img"></div>
                <p>
                  <Translate id="emptycart" />
                </p>
                <a
                  href="/all-product"
                  className="btn btn-outline-dark-2 btn-block mb-4 shopping-cart-btn"
                  style={{ width: '1vw' }}>
                  <span>
                    <Translate id="goshopping" />
                  </span>
                </a>
              </div>
            ) : (
              <div className="row">
                <div className="col-lg-9">
                  <table className="table table-cart table-mobile">
                    <thead>
                      <tr>
                        <th>
                          <Translate id="product" />
                        </th>
                        <th>
                          <Translate id="price" />
                        </th>
                        <th>
                          <Translate id="quantity" />
                        </th>
                        <th>
                          <Translate id="total" />
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.cartItems.map((items, index) => (
                        <tr key={index}>
                          <td className="product-col">
                            <div className="product">
                              <figure className="product-media">
                                <DomLink
                                  to={{
                                    pathname: `/produc/${items.product.id}`,
                                    state: { id: items.product.id },
                                  }}>
                                  <img
                                    src={`${PUBLICURI}${items.product.pictures[0]?.path}`}
                                    alt="Product"
                                  />
                                </DomLink>
                              </figure>

                              <h3 className="product-title">
                                <DomLink
                                  to={{
                                    pathname: `/product/${items.product.id}`,
                                    state: { id: items.product.id },
                                  }}>
                                  {items.product.name}
                                </DomLink>
                              </h3>
                            </div>
                          </td>
                          <td className="price-col">
                            {Intl.NumberFormat(`${language}-JD`, {
                              style: 'currency',
                              currency: currency.value,
                            })
                              .format(
                                items.product.priceCurrency[currency.value],
                              )
                              .replace('.000', '')}
                          </td>
                          <td className="quantity-col">
                            <div className="input-group  input-spinner cart-product-quantity">
                              <div className="input-group-prepend"></div>
                              <div
                                style={{ textAlign: 'center' }}
                                className="form-control ">
                                {state[index]}
                              </div>
                              <div className="input-group-append"></div>
                            </div>
                          </td>
                          <td className="total-col">
                            {Intl.NumberFormat(`${language}-JD`, {
                              style: 'currency',
                              currency: currency.value,
                            })
                              .format(
                                items.product.priceCurrency[currency.value] *
                                  items.quantity,
                              )
                              .replace('.000', '')}
                          </td>
                          <td className="remove-col">
                            <button
                              className="btn-remove"
                              onClick={() => deleteCart(items)}>
                              <i className="icon-close"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="cart-bottom">
                    <div
                      style={{
                        fontSize: 20,
                        color: '#c96',
                      }}
                      className="cart-discount">
                      <Translate id="CartTimer" />{' '}
                      {moment(cart.timer).fromNow()}
                    </div>

                    <Link
                      onClick={() => window.location.reload()}
                      style={{ cursor: 'pointer', color: '#888' }}
                      className="btn btn-outline-dark-2">
                      <span>
                        <Translate id="updatecart" />
                      </span>
                      <i className="icon-refresh"></i>
                    </Link>
                  </div>
                </div>
                <aside className="col-lg-3">
                  <div className="summary summary-cart">
                    <h3 className="summary-title">
                      <Translate id="total" />
                    </h3>

                    <table className="table table-summary">
                      <tbody>
                        <tr className="summary-subtotal">
                          <td>
                            <Translate id="subtotal" />:
                          </td>
                          <td>
                            {Intl.NumberFormat(`${language}-JD`, {
                              style: 'currency',
                              currency: currency.value,
                            })
                              .format(cart.totalPrice)
                              .replace('.000', '')}
                          </td>
                        </tr>
                        <tr className="summary-shipping">
                          <td>
                            <Translate id="shipping" />:
                          </td>
                          <td>&nbsp;</td>
                        </tr>

                        <tr className="summary-shipping-row">
                          <td>
                            <div className="custom-control custom-radio">
                              <label
                                className="custom-control-label"
                                htmlFor="standart-shipping">
                                <Translate id="standard" />:
                              </label>
                            </div>
                          </td>
                          <td>
                            {Intl.NumberFormat(`${language}-JD`, {
                              style: 'currency',
                              currency: currency.value,
                            })
                              .format(5)
                              .replace('.000', '')}
                          </td>
                        </tr>

                        <tr className="summary-total">
                          <td>
                            <Translate id="total" />:
                          </td>
                          <td>
                            {Intl.NumberFormat(`${language}-JD`, {
                              style: 'currency',
                              currency: currency.value,
                            })
                              .format(totalCartPrice())
                              .replace('.000', '')}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <a
                      href="/checkout"
                      className="btn btn-outline-primary-2 btn-order btn-block">
                      <Translate id="PROCEED TO CHECKOUT" />
                    </a>
                  </div>
                  <a
                    href="/all-product"
                    className="btn btn-outline-dark-2 btn-block mb-3">
                    <span>
                      <Translate id="CONTINUE SHOPPING" />
                    </span>
                    <i className="icon-refresh"></i>
                  </a>
                </aside>
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};
