import React from 'react';
import { useQuery } from 'react-apollo';

import NotificationsDetails from './Components/NotificationsDetails';
import { USER_NOTIFICATION } from './Notifications-gql';
import Container from '../../Components/Container';
import Loading from '../../Components/Loading';
import ErrorPage from '../../Components/ErrorPage';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  account: state.Account,
});

export default connect(mapStateToProps)(({ account: { user } }) => {
  const { data, error, loading } = useQuery(USER_NOTIFICATION, {
    variables: {
      id: user.id,
    },
  });
  return error ? (
    <ErrorPage withHeader />
  ) : loading ? (
    <Loading withHeader />
  ) : (
    <Container>
      <NotificationsDetails notification={data.userNotifications} />
    </Container>
  );
});
