import React from 'react';
import { Link } from 'react-router-dom';

import CartDetails from './Components/CartDetails';
import { connect } from 'react-redux';
import { emptyCart, deleteCartItem } from '../../Action';
import Container from '../../Components/Container';

const mapStateToProps = state => ({
  cart: state.Cart,
  account: state.Account,
});

const mapDispatchToProps = {
  removeFromCart: emptyCart,
  deleteFromCart: deleteCartItem,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  ({
    cart,
    removeFromCart,
    deleteFromCart,
    account: { user, language, currency },
  }) => {
    return (
      <Container>
        <CartDetails
          cart={cart}
          removeFromCart={removeFromCart}
          deleteFromCart={deleteFromCart}
          DomLink={Link}
          user={user}
          language={language}
          currency={currency}
        />
      </Container>
    );
  },
);
