import React from 'react';

import BG from '../../Styles/images/page-header-bg.jpg';

import Container from '../Container';
import { Translate } from 'react-localize-redux';

export default () => {
  return (
    <Container>
      <main className="main faq-page">
        <div
          className="page-header text-center"
          style={{ backgroundImage: `url(${BG})` }}>
          <div className="container">
            <h1 className="page-title">
              <Translate id="Warranty and return policy" />
            </h1>
          </div>
        </div>
        <nav aria-label="breadcrumb" className="breadcrumb-nav">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">
                  <Translate id="home" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <Translate id="Warranty and return policy" />
              </li>
            </ol>
          </div>
        </nav>
        <div className="page-content pb-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="about-text  mt-3">
                  <p>
                    - <Translate id="return1" />
                  </p>
                  <p>
                    - <Translate id="return2" />
                  </p>
                  <p>
                    - <Translate id="return3" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Container>
  );
};
