import React from 'react';

import HomeBG from '../../../Styles/images/demos/demo-5/slider/slider-1Black.jpg';
// import VideoBG from '../../../Styles/images/demos/demo-5/Video-image-1-1920x560.jpg';

import NewArrivalProduct from './NewArrivalProduct';

import { Translate } from 'react-localize-redux';
import Banar from './Banar';

const MainPage = () => (
  <main className="main">
    <div className="intro-slider-container mb-0">
      <div
        className="intro-slider owl-carousel owl-theme owl-nav-inside owl-light"
        data-toggle="owl"
        data-owl-options='{"nav": false, "dots": false}'>
        <div
          className="intro-slide"
          style={{ backgroundImage: `url(${HomeBG})` }}>
          <div className="container intro-content text-center">
            <h3 className="intro-subtitle text-white">
              <Translate id="Don’t Miss" />
            </h3>
            <h1 className="intro-title text-white">
              <Translate id="Mystery Deals" />
            </h1>

            <a href="#r" className="btn btn-primary">
              <Translate id="Discover NOW" />
            </a>
          </div>
        </div>
      </div>

      <span className="slider-loader text-white"></span>
    </div>

    <div
      className="brands-border owl-carousel owl-simple mb-5"
      data-toggle="owl"
      data-owl-options='{
            "nav": false, 
            "dots": false,
            "margin": 0,
            "loop": false,
            "responsive": {
                "0": {
                    "items":2
                },
                "420": {
                    "items":3
                },
                "600": {
                    "items":4
                },
                "900": {
                    "items":5
                },
                "1024": {
                    "items":6
                },
                "1360": {
                    "items":7
                }
            }
        }'></div>

    <Banar />

    <NewArrivalProduct />

    {/* <div
      className="video-banner video-banner-bg bg-image text-center"
      style={{ backgroundImage: `url(${VideoBG})` }}>
      <div className="container">
        <h3 className="video-banner-title h1 text-white">
          <span>
            <Translate id="New Collection" />
          </span>
          <strong>
            Winter’19 <i>/</i> Spring’20
          </strong>
        </h3>
        <a
          href="https://www.youtube.com/watch?v=vBPgmASQ1A0"
          className="btn-video btn-iframe">
          <i className="icon-play"></i>
        </a>
      </div>
    </div> */}

    <div className="container">
      <div className="cta cta-separator mb-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="cta-wrapper cta-text text-center">
              <h3 className="cta-title">
                <Translate id="Shop Social" />
              </h3>

              <p className="cta-desc">
                <Translate id="Shop Social-Desc" />
              </p>

              <div className="social-icons social-icons-colored justify-content-center">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/laylscom/"
                  className="social-icon"
                  title="Facebook">
                  <i className="icon-facebook-f"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/laylscom/"
                  className="social-icon"
                  title="Instagram">
                  <i className="icon-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
);

export default MainPage;
