import React, { useState } from 'react';
import { useFormik } from 'formik';
import Dropzone from 'react-dropzone';
import * as Yup from 'yup';
import Select from 'react-select';
import { Translate } from 'react-localize-redux';
import Axios from 'axios';
import { CirclePicker } from 'react-color';
import { toast } from 'react-toastify';
import { getOrientation } from 'get-orientation/browser';
import { Link } from 'react-router-dom';

import BG from '../../../Styles/images/backgrounds/login-bg.jpg';
import VideoBG from '../../../Styles/images/demos/demo-5/Video-image-1-1920x560.jpg';

import { uploadUrl, PUBLICURI } from '../../../Configs';
import { SVG } from '../../../Styles/SVG/SVG';
import { getRotatedImage } from '../../NewProduct/Components/rotateImage';
import CropImage from '../../NewProduct/Components/CropImage';
import LoadingScreen from '../../../Components/LoadingScreen';
import VideoModal from '../../NewProduct/Components/VideoModal';

const sizes = [
  { value: 'XS', label: '36' },
  { value: 'S', label: '38' },
  { value: 'M', label: '40' },
  { value: 'L', label: '42' },
  { value: 'XL', label: '44' },
  { value: 'XXL', label: '46' },
  { value: 'XXXL', label: '48' },
  { value: 'XXXXL', label: '50' },
  { value: 'XXXXXL', label: '52' },
  { value: 'XXXXXXL', label: '54' },
  { value: 'Free', label: 'Free 38 - 44' },
];

const shapes = [
  { value: '1', label: 'ShortDress', icon: SVG.shortDress },
  { value: '2', label: 'LongDress', icon: SVG.dress2 },
  { value: '3', label: 'LongDress', icon: SVG.dress3 },
  { value: '4', label: 'LongDress', icon: SVG.dress4 },
  { value: '5', label: 'LongDress', icon: SVG.dress5 },
  { value: '6', label: 'LongDress', icon: SVG.dress6 },
  { value: '7', label: 'Jumpsuit', icon: SVG.dress7 },
  { value: '8', label: 'Abaya', icon: SVG.dress8 },
  { value: '9', label: 'Other', icon: 'Other' },
];

const countries = [
  { value: 'Jordan', label: 'Jordan' },
  { value: 'Turkey', label: 'Turkey' },
  { value: 'France', label: 'France' },
  { value: 'China', label: 'China' },
  { value: 'Local Designer', label: 'Local Designer' },
  { value: 'Other', label: 'Other' },
];

const formatOptionLabel = ({ value, label, icon }) => (
  <div
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <div
      style={{
        cursor: 'pointer',
        marginRight: 8,
        width: 40,
        alignItems: 'center',
        justifyContent: 'center',
        color: value === '8' ? 'white' : 'black',
      }}>
      {icon}
    </div>
  </div>
);

const ValidationSchema = Yup.object().shape({
  qty: Yup.string().required('qty'),
  type: Yup.string().required('type'),
  color: Yup.string().required('color'),
  origin: Yup.string(),
  size: Yup.string().required('size'),
  pictures: Yup.array()
    .min(2)
    .max(4)
    .required('pictures'),
  shape: Yup.string().required('shape'),
  country: Yup.string().required('country'),
  category: Yup.string().required('category'),
  price: Yup.number()
    .min(0)
    .positive('nigative')
    .required('price is required'),
  oldPrice: Yup.number()
    .positive('oldPrice')
    .min(0)
    .required('oldPrice'),
});

const readFile = file =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
};

export default ({
  updateProduct,
  categoryOptions,
  data,
  language,
  id,
  currency,
}) => {
  const formik = useFormik({
    initialValues: {
      description: data.description,
      price: data.price,
      oldPrice: data.oldPrice || 0,
      qty: data.qty,
      type: data.type,
      color: data.color,
      selectedColor: data.selectedColor || '',
      origin: data.origin,
      size: data.size,
      pictures: data.pictures,
      shape: data.shape,
      chest: data.chest,
      waist: data.waist,
      hip: data.hip,
      shoulder: data.shoulder,
      high: data.high,
      length: data.length,
      sleeve: data.sleeve,
      underlay: data.underlay,
      country: { value: data.country.value, label: data.country.label },
      category: {
        value: data.category.id,
        label: data.category.name[language],
      },
    },
    onSubmit: () => handleSubmits(),
    validationSchema: ValidationSchema,
  });

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = formik;

  const uploadPictures = async files => {
    const data = new FormData();
    data.append('file', await urltoFile(files, `${files}.jpg`, 'image/jpeg'));
    data.append('type', 'image');
    return await Axios.post(uploadUrl, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
    })
      .then(({ data }) => data._id)
      .catch(() => false);
  };

  const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  const [loading, setLoading] = useState(false);

  const handleSubmits = async () => {
    const newPicture = [];
    setLoading(true);
    await asyncForEach(values.pictures, async pic => {
      if (!pic.id) {
        const image = await uploadPictures(pic);
        if (image) {
          newPicture.push(image);
        }
      } else {
        newPicture.push(pic.id);
      }
    });

    if (newPicture.length > 0) {
      delete values.pictures;
      delete values.size.__typename;
      delete values.shape.__typename;
      delete values.country.__typename;

      if (values.category.value) {
        values.category = values.category.value;
      }

      await updateProduct({
        variables: {
          product: {
            id,
            pictures: newPicture,
            ...values,
          },
        },
      })
        .then(() => {
          setLoading(false);
          toast.success(<Translate id="Product added wait for the approve" />, {
            onClose: () => (window.location.href = '/profile'),
          });
        })
        .catch(() => {
          setLoading(false);
          toast.error(<Translate id="somethingWentWrong" />);
        });
    } else {
      setLoading(false);
      toast.error(<Translate id="somethingWentWrong" />);
    }
  };

  const [file1, setFile1] = useState(
    data.pictures.length > 0 && PUBLICURI + data.pictures[0]?.path,
  );
  const [file2, setFile2] = useState(
    data.pictures.length > 1 && PUBLICURI + data.pictures[1]?.path,
  );
  const [file3, setFile3] = useState(
    data.pictures.length > 2 && PUBLICURI + data.pictures[2]?.path,
  );
  const [file4, setFile4] = useState(
    data.pictures.length > 3 && PUBLICURI + data.pictures[3]?.path,
  );
  const [dropNum, setDropNum] = useState('');

  const [modal, setModal] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  const [videoModal, setVideoModal] = useState(false);

  const videoToggle = () => {
    setVideoModal(!videoModal);
  };

  const removeFile = index => {
    let newPicture = values.pictures;
    newPicture.pop();
    setFieldValue('pictures', newPicture);

    if (index === '1') {
      setFile1('');
    } else if (index === '2') {
      setFile2('');
    } else if (index === '3') {
      setFile3('');
    } else if (index === '4') {
      setFile4('');
    }
  };

  const toggle = () => {
    setModal(!modal);
  };

  const onDrop = async (acceptedFiles, num) => {
    setDropNum(num);
    const file = acceptedFiles[0];
    let imageDataUrl = await readFile(file);

    const orientation = await getOrientation(file);
    const rotation = ORIENTATION_TO_ANGLE[orientation];
    if (rotation) {
      imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
    }

    setImageSrc(imageDataUrl);
    toggle();
  };

  const doneCropping = image => {
    if (dropNum === '1') {
      setFile1(image);
    } else if (dropNum === '2') {
      setFile2(image);
    } else if (dropNum === '3') {
      setFile3(image);
    } else if (dropNum === '4') {
      setFile4(image);
    }

    const newPicture = values.pictures;
    newPicture.push(image);
    setFieldValue('pictures', newPicture);
  };

  const urltoFile = async (url, filename, mimeType) => {
    return fetch(url)
      .then(function(res) {
        return res.arrayBuffer();
      })
      .then(function(buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  return (
    <Translate>
      {({ translate }) => (
        <>
          <CropImage
            file={imageSrc}
            modal={modal}
            toggle={toggle}
            doneCropping={doneCropping}
            dropNum={dropNum}
          />

          <VideoModal modal={videoModal} toggle={videoToggle} />
          <main className="main new-product">
            {loading && <LoadingScreen />}

            <nav
              aria-label="breadcrumb"
              className="breadcrumb-nav border-0 mb-0">
              <div className="container">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">
                      <Translate id="home" />
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="profile">
                      <Translate id="My Account" />
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Translate id="EditProduct" />
                  </li>
                </ol>
              </div>
            </nav>

            <div
              className="login-page bg-image"
              style={{ backgroundImage: `url(${BG})` }}>
              <div className="container">
                <div className="form-box">
                  <div className="form-tab">
                    <div className="tab-content">
                      <div
                        className="tab-pane  fade show active in"
                        id="signin-2"
                        role="tabpanel"
                        aria-labelledby="signin-tab-2">
                        <form onSubmit={handleSubmit}>
                          <div
                            className="row"
                            style={{
                              marginBottom: 20,
                            }}>
                            <div className="col-sm-6">
                              <div
                                className={
                                  errors.pictures && touched.pictures
                                    ? 'dropzone dropzone--multiple col-sm-3 error'
                                    : 'dropzone dropzone--multiple col-sm-3'
                                }>
                                <Dropzone
                                  className="dropzone__input  "
                                  accept="image/jpeg, image/png"
                                  multiple={false}
                                  onDrop={filesToUpload => {
                                    onDrop(filesToUpload, '1');
                                  }}>
                                  {({ getRootProps, getInputProps }) => (
                                    <div
                                      {...getRootProps()}
                                      className="dropzone__input">
                                      {!file1 && (
                                        <div className="dropzone__drop-here">
                                          <span className="lnr lnr-upload">
                                            +
                                          </span>
                                        </div>
                                      )}
                                      <input {...getInputProps()} />
                                    </div>
                                  )}
                                </Dropzone>
                                {file1 && (
                                  <div className="dropzone__imgs-wrapper ">
                                    <div
                                      className="dropzone__img"
                                      style={{
                                        backgroundImage: `url(${file1})`,
                                      }}>
                                      <p className="dropzone__img-name">
                                        {file1}
                                      </p>
                                      <button
                                        className="dropzone__img-delete"
                                        type="button"
                                        onClick={e => removeFile('1', e)}>
                                        <Translate id="Remove" />
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div
                                className={
                                  errors.pictures && touched.pictures
                                    ? 'dropzone dropzone--multiple col-sm-3 error'
                                    : 'dropzone dropzone--multiple col-sm-3'
                                }>
                                <Dropzone
                                  className="dropzone__input  "
                                  accept="image/jpeg, image/png"
                                  multiple={false}
                                  onDrop={filesToUpload => {
                                    onDrop(filesToUpload, '2');
                                  }}>
                                  {({ getRootProps, getInputProps }) => (
                                    <div
                                      {...getRootProps()}
                                      className="dropzone__input">
                                      {!file2 && (
                                        <div className="dropzone__drop-here">
                                          <span className="lnr lnr-upload" /> +
                                        </div>
                                      )}
                                      <input {...getInputProps()} />
                                    </div>
                                  )}
                                </Dropzone>
                                {file2 && (
                                  <div className="dropzone__imgs-wrapper ">
                                    <div
                                      className="dropzone__img"
                                      style={{
                                        backgroundImage: `url(${file2})`,
                                      }}>
                                      <p className="dropzone__img-name">
                                        {file2}
                                      </p>
                                      <button
                                        className="dropzone__img-delete"
                                        type="button"
                                        onClick={e => removeFile('2', e)}>
                                        <Translate id="Remove" />
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div
                                className={
                                  errors.pictures && touched.pictures
                                    ? 'dropzone dropzone--multiple col-sm-3 error'
                                    : 'dropzone dropzone--multiple col-sm-3'
                                }>
                                <Dropzone
                                  className="dropzone__input  "
                                  accept="image/jpeg, image/png"
                                  multiple={false}
                                  onDrop={filesToUpload => {
                                    onDrop(filesToUpload, '3');
                                  }}>
                                  {({ getRootProps, getInputProps }) => (
                                    <div
                                      {...getRootProps()}
                                      className="dropzone__input">
                                      {!file3 && (
                                        <div className="dropzone__drop-here">
                                          <span className="lnr lnr-upload" /> +
                                        </div>
                                      )}
                                      <input {...getInputProps()} />
                                    </div>
                                  )}
                                </Dropzone>
                                {file3 && (
                                  <div className="dropzone__imgs-wrapper ">
                                    <div
                                      className="dropzone__img"
                                      style={{
                                        backgroundImage: `url(${file3})`,
                                      }}>
                                      <p className="dropzone__img-name">
                                        {file3}
                                      </p>
                                      <button
                                        className="dropzone__img-delete"
                                        type="button"
                                        onClick={e => removeFile('3', e)}>
                                        <Translate id="Remove" />
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div
                                className={
                                  errors.pictures && touched.pictures
                                    ? 'dropzone dropzone--multiple col-sm-3 error'
                                    : 'dropzone dropzone--multiple col-sm-3'
                                }>
                                <Dropzone
                                  className="dropzone__input  "
                                  accept="image/jpeg, image/png"
                                  multiple={false}
                                  onDrop={filesToUpload => {
                                    onDrop(filesToUpload, '4');
                                  }}>
                                  {({ getRootProps, getInputProps }) => (
                                    <div
                                      {...getRootProps()}
                                      className="dropzone__input">
                                      {!file4 && (
                                        <div className="dropzone__drop-here">
                                          <span className="lnr lnr-upload" /> +
                                        </div>
                                      )}
                                      <input {...getInputProps()} />
                                    </div>
                                  )}
                                </Dropzone>
                                {file4 && (
                                  <div className="dropzone__imgs-wrapper ">
                                    <div
                                      className="dropzone__img"
                                      style={{
                                        backgroundImage: `url(${file4})`,
                                      }}>
                                      <p className="dropzone__img-name">
                                        {file4}
                                      </p>
                                      <button
                                        className="dropzone__img-delete"
                                        type="button"
                                        onClick={e => removeFile('4', e)}>
                                        <Translate id="Remove" />
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div
                                className="video-banner video-banner-sm bg-image text-center"
                                style={{
                                  backgroundImage: `url(${VideoBG})`,
                                  height: '100%',
                                }}>
                                <div className="center-flex">
                                  <h3 className="video-banner-title h1 text-white">
                                    <span>
                                      {' '}
                                      <Translate id="New Collection" />
                                    </span>
                                  </h3>
                                  <Link
                                    to="#"
                                    onClick={videoToggle}
                                    className="btn-video btn-iframe">
                                    <i className="icon-play"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className={'form-group'}>
                            <label htmlFor="type">
                              <Translate id="type" />
                            </label>
                            <div className="row">
                              <div className="col-md-6">
                                <label className="container-radio">
                                  <Translate id="used" />
                                  <input
                                    required
                                    id="Used"
                                    type="radio"
                                    name="radio"
                                    defaultChecked={values.type === 'Used'}
                                    value="Used"
                                    onChange={e =>
                                      setFieldValue('type', e.target.value)
                                    }
                                  />

                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="col-md-6">
                                <label className="container-radio">
                                  <Translate id="new" />
                                  <input
                                    id="New"
                                    type="radio"
                                    name="radio"
                                    required
                                    value="New"
                                    defaultChecked={values.type === 'New'}
                                    onChange={e =>
                                      setFieldValue('type', e.target.value)
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className={'form-group'}>
                            {values.type === 'New' && (
                              <div className="row">
                                <div
                                  className="col-sm-4"
                                  style={{
                                    marginBottom: 10,
                                  }}>
                                  <label htmlFor="oldPrice">
                                    {translate('oldprice')}
                                    {translate('(')}
                                    <Translate id={currency.value} />
                                    {translate(')')} *
                                  </label>
                                  <input
                                    type="number"
                                    className={
                                      errors.oldPrice && touched.oldPrice
                                        ? 'form-control error'
                                        : 'form-control'
                                    }
                                    id="oldPrice"
                                    name="oldPrice"
                                    required
                                    value={values.oldPrice}
                                    defaultValue={values.oldPrice}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div
                                  className="col-sm-4"
                                  style={{
                                    marginBottom: 10,
                                  }}>
                                  <label htmlFor="price">
                                    {translate('price')} {translate('(')}
                                    <Translate id={currency.value} />
                                    {translate(')')} *
                                  </label>
                                  <input
                                    type="number"
                                    className={
                                      errors.price && touched.price
                                        ? 'form-control error'
                                        : 'form-control'
                                    }
                                    id="price"
                                    name="price"
                                    required
                                    value={values.price}
                                    defaultValue={values.price}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            )}
                            <div
                              className="row"
                              style={{
                                flexDirection: 'column',
                              }}>
                              {values.type === 'Used' && (
                                <div
                                  className="col-sm-4"
                                  style={{
                                    marginBottom: 10,
                                  }}>
                                  <label htmlFor="price">
                                    {translate('price')} {translate('(')}
                                    <Translate id={currency.value} />
                                    {translate(')')} *
                                  </label>
                                  <input
                                    type="number"
                                    className={
                                      errors.price && touched.price
                                        ? 'form-control error'
                                        : 'form-control'
                                    }
                                    id="price"
                                    name="price"
                                    required
                                    value={values.price}
                                    defaultValue={values.price}
                                    onChange={handleChange}
                                  />
                                </div>
                              )}

                              <div className="col-sm-4">
                                <label htmlFor="price">
                                  <Translate id="quantity" /> *
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="qty"
                                  name="qty"
                                  required
                                  value={values.qty}
                                  onChange={e =>
                                    e.target.value >= 1 && handleChange(e)
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div
                              className={
                                errors.color && touched.color
                                  ? 'col error'
                                  : 'col'
                              }>
                              <div className={'form-group'}>
                                <label htmlFor="color">
                                  <Translate id="color" /> *
                                </label>
                                <div>
                                  <label>
                                    <Translate id="Selected Color" />:
                                    {values.selectedColor ? (
                                      values.selectedColor
                                    ) : (
                                      <Translate id="noSelectColor" />
                                    )}
                                  </label>
                                </div>
                                <CirclePicker
                                  width="auto"
                                  onChangeComplete={color => {
                                    setFieldValue('color', color.hex);

                                    setFieldValue(
                                      'selectedColor',
                                      color.hex.toUpperCase() === '#FF0000'
                                        ? 'Red'
                                        : color.hex.toUpperCase() === '#AF0000'
                                        ? 'Dark Red'
                                        : color.hex.toUpperCase() === '#FAAFBE'
                                        ? 'Pink'
                                        : color.hex.toUpperCase() === '#00FFFF'
                                        ? 'Cyan'
                                        : color.hex.toUpperCase() === '#0000FF'
                                        ? 'Blue'
                                        : color.hex.toUpperCase() === '#0000A0'
                                        ? 'Dark Blue'
                                        : color.hex.toUpperCase() === '#ADD8E6'
                                        ? 'Light Blue'
                                        : color.hex.toUpperCase() === '#800080'
                                        ? 'Purple'
                                        : color.hex.toUpperCase() === '#FFF380'
                                        ? 'Corn Yellow'
                                        : color.hex.toUpperCase() === '#FFFF00'
                                        ? 'Yellow'
                                        : color.hex.toUpperCase() === '#00FF00'
                                        ? 'Lime'
                                        : color.hex.toUpperCase() === '#FF00FF'
                                        ? 'Magenta'
                                        : color.hex.toUpperCase() === '#FFFFFF'
                                        ? 'White'
                                        : color.hex.toUpperCase() === '#C0C0C0'
                                        ? 'Silver'
                                        : color.hex.toUpperCase() === '#808080'
                                        ? 'Gray'
                                        : color.hex.toUpperCase() === '#000000'
                                        ? 'Black'
                                        : color.hex.toUpperCase() === '#FFA500'
                                        ? 'Orange'
                                        : color.hex.toUpperCase() === '#A52A2A'
                                        ? 'Brown'
                                        : color.hex.toUpperCase() === '#6F4E37'
                                        ? 'Coffee'
                                        : color.hex.toUpperCase() === '#800000'
                                        ? 'Maroon'
                                        : color.hex.toUpperCase() === '#008000'
                                        ? 'Green'
                                        : color.hex.toUpperCase() === '#808000'
                                        ? 'Olive'
                                        : '',
                                    );
                                  }}
                                  color={values.color}
                                  colors={[
                                    '#FF0000',
                                    '#AF0000',
                                    '#FAAFBE',
                                    '#00FFFF',
                                    '#0000FF',
                                    '#0000A0',
                                    '#ADD8E6',
                                    '#800080',
                                    '#FFF380',
                                    '#FFFF00',
                                    '#00FF00',
                                    '#FF00FF',
                                    '#FFFFFF',
                                    '#C0C0C0',
                                    '#808080',
                                    '#000000',
                                    '#FFA500',
                                    '#A52A2A',
                                    '#6F4E37',
                                    '#800000',
                                    '#008000',
                                    '#808000',
                                  ]}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-4">
                              <div className={'form-group'}>
                                <label htmlFor="country">
                                  <Translate id="Made in" /> *
                                </label>
                                <Select
                                  className={
                                    errors.country && touched.country
                                      ? 'error'
                                      : ''
                                  }
                                  placeholder={translate('Select Country ...')}
                                  options={countries}
                                  defaultValue={values.country}
                                  onChange={e => setFieldValue('country', e)}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className={'form-group'}>
                                <label htmlFor="shape">
                                  <Translate id="Shape" /> *
                                </label>
                                <Select
                                  className={
                                    errors.shape && touched.shape ? 'error' : ''
                                  }
                                  placeholder={translate('Select Shape ...')}
                                  options={shapes}
                                  defaultValue={shapes.find(
                                    elem => elem.value === values.shape.value,
                                  )}
                                  onChange={e =>
                                    setFieldValue('shape', e.value)
                                  }
                                  formatOptionLabel={formatOptionLabel}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className={'form-group'}>
                                <label htmlFor="size">
                                  <Translate id="size" /> *
                                </label>
                                <Select
                                  className={
                                    errors.size && touched.size ? 'error' : ''
                                  }
                                  placeholder={translate('Select Size ...')}
                                  options={sizes}
                                  defaultValue={values.size}
                                  onChange={e => setFieldValue('size', e.value)}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="chest">
                                  <Translate id="Chest / Breast" />{' '}
                                  {translate('(')}
                                  {translate('CM')}
                                  {translate(')')}
                                  {values.type === 'Used' ? (
                                    '*'
                                  ) : (
                                    <Translate id="Optional" />
                                  )}
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="chest"
                                  name="chest"
                                  value={values.chest}
                                  onChange={handleChange}
                                  required={values.type === 'Used'}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label htmlFor="waist">
                                  <Translate id="Waist" /> {translate('(')}
                                  {translate('CM')}
                                  {translate(')')}
                                  {values.type === 'Used' ? (
                                    '*'
                                  ) : (
                                    <Translate id="Optional" />
                                  )}
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="waist"
                                  name="waist"
                                  value={values.waist}
                                  onChange={handleChange}
                                  required={values.type === 'Used'}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className={'form-group'}>
                                <label htmlFor="hip">
                                  <Translate id="Hip" /> {translate('(')}
                                  {translate('CM')}
                                  {translate(')')}
                                  {values.type === 'Used' ? (
                                    '*'
                                  ) : (
                                    <Translate id="Optional" />
                                  )}
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="hip"
                                  name="hip"
                                  value={values.hip}
                                  onChange={handleChange}
                                  required={values.type === 'Used'}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-4">
                              <div className={'form-group'}>
                                <label htmlFor="shoulder">
                                  <Translate id="Shoulder" /> {translate('(')}
                                  {translate('CM')}
                                  {translate(')')}
                                  {values.type === 'Used' ? (
                                    '*'
                                  ) : (
                                    <Translate id="Optional" />
                                  )}
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="shoulder"
                                  name="shoulder"
                                  value={values.shoulder}
                                  onChange={handleChange}
                                  required={values.type === 'Used'}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className={'form-group'}>
                                <label htmlFor="high">
                                  <Translate id="High" /> {translate('(')}
                                  {translate('CM')}
                                  {translate(')')}
                                  {values.type === 'Used' ? (
                                    '*'
                                  ) : (
                                    <Translate id="Optional" />
                                  )}
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="high"
                                  name="high"
                                  value={values.high}
                                  onChange={handleChange}
                                  required={values.type === 'Used'}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className={'form-group'}>
                                <label htmlFor="length">
                                  <Translate id="Length" /> {translate('(')}
                                  {translate('CM')}
                                  {translate(')')}
                                  {values.type === 'Used' ? (
                                    '*'
                                  ) : (
                                    <Translate id="Optional" />
                                  )}
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="length"
                                  name="length"
                                  value={values.length}
                                  onChange={handleChange}
                                  required={values.type === 'Used'}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className=" col-sm-4">
                              <div className={'form-group'}>
                                <label htmlFor="origin">
                                  <Translate id="Origin / Brand" />
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="origin"
                                  name="origin"
                                  value={values.origin}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className=" col-sm-4">
                              <div className={'form-group'}>
                                <label htmlFor="origin">
                                  <Translate id="Category" /> *
                                </label>
                                <Select
                                  className={
                                    errors.country && touched.country
                                      ? 'error'
                                      : ''
                                  }
                                  options={categoryOptions}
                                  placeholder={translate('Select category ...')}
                                  defaultValue={values.category}
                                  onChange={e =>
                                    setFieldValue('category', e.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="price">
                              {' '}
                              <Translate id="Additions" />
                            </label>
                            <div className="row">
                              <div className="col-sm-6">
                                <label className="container-checkbox">
                                  <Translate id="sleeve" />
                                  <input
                                    type="checkbox"
                                    value={values.sleeve}
                                    onChange={e =>
                                      setFieldValue('sleeve', e.target.checked)
                                    }
                                  />
                                  <span className="checkmark-checkbox"></span>
                                </label>
                              </div>
                              <div className="col-sm-6">
                                <label className="container-checkbox">
                                  <Translate id="underlay" />
                                  <input
                                    type="checkbox"
                                    value={values.underlay}
                                    onChange={e =>
                                      setFieldValue(
                                        'underlay',
                                        e.target.checked,
                                      )
                                    }
                                  />
                                  <span className="checkmark-checkbox"></span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="description">
                              <Translate id="If you have more details" />
                            </label>
                            <textarea
                              type="text"
                              className="form-control"
                              id="description"
                              name="description"
                              value={values.description}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="">
                            <button
                              type="submit"
                              className="btn btn-outline-primary-2">
                              <span>
                                <Translate id="Submit" />
                              </span>
                              {loading ? (
                                <i className="fa fa-spinner fa-pulse" />
                              ) : (
                                <i className="icon-long-arrow-right"></i>
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </Translate>
  );
};
