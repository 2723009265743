import React from 'react';

import LoginPage from './Component/LoginPage';
import { useMutation } from 'react-apollo';
import { LOGIN, FACEBOOK_LOGIN, GOOGLE_LOGIN } from './Login-gql';
import Container from '../../Components/Container';

const Login = () => {
  const [loginUser] = useMutation(LOGIN);
  const [loginFacebook] = useMutation(FACEBOOK_LOGIN);
  const [loginGoogle] = useMutation(GOOGLE_LOGIN);

  return (
    <Container>
      <LoginPage
        loginUser={loginUser}
        loginFacebook={loginFacebook}
        loginGoogle={loginGoogle}
      />
    </Container>
  );
};

export default Login;
