import gql from 'graphql-tag';

export const ADDWISHLIST = gql`
  query wishlistByUser($user: ID) {
    wishlistByUser(user: $user) {
      id
      user
      product {
        id
      }
    }
  }
`;
