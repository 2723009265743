import React from 'react';
import BG from '../../../Styles/images/page-header-bg.jpg';
import { PUBLICURI } from '../../../Configs';

import { Translate } from 'react-localize-redux';

const WishlistDetails = ({
  wishlist,
  addToCart,
  removeWishList,
  language,
  currency,
}) => (
  <div className="page-wrapper wishlist-container">
    <main className="main">
      <div
        className="page-header text-center"
        style={{ backgroundImage: `url(${BG})` }}>
        <div className="container">
          <h1 className="page-title">
            <Translate id="wishlist" />
            <span>
              <Translate id="shop" />
            </span>
          </h1>
        </div>
      </div>
      <nav aria-label="breadcrumb" className="breadcrumb-nav">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">
                <Translate id="home" />
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <Translate id="wishlist" />
            </li>
          </ol>
        </div>
      </nav>

      <div className="page-content">
        <div className="container">
          {wishlist.wishListItems.length === 0 ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                flexDirection: 'column',
              }}>
              <div className="empty-wishlist-img"></div>
              <p>
                <Translate id="wishlistempty" />
              </p>
              <a
                href="/all-product"
                className="btn btn-outline-dark-2 btn-block mb-4 shopping-cart-btn"
                style={{ width: '1vw' }}>
                <span>
                  <Translate id="goshopping" />
                </span>
              </a>
            </div>
          ) : (
            <table className="table table-wishlist table-mobile">
              <thead>
                <tr>
                  <th>
                    {' '}
                    <Translate id="product" />
                  </th>
                  <th>
                    {' '}
                    <Translate id="price" />
                  </th>
                  <th>
                    <Translate id="stockstatus" />
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {wishlist.wishListItems.map((item, index) => (
                  <tr key={index}>
                    <td className="product-col">
                      <div className="product">
                        <figure className="product-media">
                          <a href={`/product/${item.product.id}`}>
                            <img
                              src={`${PUBLICURI}${item.product.pictures[0].path}`}
                              alt="Product"
                            />
                          </a>
                        </figure>

                        <h3 className="product-title">
                          <a href={`/product/${item.product.id}`}>
                            {item.product.name}
                          </a>
                        </h3>
                      </div>
                    </td>
                    <td className="price-col">
                      {Intl.NumberFormat(`${language}-JD`, {
                        style: 'currency',
                        currency: currency.value,
                      })
                        .format(item.product.priceCurrency[currency.value])
                        .replace('.000', '')}
                    </td>
                    <td className="stock-col">
                      {item.product.onHold ? (
                        <span className="out-of-stock">
                          <Translate id="onHold" />
                        </span>
                      ) : item.product.sold ? (
                        <span className="out-of-stock">
                          <Translate id="outOfStock" />
                        </span>
                      ) : (
                        <span className="in-stock">
                          <Translate id="instock" />
                        </span>
                      )}
                    </td>
                    <td className="action-col">
                      <button
                        className="btn btn-block btn-outline-primary-2"
                        onClick={() => addToCart(item.product)}>
                        <i className="icon-cart-plus"></i>
                        <Translate id="addtocart" />
                      </button>
                    </td>
                    <td className="remove-col">
                      <button
                        className="btn-remove"
                        onClick={() => removeWishList(item.product)}>
                        <i className="icon-close"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <div className="wishlist-share">
            <div className="social-icons social-icons-sm mb-2">
              <label className="social-label">Contact us:</label>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/laylscom/"
                className="social-icon"
                title="Facebook">
                <i className="icon-facebook-f"></i>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/laylscom/"
                className="social-icon"
                title="Instagram">
                <i className="icon-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
);

export default WishlistDetails;
