import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';

import { Translate } from 'react-localize-redux';
import { toast } from 'react-toastify';

import BG from '../../../Styles/images/backgrounds/login-bg.jpg';
import { loginUser } from '../../../Action/index';

const ValidationSchema = Yup.object().shape({
  code: Yup.string().required('code'),
});

const mapDispathToProps = {
  signupUser: loginUser,
};

const mapToProps = state => ({
  account: state.Account,
});

export default connect(
  mapToProps,
  mapDispathToProps,
)(({ account: { language }, signup, signupUser }) => {
  const formik = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: () => handleSubmits(),
    validationSchema: ValidationSchema,
  });

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = formik;

  const handleSubmits = () => {
    if (values.password === values.confirmPassword) {
      signup({
        variables: {
          user: {
            facebook: '',
            google: '',
            account: {
              firstName: values.firstName,
              lastName: values.lastName,
              mobile: values.mobile.replace(/ /g, ''),
              country: values.country,
              email: values.email,
              password: values.password,
            },
          },
        },
      })
        .then(async ({ data: { createUser } }) => {
          if (createUser) {
            signupUser(createUser);
            await localStorage.setItem('token', createUser.account.token);

            toast.success(<Translate id="SignupSuccessfully" />, {
              onClose: () => (window.location.href = '/'),
            });
          }
        })
        .catch(error =>
          error.graphQLErrors.length > 0
            ? toast.error(error.graphQLErrors[0].message)
            : toast.error(<Translate id="somethingWentWrong" />),
        );
    } else {
      setFieldValue('password', '');
      setFieldValue('confirmPassword', '');
      alert('Re-enter password');
    }
  };

  return (
    <div className="page-wrapper verification">
      <main className="main">
        <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">
                  <Translate id="home" />
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="/signup">
                  <Translate id="Signup" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <Translate id="Verification" />
              </li>
            </ol>
          </div>
        </nav>

        <div
          className="login-page bg-image pt-8 pb-8 pt-md-12 pb-md-12 pt-lg-17 pb-lg-17"
          style={{ backgroundImage: `url(${BG})`, height: '78vh' }}>
          <div className="container">
            <div className="form-box">
              <div className="form-tab">
                <ul className="nav nav-pills nav-fill" role="tablist">
                  <li className="nav-item">
                    <div className="nav-link active in">
                      <Translate id="Verification" />
                    </div>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active in"
                    id="register-2"
                    role="tabpanel"
                    aria-labelledby="register-tab-2">
                    <form onSubmit={handleSubmit}>
                      <div
                        className={
                          errors.code && touched.code
                            ? 'form-group error'
                            : 'form-group'
                        }>
                        <label htmlFor="code">
                          <Translate id="Code" /> *
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          id="code"
                          name="code"
                          required
                          onChange={handleChange}
                          value={values.code}
                        />
                      </div>

                      <div className="form-footer">
                        <button
                          type="submit"
                          className="btn btn-outline-primary-2">
                          <span>
                            <Translate id="Verify" />
                          </span>
                          <i className="icon-long-arrow-right"></i>
                        </button>
                        <button
                          type="submit"
                          className="btn btn-outline-primary-2">
                          <span>
                            <Translate id="ResendCode" />
                          </span>
                          <i className="icon-check"></i>
                        </button>{' '}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
});
