import React from 'react';

import BG from '../../../Styles/images/page-header-bg.jpg';
import { Translate } from 'react-localize-redux';

export default () => (
  <main className="main about-us">
    <div
      className="page-header text-center"
      style={{ backgroundImage: `url(${BG})` }}>
      <div className="container">
        <h1 className="page-title">
          <Translate id="AboutUs" />
        </h1>
      </div>
    </div>
    <nav aria-label="breadcrumb" className="breadcrumb-nav">
      <div className="container">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">
              <Translate id="home" />
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Translate id="AboutUs" />
          </li>
        </ol>
      </div>
    </nav>

    <div className="page-content pb-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="about-text  mt-3">
              <h2 className="title text-center mb-2">
                <Translate id="WhoWeAre" />
              </h2>
              <p>
                <Translate id="Aboutus1" />
              </p>
              <p>
                <Translate id="Aboutus2" />
              </p>
              <p>
                <Translate id="Aboutus3" />
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-sm-6">
            <div className="icon-box icon-box-sm text-center">
              <span
                className="icon-box-icon"
                style={{
                  marginBottom: 0,
                }}>
                <i className="icon-puzzle-piece"></i>
              </span>
              <div className="icon-box-content">
                <h3 className="icon-box-title">Design Quality</h3>
                {/* <p>
                  Sed egestas, ante et vulputate volutpat, eros pede semper est,
                  vitae luctus metus libero <br />
                  eu augue.
                </p> */}
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="icon-box icon-box-sm text-center">
              <span
                className="icon-box-icon"
                style={{
                  marginBottom: 0,
                }}>
                <i className="icon-life-ring"></i>
              </span>
              <div className="icon-box-content">
                <h3 className="icon-box-title">Professional Support</h3>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="icon-box icon-box-sm text-center">
              <span
                className="icon-box-icon"
                style={{
                  marginBottom: 0,
                }}>
                <i className="icon-heart-o"></i>
              </span>
              <div className="icon-box-content">
                <h3 className="icon-box-title">Made With Love</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
);
