import React from 'react';

import Container from '../../Components/Container';
import VerificationDetails from './Components/VerificationDetails';

export default () => {
  return (
    <Container>
      <VerificationDetails />
    </Container>
  );
};
