import { combineReducers } from 'redux';
import Configs from './Configs';
import Account from './Account';
import Cart from './Cart';
import Wishlist from './Wishlist';
import Notification from './Notification';

export default combineReducers({
  Account,
  Configs,
  Cart,
  Wishlist,
  Notification,
});
