import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Container from '../../Components/Container';
import SearchDetails from './Components/SearchDetails';

export default () => {
  const { user, language, currency } = useSelector(state => state.Account);

  return (
    <Container>
      <SearchDetails
        DomLink={Link}
        user={user}
        language={language}
        currency={currency}
      />
    </Container>
  );
};
