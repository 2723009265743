import {
  ADD_WISHLIST_ITEM,
  REMOVE_WISHLIST_ITEM,
  EMPTY_WISHLIST,
} from '../Action';
import Axios from 'axios';
import { createWishlist } from '../Configs';

const initialState = {
  wishListItems: [],
  total: 0,
  totalPrice: 0,
};

const createdWishlist = async data => {
  await Axios.post(createWishlist, {
    data,
  })
    .then(data => {
      //
    })
    .catch(error => {
      //
    });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_WISHLIST_ITEM: {
      const isExisted = state.wishListItems.some(wishListItem =>
        compareWishListItem(wishListItem, action),
      );

      const checkWishlist = isExisted
        ? state
        : Object.assign({}, state, {
            wishListItems: [
              ...state.wishListItems,
              wishListItem(undefined, action),
            ],
            total: state.total + 1,
          });
      createdWishlist(checkWishlist.wishListItems);
      return checkWishlist;
    }
    case REMOVE_WISHLIST_ITEM: {
      const index1 = state.wishListItems.findIndex(wishListItem =>
        compareWishListItem(wishListItem, action),
      ); // check if existed
      return index1 === -1
        ? state // This should not happen, but catch anyway
        : Object.assign({}, state, {
            wishListItems: state.wishListItems.filter(
              wishListItem => !compareWishListItem(wishListItem, action),
            ),
            total: state.total - 1,
          });
    }
    case EMPTY_WISHLIST:
      return Object.assign({}, state, {
        wishListItems: [],
        total: 0,
      });
    default: {
      return state;
    }
  }
};

const compareWishListItem = (wishListItem, action) => {
  return wishListItem.product.id === action.product.id;
};

const wishListItem = (
  state = { product: undefined, variation: undefined },
  action,
) => {
  switch (action.type) {
    case ADD_WISHLIST_ITEM:
      return Object.assign({}, state, { product: action.product });
    default:
      return state;
  }
};
