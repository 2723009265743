import React from 'react';
import { Translate } from 'react-localize-redux';

import NewBanner from '../../../Styles/images/new-banner/5.jpg';
import NewBanner2 from '../../../Styles/images/new-banner/6.jpg';
import NewBanner3 from '../../../Styles/images/new-banner/11.jpg';

export default () => (
  <div className="container-fluid">
    <div className="row justify-content-center">
      <div className="col-md-6 col-lg-4">
        <div className="banner banner-overlay text-white">
          <a href="/#">
            <img src={NewBanner3} alt="Banner" />
          </a>

          <div className="banner-content ">
            <h3 className="banner-title">
              <a href="/#">
                <Translate id="SellNow" />
              </a>
            </h3>
            <h4 className="banner-subtitle">
              <a href="/#">
                <Translate id="BannerSell" />
                <br />
              </a>
            </h4>
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-4">
        <div className="banner banner-overlay color-grey">
          <a href="/#">
            <img src={NewBanner2} alt="Banner" />
          </a>

          <div className="banner-content">
            <h4 className=" banner-title">
              <a href="/#">
                <Translate id="Exchange" />
              </a>
            </h4>
            <h3 className="banner-subtitle">
              <a href="/#">
                <Translate id="BannerExchange" />
              </a>
            </h3>
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-4">
        <div className="banner banner-overlay text-white">
          <a href="/#">
            <img src={NewBanner} alt="Banner" />
          </a>

          <div className="banner-content ">
            <h4 className="banner-title ">
              <a href="/#">
                <Translate id="ShopNow" />
              </a>
            </h4>
            <h3 className="banner-subtitle">
              <a href="/#">
                <Translate id="BannerShop" />
              </a>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
);
