import React from 'react';

import FAQDetails from './Components/FAQDetails';
import Container from '../../Components/Container';

export default () => {
  return (
    <Container>
      <FAQDetails />
    </Container>
  );
};
