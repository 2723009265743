import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';

import Header from './Header/Header';
import MobileMenu from './Header/MobileMenu';
import { SVG } from '../Styles/SVG/SVG';

export default ({ withHeader }) => {
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <>
      <div className={`page-wrapper ${openMenu ? 'mmenu-active' : ''}`}>
        {withHeader && (
          <Header invertColor setOpenMenu={setOpenMenu} openMenu={openMenu} />
        )}
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <div
            style={{
              width: '340px',
              marginBottom: 20,
            }}>
            {SVG.error}
          </div>
          <div
            style={{
              fontSize: 23,
              fontWeight: '900',
            }}>
            <Translate id="ErrorPageContent" />
          </div>
        </div>
      </div>
      {withHeader && (
        <MobileMenu setOpenMenu={setOpenMenu} openMenu={openMenu} />
      )}
    </>
  );
};
