import React from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Details from './Components/Details';
import Container from '../../Components/Container';
import { PRODUCTBYID } from './Product-gql';
import Loading from '../../Components/Loading';
import ErrorPage from '../../Components/ErrorPage';

const mapStateToProps = state => ({
  account: state.Account,
  cart: state.Cart,
});

export default connect(mapStateToProps)(
  ({
    history,
    match: { params },
    account: { user, language, currency },
    cart: { cartItems },
  }) => {
    if (!params?.id) {
      history.push('/');
    }

    const { data, loading, error, refetch } = useQuery(PRODUCTBYID, {
      variables: {
        id: params?.id,
      },
    });

    return error ? (
      <ErrorPage withHeader />
    ) : loading ? (
      <Loading withHeader />
    ) : (
      <Container>
        <Details
          product={data.productById}
          DomLink={Link}
          user={user}
          refetch={refetch}
          language={language}
          cartItems={cartItems}
          currency={currency}
        />
      </Container>
    );
  },
);
