import React from 'react';
import { useMutation } from 'react-apollo';
import { connect } from 'react-redux';

import NewProduct from './Components/NewProductDetails';
import { CREATEPRODUCT } from './NewProduct-gql';
import Container from '../../Components/Container';

const mapStateToProps = state => ({
  account: state.Account,
  configs: state.Configs,
});

export default connect(mapStateToProps)(
  ({ account: { user, language, currency }, configs: { category } }) => {
    const [createProduct] = useMutation(CREATEPRODUCT);

    const categoryOptions = category.map(category => ({
      value: category.id,
      label: category.name[language],
    }));

    return (
      <Container>
        <NewProduct
          createProduct={createProduct}
          user={user}
          categoryOptions={categoryOptions}
          currency={currency}
        />
      </Container>
    );
  },
);
