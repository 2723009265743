import React, { useEffect } from 'react';
import { LocalizeProvider } from 'react-localize-redux';
import Router from './Navigation/Router';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './Store';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';

import { ApolloProvider } from 'react-apollo';
import client from './Graphql/Client';

export const initGA = () => {
  ReactGA.initialize('UA-174558087-1');
};

export const GApageView = page => {
  ReactGA.pageview(page);
};

const App = () => {
  useEffect(() => {
    initGA();
  }, []);

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocalizeProvider>
            <Router />
          </LocalizeProvider>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
