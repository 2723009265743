import React, { useState, useEffect } from 'react';
import Header from './Header/Header';
import ScrollUp from './ScrollUp/ScrollUp';
import Footer from './Footer/Footer';
import MobileMenu from './Header/MobileMenu';
import { GApageView } from '../App';

export default ({ children }) => {
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    GApageView(window.location.pathname);
  }, []);

  return (
    <>
      <div className={`page-wrapper ${openMenu ? 'mmenu-active' : ''}`}>
        <Header invertColor setOpenMenu={setOpenMenu} openMenu={openMenu} />
        {children}
        <ScrollUp />
        <Footer />
      </div>
      <MobileMenu setOpenMenu={setOpenMenu} openMenu={openMenu} />
    </>
  );
};
