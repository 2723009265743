import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Slider from '@material-ui/core/Slider';
import { useSpring, animated } from 'react-spring/web.cjs';
import { toast } from 'react-toastify';
import { Translate } from 'react-localize-redux';

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export default ({ modal, toggle, file, doneCropping }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [aspect] = useState(9 / 16);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [rotation, setRotation] = useState(0);

  const clearData = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
    setRotation(0);
  };

  const getCroppedImg = (image, crop, rotation) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    canvas.style.objectFit = 'cover';

    const ctx = canvas.getContext('2d');
    ctx.save();
    ctx.clearRect(0, 0, crop.width, crop.height);

    if (rotation === 0) {
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height,
      );
    } else {
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.drawImage(image, -image.width / 2, -image.width / 2);
    }
    ctx.restore();

    return new Promise((resolve, reject) => {
      resolve(canvas.toDataURL('image/jpeg'));
    });
  };

  const showResult = useCallback(async () => {
    try {
      var x = document.createElement('IMG');
      x.setAttribute('src', file);
      x.setAttribute('alt', 'The Pulpit Rock');
      const croppedImage = await getCroppedImg(x, croppedAreaPixels, rotation);

      doneCropping(croppedImage);
      toggle();
      clearData();
      setLoading(false);
    } catch (e) {
      if (e) {
        toast.error(<Translate id="SomethingWrongWithPicture" />);
        toggle();
        setLoading(false);
      }
    }
  }, [file, croppedAreaPixels, rotation, toggle, doneCropping]);

  const [loading, setLoading] = useState(false);

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={modal}
      className="modal cash-modal"
      onClose={toggle}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={modal} className="fade-in">
        <div className="modal-dialog crop-image" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <Translate id="Crop image" />
              </h5>
              <button
                type="button"
                className="close"
                onClick={toggle}
                aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="crop-container">
                <Cropper
                  image={file}
                  crop={crop}
                  zoom={zoom}
                  rotation={rotation}
                  aspect={aspect}
                  onCropChange={crop => setCrop(crop)}
                  onCropComplete={(croppedArea, croppedAreaPixels) =>
                    setCroppedAreaPixels(croppedAreaPixels)
                  }
                  onZoomChange={zoom => setZoom(zoom)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                }}>
                <div
                  style={{
                    marginRight: 10,
                  }}>
                  <Translate id="Zoom" />
                </div>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(zoom)}
                />
              </div>
              {/* <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                }}>
                <div
                  style={{
                    marginRight: 10,
                  }}>
                  <Translate id="Rotate" />
                </div>
                <div
                  onClick={() =>
                    setRotation(prevState =>
                      prevState === 360 ? 90 : prevState + 90,
                    )
                  }>
                  <i
                    style={{
                      transform: 'scaleX(-1)',
                      fontSize: 18,
                    }}
                    className="fa fa-undo"></i>
                </div>
              </div>*/}
              <div
                style={{
                  display: 'flex',
                  marginTop: '10px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <button
                  onClick={() => {
                    showResult();
                    setLoading(true);
                  }}
                  type="button"
                  className="btn btn-primary">
                  {loading ? (
                    <div>
                      <Translate id="Loading" />{' '}
                      <i className="fa fa-spinner fa-pulse" />
                    </div>
                  ) : (
                    <Translate id="Done" />
                  )}
                </button>

                <button
                  onClick={toggle}
                  type="button"
                  className="btn btn-secondary">
                  <Translate id="Close" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
