import React, { useState, useEffect } from 'react';
import moment from 'moment';

const CountDown = ({ timeTillDate, timeFormat }) => {
  const [state, setState] = useState({
    days: 1,
    hours: 1,
    minutes: 1,
    seconds: 1,
  });

  useEffect(() => {
    setInterval(() => {
      const then = moment(timeTillDate, timeFormat);
      const now = moment(moment(), timeFormat);
      var totalSec = then.diff(now, 'seconds');
      const days = parseInt(totalSec / 86400) % 360;
      const hours = parseInt(totalSec / 3600) % 24;
      const minutes = parseInt(totalSec / 60) % 60;
      const seconds = parseInt(totalSec) % 60;
      setState({ days, hours, minutes, seconds });
    }, 1000);
  }, [timeTillDate, timeFormat]);

  const { days, hours, minutes, seconds } = state;

  const daysRadius = mapNumber(days, 360, 0, 0, 360);
  const hoursRadius = mapNumber(hours, 24, 0, 0, 360);
  const minutesRadius = mapNumber(minutes, 60, 0, 0, 360);
  const secondsRadius = mapNumber(seconds, 60, 0, 0, 360);

  return (
    <div className="countdown-wrapper">
      <div className="countdown-item">
        <SVGCircle radius={daysRadius} />
        {days}
        <span>days</span>
      </div>
      <div className="countdown-item">
        <SVGCircle radius={hoursRadius} />
        {hours}
        <span>hours</span>
      </div>
      <div className="countdown-item">
        <SVGCircle radius={minutesRadius} />
        {minutes}
        <span>minutes</span>
      </div>
      <div className="countdown-item">
        <SVGCircle radius={secondsRadius} />
        {seconds}
        <span>seconds</span>
      </div>
    </div>
  );
};

const SVGCircle = ({ radius }) => (
  <svg className="countdown-svg">
    <path
      fill="none"
      stroke="#c96"
      strokeWidth="4"
      d={describeArc(50, 50, 48, 0, radius)}
    />
  </svg>
);

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

const describeArc = (x, y, radius, startAngle, endAngle) => {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);
  var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
  var d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(' ');

  return d;
};

const mapNumber = (number, in_min, in_max, out_min, out_max) =>
  ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;

export default CountDown;
