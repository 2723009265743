import React, { useState } from 'react';
// import { toast } from 'react-toastify';
import coverImage from '../../../Styles/images/page-header-bg.jpg';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import ar from 'react-phone-input-2/lang/ar.json';
import Select from 'react-select';
import { SUBMIT_CASHBACK } from '../Cashback-gql';
import { useMutation } from 'react-apollo';
import { toast } from 'react-toastify';
import LoadingScreen from '../../../Components/LoadingScreen';

const mapStateToProps = ({ Account }) => ({
  account: Account,
});

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Password is required'),
  lastName: Yup.string().required(),
  mobile: Yup.string().required(),
  email: Yup.string().required(),
});

const transfetToOptions = [
  { value: 'DOMESTIC PAYMENT', label: 'DOMESTIC PAYMENT' },
  { value: 'WESTREN UNION', label: 'WESTREN UNION' },
];

export default connect(mapStateToProps)(
  ({ account: { user, language, currency } }) => {
    const [submitCashback] = useMutation(SUBMIT_CASHBACK);
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
      initialValues: {
        firstName: user.account.firstName,
        lastName: user.account.lastName,
        middleName: '',
        fullName: `${user.account.firstName} ${user.account.lastName}`,
        mobile: user.account.mobile,
        email: user.account.email,
        transfetTo: { value: 'DOMESTIC PAYMENT', label: 'DOMESTIC PAYMENT' },
        iBan: '',
      },
      onSubmit: () => handleSubmits(),
      validationSchema: ValidationSchema,
    });

    const { values, handleChange, handleSubmit, setFieldValue } = formik;
    const {
      firstName,
      lastName,
      transfetTo,
      middleName,
      email,
      fullName,
      iBan,
    } = values;

    const handleSubmits = async () => {
      setLoading(true);
      await submitCashback({
        variables: {
          cashback: {
            user: user.id,
            details: {
              firstName: values.firstName,
              lastName: values.lastName,
              middleName: values.middleName,
              mobile: values.mobile,
              email: values.email,
              transfetTo: values.transfetTo.value,
              iBan: values.iBan,
            },
          },
        },
      })
        .then(({ data }) => {
          if (data) {
            toast.success(<Translate id="cashbackWaitMessage" />, {
              onClose: () => (window.location.href = '/'),
            });
          }
        })
        .catch(error =>
          error.graphQLErrors.length > 0
            ? toast.error(
                error.graphQLErrors[0].message === 'AlreadySubmited' ? (
                  <Translate id="alreadySubmitCashBack" />
                ) : (
                  <Translate id="somethingWentWrong" />
                ),
              )
            : toast.error(<Translate id="somethingWentWrong" />),
        );

      setLoading(false);
    };

    return (
      <Translate>
        {({ translate }) => (
          <main className="cash-back-container main checkout-container">
            {loading && <LoadingScreen />}
            <div
              className="page-header text-center"
              style={{ backgroundImage: `url(${coverImage})` }}>
              <div className="container">
                <h1 className="page-title">
                  <Translate id="CashBack" />
                  <span>
                    <Translate id="Account" />
                  </span>
                </h1>
              </div>
            </div>
            <nav aria-label="breadcrumb" className="breadcrumb-nav">
              <div className="container">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">
                      <Translate id="home" />
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/profile">
                      <Translate id="Account" />
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Translate id="checkout" />
                  </li>
                </ol>
              </div>
            </nav>
            <div className="page-content">
              <div className="checkout">
                <div className="container">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-9">
                        <h2 className="checkout-title">
                          <Translate id="billingdetails" />
                        </h2>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className={'form-group'}>
                              <label>
                                <Translate id="Transfer money to" />:
                              </label>
                              <Select
                                placeholder={translate('Select ...')}
                                options={transfetToOptions}
                                defaultValue={transfetTo}
                                onChange={e =>
                                  setFieldValue('transfetTo', e.value)
                                }
                              />
                            </div>
                          </div>
                          {transfetTo === 'WESTREN UNION' ? (
                            <>
                              <div className="col-sm-4">
                                <label>
                                  <Translate id="BENEFICIARY FIRST NAME" />*
                                </label>
                                <input
                                  type="text"
                                  className={'form-control'}
                                  required
                                  name="firstName"
                                  value={firstName}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-sm-4">
                                <label>
                                  <Translate id="BENEFICIARY MIDDLE NAME" />*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  name="middleName"
                                  value={middleName}
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="col-sm-4">
                                <label>
                                  <Translate id="BENEFICIARY LAST NAME" />*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  name="lastName"
                                  value={lastName}
                                  onChange={handleChange}
                                />
                              </div>
                            </>
                          ) : (
                            <div className="col-sm-12">
                              <label>
                                <Translate id="FULL NAME" />
                              </label>
                              <input
                                type="text"
                                className={'form-control'}
                                required
                                name="fullName"
                                value={fullName}
                                onChange={handleChange}
                              />
                            </div>
                          )}
                        </div>

                        <div className="row">
                          <div className="col-sm-6">
                            <label>
                              {transfetTo === 'WESTREN UNION' ? (
                                <>
                                  <Translate id="BENEFICIARY EMAIL ADDRESS" />:{' '}
                                  <Translate id="(" />{' '}
                                  <Translate id="Optional" />{' '}
                                  <Translate id=")" />{' '}
                                </>
                              ) : (
                                <>
                                  <Translate id="EMAIL ADDRESS" />:{' '}
                                  <Translate id="(" />{' '}
                                  <Translate id="Optional" />{' '}
                                  <Translate id=")" />{' '}
                                </>
                              )}
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              required
                              name="email"
                              onChange={handleChange}
                              value={email}
                            />
                          </div>

                          <div className="col-sm-6">
                            <label>
                              {transfetTo === 'WESTREN UNION'
                                ? 'BENEFICIARY PHONE NUMBER*'
                                : 'PHONE NUMBER: ( OPTIONAL )'}
                            </label>
                            <PhoneInput
                              onlyCountries={['jo']}
                              className="form-control"
                              country="jo"
                              onChange={(e, country) =>
                                e.length < 15 &&
                                setFieldValue('mobile', e) &&
                                setFieldValue('country', country.countryCode)
                              }
                              localization={language === 'ar' && ar}
                              value={values.mobile}
                              required
                            />
                          </div>
                        </div>

                        {transfetTo !== 'WESTREN UNION' && (
                          <>
                            <label>IBAN</label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              placeholder={translate('16 DIGIT NUMBERS')}
                              name="iBan"
                              value={iBan}
                              onChange={handleChange}
                            />
                          </>
                        )}
                      </div>
                      <aside className="col-lg-3">
                        <div className="summary">
                          <h3 className="summary-title">
                            <Translate id="myWallet" />
                          </h3>

                          <h3>
                            {Intl.NumberFormat(`${language}-JD`, {
                              style: 'currency',
                              currency: currency.value,
                            })
                              .format(user.storyCredit)
                              .replace('.000', '')}
                          </h3>
                          <div
                            style={{
                              marginBottom: 10,
                            }}>
                            <Translate id="Fees 2" />{' '}
                            <Translate id={currency.value} />
                          </div>
                          <button
                            type="submit"
                            className="btn btn-outline-primary-2 btn-order btn-block">
                            <span className="btn-text">
                              <Translate id="CashBack" />
                            </span>
                            <span className="btn-hover-text">
                              <Translate id="24hrs to get it back" />
                            </span>
                          </button>
                        </div>
                      </aside>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </main>
        )}
      </Translate>
    );
  },
);
