import React from 'react';
import { Translate } from 'react-localize-redux';

import Container from '../../Components/Container';
import { SVG } from '../../Styles/SVG/SVG';

const NotFound = () => {
  return (
    <Container>
      <main className="main" style={{ marginTop: '7vh' }}>
        <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">
                  <Translate id="home" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                404
              </li>
            </ol>
          </div>
        </nav>

        <div
          className="error-content text-center"
          style={{
            paddingTop: 25,
          }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}>
            <div
              style={{
                width: '340px',
                marginBottom: 20,
              }}>
              {SVG.notFound}
            </div>
            <a
              href="/"
              className="btn btn-outline-primary-2 btn-minwidth-lg "
              style={{
                marginBottom: 30,
              }}>
              <span>BACK TO HOMEPAGE</span>
              <i className="icon-long-arrow-right"></i>
            </a>
          </div>
        </div>
      </main>
    </Container>
  );
};

export default NotFound;
