import React from 'react';

import BG from '../../Styles/images/page-header-bg.jpg';

import Container from '../Container';
import { Translate } from 'react-localize-redux';

export default () => {
  return (
    <Container>
      <main className="main faq-page">
        <div
          className="page-header text-center"
          style={{ backgroundImage: `url(${BG})` }}>
          <div className="container">
            <h1 className="page-title">
              <Translate id="Privacy and usage policy" />
            </h1>
          </div>
        </div>
        <nav aria-label="breadcrumb" className="breadcrumb-nav">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">
                  <Translate id="home" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <Translate id="Privacy and usage policy" />
              </li>
            </ol>
          </div>
        </nav>
        <div className="page-content pb-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="about-text  mt-3">
                  <p>
                    - <Translate id="privacy1" />
                  </p>
                  <p>
                    - <Translate id="privacy2" />
                  </p>
                  <p>
                    - <Translate id="privacy3" />
                    <div className="offset-lg-1">
                      <div>
                        - <Translate id="privacy31" />
                      </div>
                      <div>
                        - <Translate id="privacy32" />
                      </div>
                      <div>
                        - <Translate id="privacy33" />
                      </div>
                      <div>
                        - <Translate id="privacy34" />
                      </div>
                    </div>
                  </p>
                  <p>
                    - <Translate id="privacy4" />
                  </p>
                  <p>
                    - <Translate id="privacy5" />
                  </p>
                  <p>
                    - <Translate id="privacy6" />
                  </p>
                  <p>
                    - <Translate id="privacy7" />
                  </p>
                  <p>
                    - <Translate id="privacy8" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Container>
  );
};
