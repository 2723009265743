import { GET_NOTIFICATIONS } from '../Action';

const initialState = {
  notifications: [],
  notifiactionNotRead: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload.data,
        notifiactionNotRead: payload.number,
      };
    default:
      return state;
  }
};
