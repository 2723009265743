import React from 'react';

import CashbackDetails from './Components/CashbackDetails';
import Container from '../../Components/Container';

export default () => {
  return (
    <Container>
      <CashbackDetails />
    </Container>
  );
};
