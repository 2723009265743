import React, { useState } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';

import ImageSoon from '../../Styles/images/backgrounds/soon-bg.jpg';
import CountDown from './Components/CountDown';
import { subscribeUrl } from '../../Configs';
import Logo from '../../Styles/images/logo-en-layls-gold.png';
import { Translate } from 'react-localize-redux';

const CommingSoon = () => {
  const [email, setEmail] = useState('');

  const subscribe = e => {
    e.preventDefault();
    Axios.post(subscribeUrl, {
      email,
    })
      .then(() => {
        toast.success('Thanks');
        document.getElementById('name').value = '';
      })
      .catch(() => {
        toast.error(<Translate id="somethingWentWrong" />);
        document.getElementById('name').value = '';
      });
  };

  return (
    <div className="soon">
      <div className="container">
        <div className="row">
          <div className="col-md-9 col-lg-8">
            <div className="soon-content text-center">
              <div className="soon-content-wrapper">
                <img
                  src={Logo}
                  alt="L"
                  className="soon-logo mx-auto"
                  style={{ width: '100px' }}
                />
                <h1 className="soon-title" style={{ fontSize: '22px' }}>
                  Coming Soon
                </h1>
                <CountDown
                  timeTillDate="03/15/2020, 12:00 am"
                  timeFormat="MM/DD/YYYY, h:mm a"
                />
                <div className="coming-countdown countdown-separator"></div>
                <hr className="mt-2 mb-3 mt-md-3" />
                <p>
                  We are currently working on an awesome new site. Stay tuned
                  for more information. Subscribe to our newsletter to stay
                  updated on our progress.
                </p>
                <form onSubmit={subscribe}>
                  <div className="input-group mb-5">
                    <input
                      type="email"
                      id="name"
                      className="form-control bg-transparent"
                      placeholder="Enter your Email Address"
                      onChange={e => setEmail(e.target.value)}
                      required
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-primary-2"
                        type="submit">
                        <span>SUBSCRIBE</span>
                        <i className="icon-long-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </form>
                <div className="social-icons justify-content-center mb-0">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/laylscom/"
                    className="social-icon"
                    title="Facebook">
                    <i className="icon-facebook-f"></i>
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/laylscom/"
                    className="social-icon"
                    title="Instagram">
                    <i className="icon-instagram"></i>
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/"
                    className="social-icon"
                    title="Snapchat">
                    <i className="icon-snapchat-ghost"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="soon-bg bg-image"
        style={{ backgroundImage: `url(${ImageSoon})` }}></div>
    </div>
  );
};

export default CommingSoon;
