import React, { useState } from 'react';
import Axios from 'axios';

import BG from '../../../Styles/images/page-header-bg.jpg';
import { contactusUrl } from '../../../Configs';
import { Translate } from 'react-localize-redux';
import { toast } from 'react-toastify';

export default () => {
  const [state, setState] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const onChange = e => {
    e.persist();
    setState(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    Axios.post(contactusUrl, state)
      .then(() => {
        toast.success(<Translate id="MessageSent" />, {
          onClose: () => window.location.reload(),
        });
      })
      .catch(() => toast.error(<Translate id="somethingWentWrong" />));
  };

  return (
    <Translate>
      {({ translate }) => (
        <main className="main contact-us">
          <div
            className="page-header text-center"
            style={{ backgroundImage: `url(${BG})` }}>
            <div className="container">
              <h1 className="page-title">
                <Translate id="ContactUs" />
              </h1>
            </div>
          </div>
          <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <Translate id="home" />
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <Translate id="ContactUs" />
                </li>
              </ol>
            </div>
          </nav>

          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="contact-box text-center">
                    <h3>
                      <Translate id="Office" />
                    </h3>

                    <address>
                      Amman, <br />
                      Swifeh, Jordan
                    </address>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="contact-box text-center">
                    <h3>Start a Conversation</h3>

                    <div>
                      <a href="mailto:info@layls.com">info@layls.com</a>
                    </div>
                    <div>
                      <a href="tel:#">+962 78000000</a>,{' '}
                      <a href="tel:#">+962 78000000</a>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="contact-box text-center">
                    <h3>
                      <Translate id="Shop Social" />
                    </h3>

                    <div className="social-icons justify-content-center">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/laylscom/"
                        className="social-icon"
                        title="Facebook">
                        <i className="icon-facebook-f"></i>
                      </a>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/laylscom/"
                        className="social-icon"
                        title="Instagram">
                        <i className="icon-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="mt-3 mb-5 mt-md-1" />
              <div className="touch-container row justify-content-center">
                <div className="col-md-9 col-lg-7">
                  <div className="text-center">
                    <h2 className="title mb-1">Get In Touch</h2>
                    <p className="lead text-primary">
                      We collaborate with ambitious brands and people; we’d love
                      to build something great together.
                    </p>
                    <p className="mb-3">
                      Vestibulum volutpat, lacus a ultrices sagittis, mi neque
                      euismod dui, eu pulvinar nunc sapien ornare nisl.
                      Phasellus pede arcu, dapibus eu, fermentum et, dapibus
                      sed, urna.
                    </p>
                  </div>

                  <form onSubmit={handleSubmit} className="contact-form mb-2">
                    <div className="row">
                      <div className="col-sm-4">
                        <label htmlFor="cname" className="sr-only">
                          {translate('name')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="cname"
                          placeholder={translate('name')}
                          required
                          name="name"
                          onChange={onChange}
                        />
                      </div>

                      <div className="col-sm-4">
                        <label htmlFor="cemail" className="sr-only">
                          {translate('email')}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="cemail"
                          placeholder={translate('email')}
                          required
                          name="email"
                          onChange={onChange}
                        />
                      </div>

                      <div className="col-sm-4">
                        <label htmlFor="cphone" className="sr-only">
                          {translate('mobile')}
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          id="cphone"
                          placeholder={translate('mobile')}
                          name="phone"
                          onChange={onChange}
                        />
                      </div>
                    </div>

                    <label htmlFor="csubject" className="sr-only">
                      {translate('subject')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="csubject"
                      placeholder={translate('subject')}
                      name="subject"
                      onChange={onChange}
                    />

                    <label htmlFor="cmessage" className="sr-only">
                      {translate('message')}
                    </label>
                    <textarea
                      className="form-control"
                      cols="30"
                      rows="4"
                      id="cmessage"
                      required
                      placeholder={translate('message')}
                      name="message"
                      onChange={onChange}></textarea>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-outline-primary-2 btn-minwidth-sm">
                        <span>{translate('Submit')}</span>
                        <i className="icon-long-arrow-right"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </Translate>
  );
};
