import React, { useState } from 'react';
import Lightbox, { Modal, ModalGateway } from 'react-images';
import { PUBLICURI } from '../../../Configs';

export const ProductGallery = ({ images }) => {
  const [state, setState] = useState({
    lightboxIsOpen: false,
    currentImage: 0,
    currentImagePreview: 0,
  });

  const changeImg = (i, e) => {
    e.preventDefault();
    setState(prevState => ({
      ...prevState,
      currentImagePreview: i,
      currentImage: i,
    }));
  };

  const openLightbox = (index, event) => {
    event.preventDefault();
    setState(prevState => ({
      ...prevState,
      currentImage: index,
      lightboxIsOpen: true,
    }));
  };

  const closeLightbox = () => {
    setState(prevState => ({
      ...prevState,
      currentImage: prevState.currentImagePreview,
      lightboxIsOpen: false,
    }));
  };

  const { currentImage, currentImagePreview, lightboxIsOpen } = state;
  return (
    <div className="product-gallery">
      <a
        className="product-gallery__current-img"
        onClick={e => openLightbox(currentImage, e)}
        href={images[currentImage].path}>
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundImage: `url(${PUBLICURI}${images[currentImagePreview].path})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
          }}
        />
      </a>
      <div className="product_gallery__gallery">
        {images.map((img, i) => (
          <button
            type="button"
            key={i}
            onClick={e => changeImg(i, e)}
            className="product-gallery__img-preview">
            <img src={`${PUBLICURI}${img.path}`} alt="product-img" />
          </button>
        ))}
      </div>

      <ModalGateway>
        {lightboxIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Lightbox
              views={images}
              currentIndex={currentImage}
              styles={{
                view: () => ({
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }),
              }}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};
