import React from 'react';
import coverImage from '../../../Styles/images/page-header-bg.jpg';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography } from '@material-ui/core';
import { Translate } from 'react-localize-redux';
import moment from 'moment';
import { connect } from 'react-redux';
import { READ_NOTIFICAIOTN } from '../Notifications-gql';
import { useMutation } from 'react-apollo';

const useStyles = makeStyles(theme => ({
  root: {
    width: '95vw',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

const mapStateToProps = state => ({
  account: state.Account,
});

export default connect(mapStateToProps)(
  ({ notification, account: { language } }) => {
    const classes = useStyles();
    const [readNotification] = useMutation(READ_NOTIFICAIOTN);

    const readNotifi = async id => {
      await readNotification({
        variables: {
          id,
        },
      });
      window.location.href = '/profile';
    };

    return (
      <div className="page-wrapper notifications-page">
        <main className="main">
          <div
            className="page-header text-center"
            style={{ backgroundImage: `url(${coverImage})` }}>
            <div className="container">
              <h1 className="page-title">
                <Translate id="Notifications" />
                <span>
                  <Translate id="shop" />
                </span>
              </h1>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <List className={classes.root}>
              {notification.map((data, index) => (
                <div key={data.id}>
                  <div
                    onClick={() => readNotifi(data.id)}
                    style={{
                      padding: 20,
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}>
                      {data.notification.read ? (
                        <div className="circle read"></div>
                      ) : (
                        <div className="circle not-read"></div>
                      )}
                      <ListItemText
                        primary={
                          <Typography variant="h4">
                            {data.notification.title[language]}
                          </Typography>
                        }
                        secondary={
                          <>
                            <Typography variant="h5">
                              {data.notification.body[language]}
                            </Typography>
                            <Typography variant="h6">
                              {moment(data.createdAt).fromNow()}
                            </Typography>
                          </>
                        }
                      />
                    </div>
                  </div>

                  {notification.length - 1 !== index && (
                    <Divider variant="fullWidth" component="li" />
                  )}
                </div>
              ))}
            </List>
          </div>
        </main>
      </div>
    );
  },
);
