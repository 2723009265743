import React from 'react';
import Link from '@material-ui/core/Link';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';

import { PUBLICURI } from '../Configs';
import { addCartItem, addWishListItem } from '../Action';

const mapToProps = state => ({
  account: state.Account,
  cart: state.Cart,
});

const mapDispatchToProps = {
  addCartItems: addCartItem,
  addWishlistItems: addWishListItem,
};

const INCRESE_VISITS = gql`
  mutation increaseVisits($id: ID) {
    increaseVisits(id: $id)
  }
`;

export default connect(
  mapToProps,
  mapDispatchToProps,
)(
  ({
    product: { pictures },
    product,
    addCartItems,
    addWishlistItems,
    DomLink,
    account: { language, user, currency },
    cart: { cartItems },
  }) => {
    const [increaseVisits] = useMutation(INCRESE_VISITS);

    return (
      <div className="product">
        <figure className="product-media">
          {product?.type === 'New' && (
            <span className="product-label label-new">
              <Translate id="new" />
            </span>
          )}
          {product?.onHold && (
            <span className="product-label label-sale">
              <Translate id="onHold" />
            </span>
          )}
          {product?.sold && (
            <span className="product-label label-out">
              <Translate id="outOfStock" />
            </span>
          )}
          <DomLink
            style={{
              height: 'inherit',
            }}
            onClick={() => {
              increaseVisits({
                variables: {
                  id: product.id,
                },
              });
            }}
            to={{
              pathname: `/product/${product.id}`,
              state: { id: product.id },
            }}>
            <img
              src={`${PUBLICURI}${pictures[0]?.path}`}
              alt="Product"
              className="product-image"
            />
            <img
              src={`${PUBLICURI}${pictures[1]?.path}`}
              alt="Product"
              className="product-image-hover"
            />
            {/* <div
              style={{
                width: '100%',
                height: '100%',
                backgroundImage: `url(${PUBLICURI}${pictures[0].path})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '50% 50%',
              }}
              className="product-image"
            />
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundImage: `url(${PUBLICURI}${pictures[1].path})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '50% 50%',
              }}
              className="product-image-hover"
            /> */}
          </DomLink>

          <div className="product-action-vertical">
            <Link
              style={{ cursor: 'pointer' }}
              onClick={() => addWishlistItems(product)}
              className="btn-product-icon btn-wishlist btn-expandable">
              <span>
                <Translate id="addtowishlist" />
              </span>
            </Link>
          </div>

          {cartItems.length === 0 && !product?.onHold && !product?.sold && (
            <div className="product-action product-action-transparent">
              <Link
                style={{ cursor: 'pointer', color: '#c96' }}
                onClick={() =>
                  addCartItems(
                    {
                      currency,
                      ...product,
                    },
                    user.id,
                  )
                }
                className="btn-product btn-cart">
                <span>
                  <Translate id="addtocart" />
                </span>
              </Link>
            </div>
          )}
        </figure>

        <div className="product-body">
          <div className="product-cat">
            <a href="#r">{product?.category?.name[language]}</a>
          </div>
          <h3 className="product-title">
            <a href={`/product/${product.id}`}>{product.name}</a>
          </h3>
          <div className="product-price">
            {product.type === 'New' ? (
              <>
                <span className="new-price">
                  {Intl.NumberFormat(`${language}-JD`, {
                    style: 'currency',
                    currency: currency.value,
                  })
                    .format(product?.priceCurrency[currency.value])
                    .replace('.000', '')}
                </span>
                <span className="old-price">
                  {Intl.NumberFormat(`${language}-JD`, {
                    style: 'currency',
                    currency: currency.value,
                  })
                    .format(product?.oldPriceCurrency[currency.value])
                    .replace('.000', '')}
                </span>
              </>
            ) : (
              <>
                {Intl.NumberFormat(`${language}-JD`, {
                  style: 'currency',
                  currency: currency.value,
                })
                  .format(product?.priceCurrency[currency.value])
                  .replace('.000', '')}
              </>
            )}
          </div>
        </div>
      </div>
    );
  },
);
