import gql from 'graphql-tag';
import { USER_GQL } from '../../Navigation/Router-gql';

export const SIGNUP = gql`
  mutation createUser($user: UserInput) {
    createUser(user: $user) {
      ${USER_GQL}
    }
  }
`;
