import React from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import BG from '../../../Styles/images/backgrounds/login-bg.jpg';
import Footer from '../../../Components/Footer/Footer';

const mapStateToProps = state => ({
  account: state.Account,
});

export default connect(mapStateToProps)(
  ({ location: { state }, account: { language, currency } }) => {
    return (
      <div className="page-wrapper thankyou ">
        <main className="main">
          <div
            className="login-page bg-image pt-8 pb-8 pt-md-12 pb-md-12 pt-lg-17 pb-lg-17"
            style={{ backgroundImage: `url(${BG})` }}>
            <div className="content container">
              <div className="row">
                <div className="col-lg-9 left">
                  <h1 className="thanks">
                    <Translate id="Thanks" />
                  </h1>
                  <h5>Costumer Name</h5>
                  <h6>Thank You for the order & Welcome to LAYLS Family.</h6>
                  <p>You have successfully ordered "Order details".</p>
                  <p>Share with friends</p>
                  <div className="wishlist-share">
                    <div className="social-icons social-icons-sm mb-2">
                      <label className="social-label"></label>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/laylscom/"
                        className="social-icon"
                        title="Facebook">
                        <i className="icon-facebook-f"></i>
                      </a>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/laylscom/"
                        className="social-icon"
                        title="Instagram">
                        <i className="icon-instagram"></i>
                      </a>
                    </div>
                  </div>
                  <p>Email us at "Email" with any questions</p>
                  <a
                    href="/all-product"
                    className="btn btn-outline-dark-2 btn-block mb-4 shopping-cart-btn"
                    style={{ width: '1vw' }}>
                    <span>Return to store</span>
                  </a>
                </div>
                <aside className="col-lg-3">
                  <div className="summary summary-cart">
                    <h3 className="summary-title">Your Order Summary</h3>

                    <table className="table table-summary">
                      <tbody>
                        <tr className="summary-shipping">
                          <td>Shipping:</td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr className="summary-shipping-row">
                          <td>
                            <div className="custom-control custom-radio">
                              <label
                                className="custom-control-label"
                                htmlFor="standart-shipping">
                                Standart:
                              </label>
                            </div>
                          </td>
                          <td>
                            {Intl.NumberFormat(`${language}-JD`, {
                              style: 'currency',
                              currency: currency.value,
                            })
                              .format(5)
                              .replace('.000', '')}
                          </td>
                        </tr>
                        <tr className="summary-subtotal">
                          <td>Order total:</td>
                          <td>
                            {Intl.NumberFormat(`${language}-JD`, {
                              style: 'currency',
                              currency: currency.value,
                            })
                              .format(state?.orderTotal)
                              .replace('.000', '')}
                          </td>
                        </tr>
                        <tr className="summary-shipping-estimate">
                          <td>
                            We will deliver to
                            <p>
                              {`${state?.address?.city}, ${state?.address?.street}, ${state?.address?.appartment}`}
                            </p>
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  },
);
