import React, { useState } from 'react';
import { connect } from 'react-redux';

import Header from '../../Components/Header/Header';
import MainPage from './Components/MainPage';
import ScrollUp from '../../Components/ScrollUp/ScrollUp';
import MobileMenu from '../../Components/Header/MobileMenu';
import Footer from '../../Components/Footer/Footer';

const Home = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      <div className={`page-wrapper ${openMenu ? 'mmenu-active' : ''}`} id="#">
        <Header setOpenMenu={setOpenMenu} openMenu={openMenu} />
        <MainPage />
        <ScrollUp />
        <Footer />
      </div>
      <MobileMenu setOpenMenu={setOpenMenu} openMenu={openMenu} />
    </>
  );
};

const mapToProps = state => ({
  account: state.Account,
});

export default connect(mapToProps)(Home);
