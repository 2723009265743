import React, { useState } from 'react';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
import { Col, Container } from 'reactstrap';
import { Translate } from 'react-localize-redux';

import { addCartItem, addWishListItem } from '../../../Action';
import { PUBLICURI } from '../../../Configs';
import SizeGuideModal from './SizeGuideModal';
import { ProductGallery } from './ProductGallery';
import ProductCard from '../../../Components/ProductCard';

const mapDispatchToProps = {
  addCartItems: addCartItem,
  addWishlistItems: addWishListItem,
};

export default connect(
  null,
  mapDispatchToProps,
)(
  ({
    addCartItems,
    addWishlistItems,
    product,
    product: {
      type,
      oldPrice,
      price,
      description,
      pictures,
      color,
      shape,
      size,
      qty: productQty,
      selectedColor,
      chest,
      high,
      hip,
      length,
      waist,
      origin,
      sleeve,
      shoulder,
      onHold,
      sold,
      underlay,
      visits,
      priceCurrency,
      oldPriceCurrency,
      user: { id: userId },
    },
    DomLink,
    user: { id },
    refetch,
    language,
    cartItems,
    currency,
  }) => {
    const [qty, setQty] = useState(productQty);

    const [images] = useState(
      pictures.map(pic => ({
        src: `${PUBLICURI}${pic?.path}`,
        ...pic,
      })),
    );
    const [openModal, setOpen] = useState(false);

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <>
        <SizeGuideModal open={openModal} handleClose={handleClose} />
        <main className="main" style={{ marginTop: '7vh' }}>
          <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
            <div className="container d-flex align-items-center">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <Translate id="home" />
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <Translate id="product" />
                </li>
              </ol>
            </div>
          </nav>

          <div className="page-content" style={{ marginTop: 10 }}>
            <div className="container">
              <div className="product-details-top">
                <div className="row">
                  <div className="col-md-5">
                    <ProductGallery images={images} />
                  </div>
                  <div className="col-md-6">
                    <div className="product-details">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}>
                        <h1 className="product-title">{product.name}</h1>
                        {userId === id && (
                          <h1 className="product-title">
                            <Translate id="Visits" />: {visits}
                          </h1>
                        )}
                      </div>

                      {type === 'New' ? (
                        <>
                          <div
                            className="old-price"
                            style={{
                              fontSize: 22,
                            }}>
                            <Translate id="oldprice" />:
                            {Intl.NumberFormat(`${language}-JD`, {
                              style: 'currency',
                              currency: currency.value,
                            })
                              .format(oldPriceCurrency[currency.value])
                              .replace('.000', '')}
                          </div>
                          <div className="product-price">
                            <Translate id="newprice" />:{' '}
                            {Intl.NumberFormat(`${language}-JD`, {
                              style: 'currency',
                              currency: currency.value,
                            })
                              .format(priceCurrency[currency.value])
                              .replace('.000', '')}
                          </div>
                        </>
                      ) : (
                        <div className="product-price">
                          <Translate id="price" />:{' '}
                          {Intl.NumberFormat(`${language}-JD`, {
                            style: 'currency',
                            currency: currency.value,
                          })
                            .format(priceCurrency[currency.value])
                            .replace('.000', '')}
                        </div>
                      )}

                      <div className="product-content">
                        <p>{description}</p>
                      </div>

                      <div className="details-filter-row details-row-size">
                        <label htmlFor="size">
                          <Translate id="size" />:
                        </label>
                        <label>{size.label}</label>
                        <div
                          className="size-guide"
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={handleOpen}>
                          <i className="icon-th-list"></i>
                          <Translate id="sizeguide" />
                        </div>
                      </div>

                      <div className="details-filter-row details-row-size">
                        <label htmlFor="qty">
                          <Translate id="quantity" />:
                        </label>
                        <div className="input-group  input-spinner product-details-quantity">
                          <div className="input-group-prepend">
                            {productQty > 1 && (
                              <button
                                onClick={() => qty > 1 && setQty(qty - 1)}
                                style={{ minWidth: '26px' }}
                                className="btn btn-decrement btn-spinner"
                                type="button">
                                <i className="icon-minus"></i>
                              </button>
                            )}
                          </div>
                          <div
                            style={{ textAlign: 'center' }}
                            className="form-control ">
                            {qty}
                          </div>
                          <div className="input-group-append">
                            {productQty > 1 && (
                              <button
                                onClick={() =>
                                  productQty > qty && setQty(qty + 1)
                                }
                                style={{ minWidth: '26px' }}
                                className="btn btn-increment btn-spinner"
                                type="button">
                                <i className="icon-plus"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="product-details-action invert">
                        {cartItems.length === 0 &&
                          productQty > 0 &&
                          userId !== id && (
                            <Link
                              style={{ cursor: 'pointer' }}
                              className="btn-product btn-cart"
                              onClick={() => {
                                setTimeout(() => refetch(), 1000);
                                setQty(qty - 1);
                                addCartItems(
                                  {
                                    currency,
                                    ...product,
                                  },
                                  id,
                                  qty,
                                );
                              }}>
                              <span>
                                <Translate id="addtocart" />
                              </span>
                            </Link>
                          )}

                        <div className="details-action-wrapper">
                          {productQty > 0 && userId !== id && (
                            <Link
                              style={{ cursor: 'pointer' }}
                              className="btn-product btn-wishlist"
                              title="Wishlist"
                              onClick={() => addWishlistItems(product)}>
                              <span>
                                <Translate id="addtowishlist" />
                              </span>
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className="product-details-tab">
                        <ul
                          className="nav nav-pills justify-content-center"
                          role="tablist">
                          <li className="nav-item">
                            <div className="nav-link active">
                              <Translate id="Details" />
                            </div>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div
                            className="tab-pane fade-in show active"
                            style={{
                              padding: 10,
                            }}>
                            <div className="product-desc-content">
                              <h3>
                                <Translate id="Product Information" />
                              </h3>

                              <div className="details-filter-row product-cat">
                                <span style={{ width: 140, marginRight: '0' }}>
                                  <Translate id="Category" />:
                                </span>
                                <a href={product.category.id}>
                                  {product.category.name.en}
                                </a>
                              </div>

                              <div
                                className="details-filter-row product-cat "
                                style={{
                                  width: '100%',
                                }}>
                                <label htmlFor="size" style={{ width: 140 }}>
                                  <Translate id="Shape" />:
                                </label>
                                <label
                                  style={{
                                    width: '50%',
                                  }}>
                                  {shape.label}
                                </label>
                              </div>

                              <div className="details-filter-row product-cat">
                                <label style={{ width: 140 }}>
                                  <Translate id="color" />:
                                </label>
                                <div className="product-nav product-nav-dots">
                                  <a href="#r" style={{ background: color }}>
                                    <span className="sr-only">{color}</span>
                                  </a>
                                </div>
                              </div>

                              <div
                                className="details-filter-row product-cat "
                                style={{
                                  width: '100%',
                                }}>
                                <label htmlFor="size" style={{ width: 140 }}>
                                  <Translate id="Chest / Breast" />:
                                </label>
                                <label
                                  style={{
                                    width: '50%',
                                  }}>
                                  {chest}
                                </label>
                              </div>

                              <div
                                className="details-filter-row product-cat "
                                style={{
                                  width: '100%',
                                }}>
                                <label htmlFor="size" style={{ width: 140 }}>
                                  <Translate id="Shoulder" />:
                                </label>
                                <label
                                  style={{
                                    width: '50%',
                                  }}>
                                  {shoulder}
                                </label>
                              </div>

                              <div
                                className="details-filter-row product-cat "
                                style={{
                                  width: '100%',
                                }}>
                                <label htmlFor="size" style={{ width: 140 }}>
                                  <Translate id="High" />:
                                </label>
                                <label
                                  style={{
                                    width: '50%',
                                  }}>
                                  {high}
                                </label>
                              </div>

                              <div
                                className="details-filter-row product-cat "
                                style={{
                                  width: '100%',
                                }}>
                                <label htmlFor="size" style={{ width: 140 }}>
                                  <Translate id="Hip" />:
                                </label>
                                <label
                                  style={{
                                    width: '50%',
                                  }}>
                                  {hip}
                                </label>
                              </div>

                              <div
                                className="details-filter-row product-cat "
                                style={{
                                  width: '100%',
                                }}>
                                <label htmlFor="size" style={{ width: 140 }}>
                                  <Translate id="Length" />:
                                </label>
                                <label
                                  style={{
                                    width: '50%',
                                  }}>
                                  {length}
                                </label>
                              </div>

                              <div
                                className="details-filter-row product-cat "
                                style={{
                                  width: '100%',
                                }}>
                                <label htmlFor="size" style={{ width: 140 }}>
                                  <Translate id="Waist" />:
                                </label>
                                <label
                                  style={{
                                    width: '50%',
                                  }}>
                                  {waist}
                                </label>
                              </div>

                              <div
                                className="details-filter-row product-cat "
                                style={{
                                  width: '100%',
                                }}>
                                <label htmlFor="size" style={{ width: 140 }}>
                                  <Translate id="Additions" />:
                                </label>
                                <label
                                  style={{
                                    width: '50%',
                                  }}>
                                  {sleeve && <Translate id="sleeve" />}{' '}
                                  {underlay && <Translate id="underlay" />}
                                </label>
                              </div>

                              {origin && (
                                <div
                                  className="details-filter-row product-cat "
                                  style={{
                                    width: '100%',
                                  }}>
                                  <label htmlFor="size" style={{ width: 140 }}>
                                    <Translate id="ordernotes" />:
                                  </label>
                                  <label
                                    style={{
                                      width: '50%',
                                    }}>
                                    {origin}
                                  </label>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {userId === id ? (
                <div />
              ) : (
                product?.youMayAlsoLike?.length > 0 && (
                  <Container
                    className="pt-3 all-product"
                    style={{ marginTop: 0 }}>
                    <div className="heading heading-center mb-3">
                      <h2 className="title text-center mb-4">
                        <Translate id="You May Also Like" />
                      </h2>
                    </div>

                    <div className="tab-content">
                      <div className="tab-pane p-0 fade active in">
                        <div className="products">
                          <div className="row">
                            {product.youMayAlsoLike.map(
                              (mayProduct, index1) => (
                                <Col md={4} lg={3} xs={6} key={index1}>
                                  <ProductCard
                                    product={mayProduct}
                                    DomLink={DomLink}
                                  />
                                </Col>
                              ),
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Container>
                )
              )}
            </div>
          </div>
        </main>
      </>
    );
  },
);
