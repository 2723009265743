import gql from 'graphql-tag';

export const PRODUCT_GQL = `
  id
  name
  description
  price
  oldPrice
  type
  color
  selectedColor
  origin
  chest
  waist
  hip
  shoulder
  high
  length
  sleeve
  underlay
  qty
  approve
  visits
  totalTime
  ref
  status
  size {
    label
    value
  }
  shape {
    label
    value
  }
  country {
    label
    value
  }
  onHold
  sold
  category {
    id
    name {
      ar
      en
    }
  }
  priceCurrency {
    jod
    usd
    egp
    sar
  }
  oldPriceCurrency {
    jod
    usd
    egp
    sar
  }
  pictures {
    id
    path
  }
  user {
    id
  }
`;

export const ALLPRODUCTS = gql`
  query products($page: Float) {
    products(page: $page) {
      ${PRODUCT_GQL}
    }
  }
`;
