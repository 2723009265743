import { ApolloClient } from 'apollo-client';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';

import { GQLURI } from '../Configs';
import { store } from '../Store';
import { LOGOUT } from '../Action';

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) => {
          if (message === 'tokenInvalid') {
            store.dispatch({ type: LOGOUT });
          }
          // eslint-disable-next-line
          return console.log(
            `Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
        });
      if (networkError) console.log(`[Network error]: ${networkError}`); // eslint-disable-line
    }),
    setContext(
      () =>
        new Promise(resolve => {
          resolve({
            headers: {
              authorization: `bearer ${
                localStorage.getItem('token')
                  ? localStorage.getItem('token')
                  : ''
              }`,
            },
          });
        }),
    ),
    new HttpLink({
      uri: GQLURI,
      credentials: 'same-origin',
    }),
  ]),
  cache: new InMemoryCache(),
});

export default client;
