import React, { useState } from 'react';
import { Collapse } from 'reactstrap';

export default ({ title, children }) => {
  const [collapse, setState] = useState(false);

  const toggle = () => {
    setState(!collapse);
  };

  return (
    <div className="card card-box card-sm bg-light">
      <div className="card-header">
        <h2 className={collapse ? 'collapsed card-title' : 'card-title'}>
          <div className={collapse ? '' : 'collapsed'} onClick={toggle}>
            {title}
          </div>
        </h2>
      </div>
      <div className={collapse ? 'collapsed' : 'collapse in'}>
        <Collapse isOpen={collapse} className="card-body">
          <div className="card-body">{children}</div>
        </Collapse>
      </div>
    </div>
  );
};
