import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import $ from 'jquery';
import PhoneInput from 'react-phone-input-2';
import ar from 'react-phone-input-2/lang/ar.json';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Translate } from 'react-localize-redux';

import coverImage from '../../../Styles/images/page-header-bg.jpg';
import LoadingScreen from '../../../Components/LoadingScreen';

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Password is required'),
  lastName: Yup.string().required(),
  country: Yup.string().required(),
  city: Yup.string().required('testtt'),
  streetAddress: Yup.string().required(),
  appartment: Yup.string().required(),
  mobile: Yup.string().required(),
  email: Yup.string().required(),
});

const cites = [
  { value: 'Amman', label: 'Amman' },
  { value: 'Irbid', label: 'Irbid' },
  { value: 'Jarash', label: 'Jarash' },
];

export default ({
  cart,
  account: { language, user, currency },
  createOrder,
  claerCart,
  history,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    $('#checkout-discount-input')
      .on('focus', function() {
        // Hide label on focus
        $(this)
          .parent('form')
          .find('label')
          .css('opacity', 0);
      })
      .on('blur', function() {
        // Check if input is empty / toggle label
        var $this = $(this);

        if ($this.val().length !== 0) {
          $this
            .parent('form')
            .find('label')
            .css('opacity', 0);
        } else {
          $this
            .parent('form')
            .find('label')
            .css('opacity', 1);
        }
      });
  });
  const formik = useFormik({
    initialValues: {
      firstName: user.account.firstName,
      lastName: user.account.lastName,
      country: 'Jordan',
      city: '',
      streetAddress: '',
      appartment: '',
      mobile: user.account.mobile,
      email: user.account.email,
      notes: '',
    },
    onSubmit: () => handleSubmits(),
    validationSchema: ValidationSchema,
  });

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = formik;
  const {
    firstName,
    lastName,
    city,
    streetAddress,
    appartment,
    email,
    notes,
  } = values;

  const handleSubmits = async () => {
    const items = [];
    if (cart.cartItems.length > 0) {
      cart.cartItems.forEach(item => {
        items.push({
          product: item.product.id,
          qty: item.quantity,
          total: item.product.priceCurrency[currency.value] * item.quantity,
          user: item.product.user.id,
        });
      });

      setLoading(true);

      await createOrder({
        variables: {
          order: {
            user: user.id,
            total: totalCartPrice(),
            items,
            notes,
            address: {
              city,
              appartment,
              street: streetAddress,
            },
          },
        },
      })
        .then(() => {
          setLoading(false);
          claerCart(user.id);
          history.push({
            pathname: '/thankyou',
            state: {
              orderTotal: totalCartPrice(),
              address: {
                city,
                appartment,
                street: streetAddress,
              },
            },
          });
        })
        .catch(() => {
          setLoading(false);
          toast.error(<Translate id="somethingWentWrong" />);
        });
    } else {
      toast.error('The cart is empty');
    }
  };

  const totalUser = () => {
    let plus = 0;

    cart.cartItems.forEach((items, index) => {
      if (
        items.product.user.id !==
        (cart.cartItems[index + 1] && cart.cartItems[index + 1].product.user.id)
      ) {
        ++plus;
      }
    });
    return plus;
  };

  const deliveryCost =
    currency.value === 'jod'
      ? 5
      : currency.value === 'sar'
      ? 30
      : currency.value === 'egp'
      ? 110
      : 7;
  const totalCartPrice = () => cart.totalPrice + deliveryCost * totalUser();

  const handleCoupon = e => {
    e.preventDefault();
  };

  return (
    <Translate>
      {({ translate }) => (
        <main className="main checkout-container">
          {loading && <LoadingScreen />}
          <div
            className="page-header text-center"
            style={{ backgroundImage: `url(${coverImage})` }}>
            <div className="container">
              <h1 className="page-title">
                <Translate id="checkout" />
                <span>
                  <Translate id="shop" />
                </span>
              </h1>
            </div>
          </div>
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <Translate id="home" />
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <Translate id="checkout" />
                </li>
              </ol>
            </div>
          </nav>

          <div className="page-content">
            <div className="checkout">
              <div className="container">
                <div className="checkout-discount">
                  <form onSubmit={handleCoupon}>
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="checkout-discount-input"
                    />
                    <label
                      htmlFor="checkout-discount-input"
                      className="text-truncate">
                      <Translate id="Have a coupon" />{' '}
                      <span>
                        <Translate id="Click here to enter your code" />
                      </span>
                    </label>
                  </form>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-9">
                      <h2 className="checkout-title">
                        <Translate id="billingdetails" />*
                      </h2>
                      <div className="row">
                        <div className="col-sm-6">
                          <label>
                            <Translate id="firstname" />*
                          </label>
                          <input
                            type="text"
                            className={'form-control'}
                            required
                            name="firstName"
                            value={firstName}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-sm-6">
                          <label>
                            <Translate id="lastname" />*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="lastName"
                            value={lastName}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <label>
                            <Translate id="email" />*
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            required
                            name="email"
                            onChange={handleChange}
                            value={email}
                          />
                        </div>

                        <div className="col-sm-6">
                          <label>
                            <Translate id="mobile" />*
                          </label>
                          <PhoneInput
                            onlyCountries={['jo']}
                            className="form-control"
                            country="jo"
                            onChange={(e, country) =>
                              e.length < 15 &&
                              setFieldValue('mobile', e) &&
                              setFieldValue('country', country.countryCode)
                            }
                            localization={language === 'ar' && ar}
                            value={values.mobile}
                            required
                          />
                        </div>
                      </div>

                      <label>
                        <Translate id="country" />*
                      </label>
                      <input
                        className="form-control"
                        value={'Jordan'}
                        disabled
                      />

                      <div className="row">
                        <div className="col-sm-12">
                          <div className={'form-group'}>
                            <label>
                              <Translate id="city" />*
                            </label>
                            <Select
                              className={
                                errors.city && touched.city ? 'error' : ''
                              }
                              placeholder={<Translate id="selectcity" />}
                              options={cites}
                              required
                              onChange={e => setFieldValue('city', e.value)}
                            />
                            {errors.city && touched.city && (
                              <label style={{ color: 'red' }}>
                                <Translate id="city req" />
                              </label>
                            )}
                          </div>
                        </div>
                      </div>

                      <label>
                        <Translate id="street" />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={translate('selectstreet')}
                        required
                        name="streetAddress"
                        value={streetAddress}
                        onChange={handleChange}
                      />
                      <input
                        type="text"
                        className="form-control"
                        placeholder={translate('Appartments, suite')}
                        required
                        name="appartment"
                        value={appartment}
                        onChange={handleChange}
                      />

                      <label>
                        <Translate id="ordernotes" /> (
                        <Translate id="Optional" />)
                      </label>
                      <textarea
                        className="form-control"
                        cols="30"
                        rows="4"
                        placeholder={translate('Notes about your order')}
                        value={notes}
                        onChange={handleChange}
                      />
                    </div>
                    <aside className="col-lg-3">
                      <div className="summary">
                        <h3 className="summary-title">
                          <Translate id="yourorder" />
                        </h3>

                        <table className="table table-summary">
                          <thead>
                            <tr>
                              <th>
                                <Translate id="product" />
                              </th>
                              <th>
                                <Translate id="total" />
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {cart.cartItems.map((items, index) => (
                              <tr key={index}>
                                <td>
                                  <Link
                                    to={{
                                      pathname: `/product/${items.product.id}`,
                                      state: { id: items.product.id },
                                    }}>
                                    {`${items?.product?.name}`}
                                  </Link>
                                </td>
                                <td>
                                  {Intl.NumberFormat(`${language}-JD`, {
                                    style: 'currency',
                                    currency: currency.value,
                                  })
                                    .format(
                                      items.product.priceCurrency[
                                        currency.value
                                      ] * items.quantity,
                                    )
                                    .replace('.000', '')}
                                </td>
                              </tr>
                            ))}

                            <tr className="summary-subtotal">
                              <td>
                                <Translate id="subtotal" />:
                              </td>
                              <td>
                                {Intl.NumberFormat(`${language}-JD`, {
                                  style: 'currency',
                                  currency: currency.value,
                                })
                                  .format(cart.totalPrice)
                                  .replace('.000', '')}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Translate id="shipping" />:
                              </td>
                              <td>
                                {Intl.NumberFormat(`${language}-JD`, {
                                  style: 'currency',
                                  currency: currency.value,
                                })
                                  .format(totalUser() * deliveryCost)
                                  .replace('.000', '')}
                              </td>
                            </tr>
                            <tr className="summary-total">
                              <td>
                                <Translate id="total" />:
                              </td>
                              <td>
                                {Intl.NumberFormat(`${language}-JD`, {
                                  style: 'currency',
                                  currency: currency.value,
                                })
                                  .format(totalCartPrice())
                                  .replace('.000', '')}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div
                          className="accordion-summary"
                          id="accordion-payment">
                          <div className="card">
                            <div className="card-header" id="heading-3">
                              <h2 className="card-title">
                                <a role="button" href="#collapse-3">
                                  <Translate id="cod" />
                                </a>
                              </h2>
                            </div>
                          </div>
                        </div>

                        {cart.cartItems.length > 0 && (
                          <button
                            type="submit"
                            className="btn btn-outline-primary-2 btn-order btn-block">
                            <span className="btn-text">
                              <Translate id="placeorder" />
                            </span>
                            <span className="btn-hover-text">
                              <Translate id="PROCEED TO CHECKOUT" />
                            </span>
                          </button>
                        )}
                      </div>
                    </aside>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      )}
    </Translate>
  );
};
