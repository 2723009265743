import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useMutation } from 'react-apollo';
import { Translate } from 'react-localize-redux';

import BG from '../../../Styles/images/backgrounds/login-bg.jpg';
import { FORGET_PASSWORD } from '../ForgetPassoword-gql';

const ValidationSchema = Yup.object().shape({
  email: Yup.string().required('email'),
});

export default () => {
  const [forgetPasswordEmail] = useMutation(FORGET_PASSWORD);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async ({ email }) => {
      await forgetPasswordEmail({
        variables: {
          email,
        },
      })
        .then(data => {
          if (data) {
            toast.success(<Translate id="EmilSent" />, {
              onClose: () => (window.location.href = '/'),
            });
          }
        })
        .catch(error => {
          if (error) {
            toast.error(<Translate id="somethingWentWrong" />);
          }
        });
    },
    validationSchema: ValidationSchema,
  });

  const { errors, touched, values, handleChange, handleSubmit } = formik;

  return (
    <div className="page-wrapper login">
      <main className="main">
        <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">
                  <Translate id="home" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <Translate id="Forget Password" />
              </li>
            </ol>
          </div>
        </nav>

        <div
          className="login-page bg-image pt-8 pb-8 pt-md-12 pb-md-12 pt-lg-17 pb-lg-17"
          style={{ backgroundImage: `url(${BG})` }}>
          <div className="container">
            <div className="form-box">
              <div className="form-tab">
                <ul className="nav nav-pills nav-fill" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="signin-tab-2"
                      data-toggle="tab"
                      href="#signin-2"
                      role="tab"
                      aria-controls="signin-2"
                      aria-selected="false">
                      <Translate id="Forget Password" />
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    className="tab-pane  fade show active in"
                    id="signin-2"
                    role="tabpanel"
                    aria-labelledby="signin-tab-2">
                    <form onSubmit={handleSubmit}>
                      <div
                        className={
                          errors.email && touched.email
                            ? 'form-group error'
                            : 'form-group'
                        }>
                        <label htmlFor="email">
                          <Translate id="Email address or phone" /> *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          name="email"
                          onChange={handleChange}
                          value={values.email}
                          required
                        />
                      </div>

                      <div
                        className="form-footer"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}>
                        <button
                          type="submit"
                          className="btn btn-outline-primary-2">
                          <span>
                            <Translate id="SEND" />
                          </span>
                          <i className="icon-long-arrow-right"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
