import React from 'react';
import Container from '../../Components/Container';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { useMutation } from 'react-apollo';

import BG from '../../Styles/images/backgrounds/login-bg.jpg';
import { toast } from 'react-toastify';
import { CONFIRM_PASSWORD } from './ForgetPassoword-gql';
import { changeLanguage, loginUser } from '../../Action';
import { Translate } from 'react-localize-redux';

const ValidationSchema = Yup.object().shape({
  password: Yup.string().required('password'),
  confirmationPassword: Yup.string().required('confirmPassword'),
});

const mapDispatchToProps = {
  loginDispatch: loginUser,
  changeLang: changeLanguage,
};

export default connect(
  null,
  mapDispatchToProps,
)(({ match: { params }, loginDispatch, changeLang }) => {
  const [confirmPassword] = useMutation(CONFIRM_PASSWORD);
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmationPassword: '',
    },
    onSubmit: async ({ password }) =>
      confirmPassword({
        variables: {
          token: params.token,
          newPassword: password,
        },
      })
        .then(async ({ data: { confirmToken } }) => {
          if (confirmToken) {
            loginDispatch(confirmToken);
            changeLang(loginUser.account.lang);
            await localStorage.setItem('token', confirmToken.account.token);
            toast.success(<Translate id="PasswordChanged" />, {
              onClose: () => (window.location.href = '/'),
            });
          }
        })
        .catch(error =>
          error.graphQLErrors.length > 0
            ? toast.error(error.graphQLErrors[0].message, {
                onClose: () => (window.location.href = '/forget-password'),
              })
            : toast.error(<Translate id="somethingWentWrong" />),
        ),
    validationSchema: ValidationSchema,
  });

  const { errors, touched, values, handleChange, handleSubmit } = formik;

  return (
    <Container>
      <div className="page-wrapper login">
        <main className="main">
          <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <Translate id="home" />
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <Translate id="Confirm Password" />
                </li>
              </ol>
            </div>
          </nav>

          <div
            className="login-page bg-image pt-8 pb-8 pt-md-12 pb-md-12 pt-lg-17 pb-lg-17"
            style={{ backgroundImage: `url(${BG})` }}>
            <div className="container">
              <div className="form-box">
                <div className="form-tab">
                  <ul className="nav nav-pills nav-fill" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="signin-tab-2"
                        data-toggle="tab"
                        href="#signin-2"
                        role="tab"
                        aria-controls="signin-2"
                        aria-selected="false">
                        <Translate id="Confirm Password" />
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className="tab-pane  fade show active in"
                      id="signin-2"
                      role="tabpanel"
                      aria-labelledby="signin-tab-2">
                      <form onSubmit={handleSubmit}>
                        <div
                          className={
                            errors.password && touched.password
                              ? 'form-group error'
                              : 'form-group'
                          }>
                          <label htmlFor="password">
                            <Translate id="New Password" />
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            onChange={handleChange}
                            value={values.password}
                            required
                          />
                        </div>

                        <div
                          className={
                            errors.confirmationPassword &&
                            touched.confirmationPassword
                              ? 'form-group error'
                              : 'form-group'
                          }>
                          <label htmlFor="confirmationPassword">
                            <Translate id="Confirm Password" />
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="confirmationPassword"
                            name="confirmationPassword"
                            onChange={handleChange}
                            value={values.confirmationPassword}
                            required
                          />
                        </div>

                        <div
                          className="form-footer"
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}>
                          <button
                            type="submit"
                            className="btn btn-outline-primary-2">
                            <span>
                              <Translate id="Confirm" />
                            </span>
                            <i className="icon-long-arrow-right"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </Container>
  );
});
