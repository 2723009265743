import gql from 'graphql-tag';
import { PRODUCT_GQL } from '../AllProducts/AllProducts-gql';

export const PRODUCTBYID = gql`
  query productById($id: ID) {
    productById(id: $id) {
      ${PRODUCT_GQL}
      youMayAlsoLike {
        ${PRODUCT_GQL}
      }
    }
  }
`;
