import React from 'react';
import { connect } from 'react-redux';

import Wishlist from './Components/WishlistDetials';
import { removeWishListItem, addCartItem } from '../../Action';
import Container from '../../Components/Container';

const mapStateToProps = state => ({
  wishlist: state.Wishlist,
  account: state.Account,
});

const mapDispatchToProps = {
  removeWishList: removeWishListItem,
  addToCart: addCartItem,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(({ wishlist, removeWishList, addToCart, account }) => {
  return (
    <Container>
      <Wishlist
        wishlist={wishlist}
        removeWishList={removeWishList}
        addToCart={addToCart}
        {...account}
      />
    </Container>
  );
});
