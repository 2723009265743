import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { Translate } from 'react-localize-redux';
import { PUBLICURI } from '../../../Configs';

import coverImage from '../../../Styles/images/page-header-bg.jpg';
import { SEARCH } from '../Search-gql';

export default ({ DomLink, language, currency }) => {
  const [searchProduct] = useMutation(SEARCH);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [word, setWord] = useState('');
  const search = () => {
    setLoading(true);
    if (word) {
      searchProduct({
        variables: {
          word,
        },
      })
        .then(({ data }) => {
          if (data) {
            setData(data.searchProduct);
          }
        })
        .catch(error => {
          if (error) {
            setData([]);
          }
        });
      setLoading(false);
    } else {
      setData('');
    }
  };

  return (
    <div className="notifications-page">
      <div
        className="page-header text-center"
        style={{ backgroundImage: `url(${coverImage})` }}>
        <div className="container">
          <h1 className="page-title">
            <Translate id="Search" />
            <span>
              <Translate id="shop" />
            </span>
          </h1>
        </div>
      </div>
      <form
        className="row"
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onSubmit={e => {
          e.preventDefault();
          search();
        }}>
        <div className="col-sm-6">
          <label htmlFor="price">
            <Translate id="Search" />
          </label>
          <input
            type="search"
            id="site-search"
            name="q"
            aria-label="Search through site content"
            className="form-control"
            onChange={e => setWord(e.target.value)}
            required
          />
        </div>
        <button
          onSubmit={search}
          type="submit"
          className="btn btn-outline-primary-2"
          style={{ marginTop: '15px' }}>
          <span>
            <Translate id="SAVE CHANGES" />
          </span>
          {loading ? (
            <i className="fa fa-spinner fa-pulse" />
          ) : (
            <i className="icon-long-arrow-right"></i>
          )}
        </button>
      </form>
      {data.length > 0 ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <div className="col-lg-6">
              <table className="table table-cart table-mobile">
                <thead>
                  <tr>
                    <th>
                      <Translate id="product" />
                    </th>
                    <th>
                      <Translate id="price" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((items, index) => (
                    <tr key={index}>
                      <td className="product-col">
                        <div className="product">
                          <figure className="product-media">
                            <DomLink
                              to={{
                                pathname: `/produc/${items.id}`,
                                state: { id: items.id },
                              }}>
                              <img
                                src={`${PUBLICURI}${items.pictures[0].path}`}
                                alt="Product"
                              />
                            </DomLink>
                          </figure>

                          <h3 className="product-title">
                            <DomLink
                              to={{
                                pathname: `/product/${items.id}`,
                                state: { id: items.id },
                              }}>
                              {items.name}
                            </DomLink>
                          </h3>
                        </div>
                      </td>
                      <td className="price-col">
                        <div className="product-price">
                          {Intl.NumberFormat(`${language}-JD`, {
                            style: 'currency',
                            currency: currency.value,
                          })
                            .format(items.priceCurrency[currency.value])
                            .replace('.000', '')}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',
              marginTop: 20,
            }}>
            <div className="empty-result-img"></div>
            <p>
              <Translate id="No result" />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
