import React from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { connect } from 'react-redux';

import ProfileDetails from './Components/ProfileDetails';
import { DASHBOARD, LOGOUT, DELETE_PRODUCT } from './Profile-gql';
import { logoutUser } from '../../Action';
import Container from '../../Components/Container';
import Loading from '../../Components/Loading';
import ErrorPage from '../../Components/ErrorPage';

const mapStateToProps = state => ({
  account: state.Account,
});

const mapDispatchToProps = {
  logoutUsers: logoutUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(({ account: { user, language, currency }, logoutUsers }) => {
  const { data, loading, error } = useQuery(DASHBOARD, {
    variables: {
      id: user.id,
    },
  });

  const [deleteProduct] = useMutation(DELETE_PRODUCT);
  const [logoutAccount] = useMutation(LOGOUT);

  const logout = () => {
    logoutAccount({
      variables: {
        id: user.id,
      },
    });
    logoutUsers();
    setTimeout(() => {
      window.location.href = '/';
    }, 10);
  };

  return error ? (
    <ErrorPage withHeader />
  ) : loading ? (
    <Loading withHeader />
  ) : (
    <Container>
      <ProfileDetails
        user={user}
        dashboard={data?.userDashboard}
        logout={logout}
        deleteProduct={deleteProduct}
        language={language}
        currency={currency}
      />
    </Container>
  );
});
