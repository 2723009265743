import React from 'react';

import UserCard from '../../../Components/UserCard';

import { Translate } from 'react-localize-redux';

export default ({ products, deleteProduct }) =>
  products?.length > 0 ? (
    <>
      <div
        style={{
          marginBottom: 10,
          paddingLeft: '10px',
          paddingRight: '10px',
        }}>
        <a href="/new-product" className="btn btn-primary">
          <span>
            <Translate id="CREATE PRODUCT" />
          </span>
          <i className="icon-long-arrow-right"></i>
        </a>
      </div>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
        }}>
        {products.map((product, index) => (
          <UserCard
            product={product}
            key={index}
            deleteProduct={deleteProduct}
          />
        ))}
      </div>
    </>
  ) : (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
      }}>
      <div
        style={{
          marginBottom: 10,
          paddingLeft: '10px',
          paddingRight: '10px',
        }}>
        <a href="/new-product" className="btn btn-primary">
          <span>
            <Translate id="AddNewDress" />
          </span>
        </a>
      </div>
      <div className="empty-trending-img"></div>
      <p>
        <Translate id="No products yet." />
      </p>
    </div>
  );
