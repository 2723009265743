import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import AllProductsDetails from './Components/AllProductsDetails';
import Filter from './Components/Filter';
import { useQuery } from 'react-apollo';
import { ALLPRODUCTS } from './AllProducts-gql';
import Container from '../../Components/Container';
import Loading from '../../Components/Loading';
import ErrorPage from '../../Components/ErrorPage';

export default React.memo(() => {
  const { data, error, loading } = useQuery(ALLPRODUCTS, {
    page: 100,
  });

  const [openFilter, setFilter] = useState(false);

  return error ? (
    <ErrorPage withHeader />
  ) : loading ? (
    <Loading withHeader />
  ) : (
    <Container>
      <AllProductsDetails
        setFilter={setFilter}
        products={data.products}
        DomLink={Link}
      />
      <Filter openFilter={openFilter} setFilter={setFilter} />
    </Container>
  );
});
