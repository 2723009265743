import gql from 'graphql-tag';
import { USER_GQL } from '../../Navigation/Router-gql';

export const FORGET_PASSWORD = gql`
  mutation forgetPassword($email: String) {
    forgetPassword(email: $email)
  }
`;

export const CONFIRM_PASSWORD = gql`
  mutation confirmToken($token: String!, $newPassword: String!) {
    confirmToken(token: $token, newPassword: $newPassword) {
      ${USER_GQL}
    }
  }
`;
