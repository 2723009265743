import {
  ADD_CART_ITEM,
  REMOVE_CART_ITEM,
  DELETE_CART_ITEM,
  EMPTY_CART,
  QUERY_CART_ITEM,
} from '../Action';
import Axios from 'axios';
import { createCart, removeCart, deleteCart, clearCart } from '../Configs';

const initialState = {
  cartItems: [],
  total: 0,
  totalPrice: 0,
  myOrders: [],
  isFetching: false,
};

export default (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case QUERY_CART_ITEM: {
      let addOrder;
      if (action.payload.length > 0) {
        let total = 0;
        let totalPrice = 0;
        let cartItem = [];
        action.payload.forEach(item => {
          total += item.quantity;
          totalPrice += getPrice(item) * item.quantity;
          cartItem.push(item);
        });
        addOrder = Object.assign(
          {},
          state,
          { cartItems: cartItem },
          {
            total,
            totalPrice,
          },
        );
      } else {
        if (state.cartItems.length > 0) {
          addOrder = Object.assign(
            {},
            state,
            { cartItems: state.cartItems },
            {
              total: state.total,
              totalPrice: state.totalPrice,
              timer: state.timer,
            },
          );
        } else {
          addOrder = Object.assign(
            {},
            state,
            { cartItems: [] },
            {
              total: 0,
              totalPrice: 0,
            },
          );
        }
      }
      return addOrder;
    }
    case ADD_CART_ITEM: {
      console.log(action);
      Axios.post(createCart, {
        cart: {
          product: action.product.id,
          user: action.userId,
        },
      });
      const isExisted = state.cartItems.some(cartItem =>
        compareCartItem(cartItem, action),
      );

      var dt = new Date();
      dt.setMinutes(dt.getMinutes() + 30);

      const addOrder = Object.assign(
        {},
        state,
        isExisted
          ? { cartItems: state.cartItems.map(item => cartItem(item, action)) }
          : { cartItems: [...state.cartItems, cartItem(undefined, action)] },
        {
          total: state.total + 1,
          totalPrice: state.totalPrice + getPrice(action),
          timer: dt,
        },
      );
      return addOrder;
    }
    case REMOVE_CART_ITEM: {
      Axios.post(removeCart, {
        cart: {
          product: action.product.id,
          user: action.userId,
        },
      });
      const index = state.cartItems.findIndex(cartItem =>
        compareCartItem(cartItem, action),
      ); // check if existed
      return index === -1
        ? state // This should not happen, but catch anyway
        : Object.assign(
            {},
            state,
            state.cartItems[index].quantity === 1
              ? {
                  cartItems: state.cartItems.filter(
                    cartItem => !compareCartItem(cartItem, action),
                  ),
                }
              : {
                  cartItems: state.cartItems.map(item =>
                    cartItem(item, action),
                  ),
                },
            {
              total: state.total - 1,
              totalPrice: state.totalPrice - getPrice(action),
            },
          );
    }
    case DELETE_CART_ITEM: {
      Axios.post(deleteCart, {
        cart: {
          product: action.product.id,
          user: action.userId,
        },
      });
      const index1 = state.cartItems.findIndex(cartItem =>
        compareCartItem(cartItem, action),
      ); // check if existed
      return index1 === -1
        ? state // This should not happen, but catch anyway
        : Object.assign({}, state, {
            cartItems: state.cartItems.filter(
              cartItem => !compareCartItem(cartItem, action),
            ),
            total: state.total - Number(action.quantity),
            totalPrice:
              state.totalPrice - Number(action.quantity) * getPrice(action),
          });
    }
    case EMPTY_CART:
      Axios.post(clearCart, {
        cart: {
          user: action.userId,
        },
      });
      return Object.assign({}, state, {
        type: EMPTY_CART,
        cartItems: [],
        total: 0,
        totalPrice: 0,
      });
    default:
      return state;
  }
};

const compareCartItem = (cartItem, action) =>
  cartItem.product.id === action.product.id;

const cartItem = (
  state = { product: undefined, quantity: 1, variation: undefined },
  action,
) => {
  switch (action.type) {
    case ADD_CART_ITEM:
      return state.product === undefined
        ? Object.assign({}, state, {
            product: action.product,
            variation: action.variation,
          })
        : !compareCartItem(state, action)
        ? state
        : Object.assign({}, state, {
            quantity: state.quantity < 10 ? state.quantity + 1 : state.quantity,
          });
    case REMOVE_CART_ITEM:
      return !compareCartItem(state, action)
        ? state
        : Object.assign({}, state, { quantity: state.quantity - 1 });
    default:
      return state;
  }
};

// get price from variation or product and format
const getPrice = action =>
  Number(action.product.priceCurrency[action.product.currency.value]);
