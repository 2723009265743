import React, { useState } from 'react';
import { DisappearedLoading } from 'react-loadingg';

import Header from './Header/Header';
import MobileMenu from './Header/MobileMenu';

export default ({ withHeader }) => {
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <>
      <div className={`page-wrapper ${openMenu ? 'mmenu-active' : ''}`}>
        {withHeader && (
          <Header invertColor setOpenMenu={setOpenMenu} openMenu={openMenu} />
        )}
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
          }}>
          <DisappearedLoading color={'#c96'} />
        </div>
      </div>
      {withHeader && (
        <MobileMenu setOpenMenu={setOpenMenu} openMenu={openMenu} />
      )}
    </>
  );
};
