import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { connect } from 'react-redux';

import Container from '../../Components/Container';
import EditProductDetails from './Components/EditProductDetails';
import { PRODUCT_BY_ID, UPDATE_PRODUCT } from './EditProduct-gql';
import Loading from '../../Components/Loading';
import ErrorPage from '../../Components/ErrorPage';

const mapStateToProps = state => ({
  account: state.Account,
  configs: state.Configs,
});

export default connect(mapStateToProps)(
  ({
    account: { user, language, currency },
    configs: { category },
    match: { params },
  }) => {
    const [updateProduct] = useMutation(UPDATE_PRODUCT);

    const { data, loading, error } = useQuery(PRODUCT_BY_ID, {
      variables: {
        id: params.id,
      },
    });

    const categoryOptions = category.map(category => ({
      value: category.id,
      label: category.name[language],
    }));

    return error ? (
      <ErrorPage withHeader />
    ) : loading ? (
      <Loading withHeader />
    ) : (
      <Container>
        <EditProductDetails
          updateProduct={updateProduct}
          data={data.productById}
          user={user}
          categoryOptions={categoryOptions}
          language={language}
          id={params.id}
          currency={currency}
        />
      </Container>
    );
  },
);
