import React from 'react';
import Container from '../../Components/Container';
import ForgetPasswordDetials from './Components/ForgetPasswordDetials';

export default () => {
  return (
    <Container>
      <ForgetPasswordDetials />
    </Container>
  );
};
