export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';
export const LANGUAGE = 'LANGUAGE';
export const ADD_CART_ITEM = 'ADD_CART_ITEM';
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export const DELETE_CART_ITEM = 'DELETE_CART_ITEM';
export const EMPTY_CART = 'EMPTY_CART';
export const ADD_WISHLIST_ITEM = 'ADD_WISHLIST_ITEM';
export const REMOVE_WISHLIST_ITEM = 'REMOVE_WISHLIST_ITEM';
export const EMPTY_WISHLIST = 'EMPTY_WISHLIST';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const QUERY_CART_ITEM = 'QUERY_CART_ITEM';
export const CHANGE_CURRENCY = 'CHANGE_CURRENCY';
export const SIGNUP_DATA = 'SIGNUP_DATA';
export const SIGNUP_CLEAR = 'SIGNUP_CLEAR';
export const ADD_LENGTH = 'ADD_LENGTH';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';

export const loginUser = (user = {}) => ({
  type: LOGIN,
  payload: { user },
});

export const logoutUser = () => ({
  type: LOGOUT,
});

export const changeLanguage = (lang = '') => ({
  type: LANGUAGE,
  payload: { lang },
});

export const addCartItem = (product, userId) => ({
  type: ADD_CART_ITEM,
  product,
  userId,
});

export const removeCartItem = (product, userId) => ({
  type: REMOVE_CART_ITEM,
  product,
  userId,
});

export const deleteCartItem = (product, variation, quantity) => ({
  type: DELETE_CART_ITEM,
  product,
  variation,
  quantity,
});

export const emptyCart = userId => ({
  type: EMPTY_CART,
  userId,
});

export const addWishListItem = product => ({
  type: ADD_WISHLIST_ITEM,
  product,
});

export const removeWishListItem = product => ({
  type: REMOVE_WISHLIST_ITEM,
  product,
});

export const emptyWishList = () => ({
  type: EMPTY_WISHLIST,
});

export const addCategory = category => ({
  type: ADD_CATEGORY,
  payload: category,
});

export const queryCartItems = items => ({
  type: QUERY_CART_ITEM,
  payload: items,
});

export const changeCurrency = currency => ({
  type: CHANGE_CURRENCY,
  payload: currency,
});

export const signupData = data => ({
  type: SIGNUP_DATA,
  payload: data,
});

export const signupClear = () => ({
  type: SIGNUP_CLEAR,
});

export const productLength = length => ({
  type: ADD_LENGTH,
  payload: length,
});

export const getNotifications = (data, number) => ({
  type: GET_NOTIFICATIONS,
  payload: { data: data, number: number },
});
