import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring/web.cjs';

import SizeGuide from '../../../Styles/images/SizeGuide.png';

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export default ({ open, handleClose }) => (
  <Modal
    aria-labelledby="spring-modal-title"
    aria-describedby="spring-modal-description"
    open={open}
    className="modal cash-modal"
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}>
    <Fade in={open} className="fade-in">
      <div className="modal-dialog size-guid" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Size guide</h5>
            <button
              type="button"
              className="close"
              onClick={handleClose}
              aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="size-guide-continer">
              <div>
                <img src={SizeGuide} alt="size" />
              </div>
              <div
                style={{
                  height: '100vh',
                  width: '85vw',
                }}>
                <div
                  id="pop_size_simple_tab"
                  className=""
                  style={{ display: 'block' }}>
                  <table
                    className="size  lang-en"
                    cellSpacing="0"
                    cellPadding="0">
                    <caption>Dress Size Chart</caption>
                    <thead>
                      <tr>
                        <td width="14%" className="first">
                          US Size
                        </td>
                        <td colSpan="2">0</td>
                        <td colSpan="2">2</td>
                        <td colSpan="2">4</td>
                        <td colSpan="2">6</td>
                        <td colSpan="2">8</td>
                        <td colSpan="2">10</td>
                        <td colSpan="2">12</td>
                        <td colSpan="2">14</td>
                        <td colSpan="2">16</td>
                      </tr>
                      <tr>
                        <td width="14%" className="first">
                          UK Size
                        </td>
                        <td className="first" colSpan="2">
                          6
                        </td>
                        <td className="first" colSpan="2">
                          8
                        </td>
                        <td className="first" colSpan="2">
                          10
                        </td>
                        <td className="first" colSpan="2">
                          12
                        </td>
                        <td className="first" colSpan="2">
                          14
                        </td>
                        <td className="first" colSpan="2">
                          16
                        </td>
                        <td className="first" colSpan="2">
                          18
                        </td>
                        <td className="first" colSpan="2">
                          20
                        </td>
                        <td className="first" colSpan="2">
                          22
                        </td>
                      </tr>
                      <tr>
                        <td width="14%" className="first">
                          EU Size
                        </td>
                        <td colSpan="2">32</td>
                        <td colSpan="2">34</td>
                        <td colSpan="2">36</td>
                        <td colSpan="2">38</td>
                        <td colSpan="2">40</td>
                        <td colSpan="2">42</td>
                        <td colSpan="2">44</td>
                        <td colSpan="2">46</td>
                        <td colSpan="2">48</td>
                      </tr>
                      <tr>
                        <td width="14%" className="first"></td>
                        <td>inch</td>
                        <td className="first">cm</td>
                        <td>inch</td>
                        <td className="first">cm</td>
                        <td>inch</td>
                        <td className="first">cm</td>
                        <td>inch</td>
                        <td className="first">cm</td>
                        <td>inch</td>
                        <td className="first">cm</td>
                        <td>inch</td>
                        <td className="first">cm</td>
                        <td>inch</td>
                        <td className="first">cm</td>
                        <td>inch</td>
                        <td className="first">cm</td>
                        <td>inch</td>
                        <td className="first">cm</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td width="14%" className="first">
                          Bust
                        </td>
                        <td>31 ½</td>
                        <td className="first">80</td>
                        <td>32 ½</td>
                        <td className="first">83</td>
                        <td>33 ½</td>
                        <td className="first">85</td>
                        <td>34 ½</td>
                        <td className="first">88</td>
                        <td>35 ½</td>
                        <td className="first">90</td>
                        <td>36 ½</td>
                        <td className="first">93</td>
                        <td>38</td>
                        <td className="first">97</td>
                        <td>39 ½</td>
                        <td className="first">100</td>
                        <td>41</td>
                        <td className="first">104</td>
                      </tr>
                      <tr>
                        <td width="14%" className="first">
                          Waist
                        </td>
                        <td>24 ½</td>
                        <td className="first">62</td>
                        <td>25 ½</td>
                        <td className="first">65</td>
                        <td>26 ½</td>
                        <td className="first">67</td>
                        <td>27 ½</td>
                        <td className="first">70</td>
                        <td>28 ½</td>
                        <td className="first">72</td>
                        <td>29 ½</td>
                        <td className="first">75</td>
                        <td>31</td>
                        <td className="first">79</td>
                        <td>32 ½</td>
                        <td className="first">83</td>
                        <td>34</td>
                        <td className="first">86</td>
                      </tr>
                      <tr>
                        <td width="14%" className="first">
                          Hips
                        </td>
                        <td>34 ¾</td>
                        <td className="first">88</td>
                        <td>35 ¾</td>
                        <td className="first">91</td>
                        <td>36 ¾</td>
                        <td className="first">93</td>
                        <td>37 ¾</td>
                        <td className="first">96</td>
                        <td>38 ¾</td>
                        <td className="first">98</td>
                        <td>39 ¾</td>
                        <td className="first">101</td>
                        <td>41 ¼</td>
                        <td className="first">105</td>
                        <td>42 ¾</td>
                        <td className="first">109</td>
                        <td>44 ¼</td>
                        <td className="first">112</td>
                      </tr>
                      <tr>
                        <td width="14%" className="first">
                          Hollow to hem(front)
                        </td>
                        <td>57</td>
                        <td className="first">144</td>
                        <td>58</td>
                        <td className="first">147</td>
                        <td>58</td>
                        <td className="first">147</td>
                        <td>59</td>
                        <td className="first">150</td>
                        <td>59</td>
                        <td className="first">150</td>
                        <td>60</td>
                        <td className="first">152</td>
                        <td>60</td>
                        <td className="first">152</td>
                        <td>61</td>
                        <td className="first">155</td>
                        <td>61</td>
                        <td className="first">155</td>
                      </tr>
                    </tbody>
                  </table>

                  <table
                    className="size  lang-en"
                    cellSpacing="0"
                    cellPadding="0">
                    <caption>Plus Size Dress Size Chart</caption>
                    <thead>
                      <tr>
                        <td width="14%" className="first">
                          US Size
                        </td>
                        <td colSpan="2">16W</td>
                        <td colSpan="2">18W</td>
                        <td colSpan="2">20W</td>
                        <td colSpan="2">22W</td>
                        <td colSpan="2">24W</td>
                        <td colSpan="2">26W</td>
                      </tr>
                      <tr>
                        <td width="14%" className="first">
                          UK Size
                        </td>
                        <td className="first" colSpan="2">
                          22
                        </td>
                        <td className="first" colSpan="2">
                          24
                        </td>
                        <td className="first" colSpan="2">
                          26
                        </td>
                        <td className="first" colSpan="2">
                          28
                        </td>
                        <td className="first" colSpan="2">
                          30
                        </td>
                        <td className="first" colSpan="2">
                          32
                        </td>
                      </tr>
                      <tr>
                        <td width="14%" className="first">
                          EU Size
                        </td>
                        <td colSpan="2">48</td>
                        <td colSpan="2">50</td>
                        <td colSpan="2">52</td>
                        <td colSpan="2">54</td>
                        <td colSpan="2">56</td>
                        <td colSpan="2">58</td>
                      </tr>
                      <tr>
                        <td width="14%" className="first"></td>
                        <td>inch</td>
                        <td className="first">cm</td>
                        <td>inch</td>
                        <td className="first">cm</td>
                        <td>inch</td>
                        <td className="first">cm</td>
                        <td>inch</td>
                        <td className="first">cm</td>
                        <td>inch</td>
                        <td className="first">cm</td>
                        <td>inch</td>
                        <td className="first">cm</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td width="14%" className="first">
                          Bust
                        </td>
                        <td>43</td>
                        <td className="first">109</td>
                        <td>45</td>
                        <td className="first">114</td>
                        <td>47</td>
                        <td className="first">119</td>
                        <td>49</td>
                        <td className="first">124</td>
                        <td>51</td>
                        <td className="first">130</td>
                        <td>53</td>
                        <td className="first">135</td>
                      </tr>
                      <tr>
                        <td width="14%" className="first">
                          Waist
                        </td>
                        <td>36 ¼</td>
                        <td className="first">92</td>
                        <td>38 ½</td>
                        <td className="first">98</td>
                        <td>40 ¾</td>
                        <td className="first">104</td>
                        <td>43</td>
                        <td className="first">109</td>
                        <td>45 ¼</td>
                        <td className="first">115</td>
                        <td>47 ½</td>
                        <td className="first">121</td>
                      </tr>
                      <tr>
                        <td width="14%" className="first">
                          Hips
                        </td>
                        <td>45 ½</td>
                        <td className="first">116</td>
                        <td>47 ½</td>
                        <td className="first">121</td>
                        <td>49 ½</td>
                        <td className="first">126</td>
                        <td>51 ½</td>
                        <td className="first">131</td>
                        <td>53 ½</td>
                        <td className="first">136</td>
                        <td>55 ½</td>
                        <td className="first">141</td>
                      </tr>
                      <tr>
                        <td width="14%" className="first">
                          Hollow to hem(front)
                        </td>
                        <td>61</td>
                        <td className="first">155</td>
                        <td>61</td>
                        <td className="first">155</td>
                        <td>61</td>
                        <td className="first">155</td>
                        <td>61</td>
                        <td className="first">155</td>
                        <td>61</td>
                        <td className="first">155</td>
                        <td>61</td>
                        <td className="first">155</td>
                      </tr>
                    </tbody>
                  </table>

                  <p className="size_bottom">
                    <span className="red">Note: </span>The Hollow to Floor
                    measurement of our standard dresses already includes 2
                    inches (ca.5 cm) extra to fit high heels.&nbsp;Size chart is
                    provided as a general sizing guide only. Specific
                    measurements vary with each style.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  </Modal>
);
