import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring/web.cjs';

import { Translate } from 'react-localize-redux';

import {
  changeCurrency,
  changeLanguage,
  deleteCartItem,
} from '../../Action/index';
import HeaderDetails from './HeaderDetails';
import { useMutation } from 'react-apollo';
import { CHANGE_LANG } from './Header-gql';

const mapToDispatch = {
  changeLang: changeLanguage,
  deleteFromCart: deleteCartItem,
  changeCurrencies: changeCurrency,
};

const mapToProps = state => ({
  account: state.Account,
  cart: state.Cart,
  wishlist: state.Wishlist,
  configs: state.Configs,
  notification: state.Notification,
});

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export default connect(
  mapToProps,
  mapToDispatch,
)(
  ({
    changeLang,
    account: {
      currency,
      language,
      user: { storyCredit, storyCreditOnHold, account },
      productLength,
    },
    changeCurrencies,
    notification: { notifiactionNotRead },
    setOpenMenu,
    invertColor,
    cart,
    deleteFromCart,
    wishlist,
    configs: { loggedin, category },
  }) => {
    const [open, setOpen] = React.useState(false);
    const [changeLangMutation] = useMutation(CHANGE_LANG);

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const changeLanguage = () => {
      const newLanguage = language === 'en' ? 'ar' : 'en';
      changeLang(newLanguage);
      if (account) {
        changeLangMutation({
          variables: {
            id: account?.id,
            lang: newLanguage,
          },
        });
      }
      setTimeout(() => window.location.reload(), 0);
    };

    return (
      <>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open}
          className="modal cash-modal"
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
          <Fade in={open} className="fade-in">
            <div className="cash-continer">
              <h2 id="spring-modal-title" style={{ color: '#000' }}>
                <Translate id="myWallet" />:
                {Intl.NumberFormat(`${language}-JD`, {
                  style: 'currency',
                  currency: currency.value,
                })
                  .format(storyCredit)
                  .replace('.000', '')}
              </h2>
              <p id="spring-modal-description">
                <Translate id="Balance On Hold" />:
                {Intl.NumberFormat(`${language}-JD`, {
                  style: 'currency',
                  currency: currency.value,
                })
                  .format(storyCreditOnHold)
                  .replace('.000', '')}
              </p>
              <div style={{ marginBottom: 20 }}>
                <button
                  onClick={() => {
                    handleClose();
                    window.location.href = '/cash-back';
                  }}
                  type="submit"
                  className="btn btn-outline-primary-2">
                  <Translate id="Add Details" />
                </button>
              </div>
            </div>
          </Fade>
        </Modal>

        <HeaderDetails
          DomLink={Link}
          language={language}
          changeLanguage={changeLanguage}
          setOpenMenu={setOpenMenu}
          invertColor={invertColor}
          cart={cart}
          deleteFromCart={deleteFromCart}
          wishlist={wishlist}
          handleOpen={handleOpen}
          loggedin={loggedin}
          category={category}
          productLength={productLength}
          notifiactionNotRead={notifiactionNotRead}
          changeCurrencies={changeCurrencies}
          currency={currency}
        />
      </>
    );
  },
);
