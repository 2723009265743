import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import Link from '@material-ui/core/Link';

import BlackLogo from '../../Styles/images/logo-en-layls-gold.png';
import BlackLogoAR from '../../Styles/images/logo-ar-layls-gold.png';
import Banar2 from '../../Styles/images/menu/banner-2.jpg';
import { PUBLICURI } from '../../Configs';
import { SVG } from '../../Styles/SVG/SVG';

const CountrySelector = [
  { value: 'usd', label: 'USA' },
  { value: 'jod', label: 'Jordan' },
  { value: 'sar', label: 'KSA' },
  { value: 'egp', label: 'Egypt' },
];

export default ({
  setOpenMenu,
  invertColor,
  cart,
  deleteFromCart,
  wishlist,
  changeLanguage,
  DomLink,
  language,
  handleOpen,
  loggedin,
  category,
  notifiactionNotRead,
  changeCurrencies,
  currency,
}) => {
  const [search, setSearch] = useState('');

  const [openFlag, seOpenFlag] = useState(false);

  return (
    <Translate>
      {({ translate }) => (
        <header
          className={
            invertColor ? 'header header-5 invert' : 'header header-5 '
          }>
          <div className="header-middle sticky-header">
            <div className="container-fluid">
              <div className="header-left">
                <button
                  className="mobile-menu-toggler"
                  onClick={() => setOpenMenu(true)}>
                  <span className="sr-only">Toggle mobile menu</span>
                  <i className="icon-bars"></i>
                </button>

                <a href="/" className="logo">
                  <img
                    src={
                      invertColor
                        ? language === 'en'
                          ? BlackLogo
                          : BlackLogoAR
                        : language === 'en'
                        ? BlackLogo
                        : BlackLogoAR
                    }
                    alt="Logo"
                    width="92"
                    height="25"
                  />
                </a>

                <nav className="main-nav">
                  <ul className="menu sf-arrows">
                    <li>
                      {loggedin && (
                        <Link href="/new-product" className="sf-no-ul">
                          <Translate id="CREATE PRODUCT" />
                        </Link>
                      )}
                    </li>
                    <li>
                      <a href="#r" className="sf-with-ul">
                        <Translate id="dresses" />
                      </a>

                      <div className="megamenu megamenu-sm">
                        <div className="row no-gutters">
                          <div className="col-md-6">
                            <div className="menu-col">
                              <div className="menu-title">Dresses Category</div>
                              <ul>
                                {category &&
                                  category.length > 0 &&
                                  category.map((categ, index) => (
                                    <li key={index}>
                                      <a href={`/${categ.id}`}>
                                        {categ.name[language]}
                                      </a>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="banner banner-overlay">
                              <a href="#r">
                                <img src={Banar2} alt="Banner" />

                                <div className="banner-content banner-content-bottom">
                                  <div className="banner-title text-white">
                                    New Trends
                                    <br />
                                    <span>
                                      <strong>spring 2019</strong>
                                    </span>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      {!loggedin && (
                        <Link href="/signup" className="sf-no-ul">
                          <Translate id="Signup" />
                        </Link>
                      )}
                    </li>
                    {!loggedin && (
                      <li>
                        <Link href="/login" className="sf-no-ul">
                          <Translate id="Login" />
                        </Link>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>

              <div
                className={
                  language === 'ar' ? 'header-right-ar' : 'header-right'
                }>
                <div className="header-search header-search-extended header-search-visible">
                  <a
                    href={`/search/${search}`}
                    className="search-toggle"
                    role="button">
                    <i className="icon-search"></i>
                  </a>
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      window.location.href = `/search/${search}`;
                    }}>
                    <div className="header-search-wrapper">
                      <input
                        type="search"
                        className="form-control"
                        name="word"
                        id="word"
                        placeholder={translate("I'm looking for ...")}
                        required
                        onChange={e => setSearch(e.target.value)}
                      />

                      <button className="btn btn-primary" type="submit">
                        <i className="icon-search"></i>
                      </button>
                    </div>
                  </form>
                </div>
                {loggedin && (
                  <>
                    {' '}
                    <a href="/profile" className="wishlist-link">
                      {SVG.userInfo}
                    </a>
                    <div onClick={handleOpen}>
                      <div
                        className="wishlist-link"
                        style={{
                          cursor: 'pointer',
                          marginRight: 30,
                        }}>
                        {SVG.money}
                      </div>
                    </div>
                    <a href="/notification" className="wishlist-link link">
                      {SVG.bell}
                      <span className="wishlist-count">
                        {notifiactionNotRead}
                      </span>
                    </a>
                    <a href="/wishlist" className="wishlist-link">
                      {SVG.heart}
                      <span className="wishlist-count">{wishlist.total}</span>
                    </a>
                    <div className="dropdown cart-dropdown">
                      <a
                        href="/cart"
                        className="dropdown-toggle"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-display="static">
                        {SVG.cart}
                        <span className="cart-count">{cart.total}</span>
                      </a>

                      <div className="dropdown-menu dropdown-menu-right">
                        <div className="dropdown-cart-products">
                          {cart.cartItems.length > 0 ? (
                            cart.cartItems.map((items, index) => (
                              <div key={index} className="product">
                                <div className="product-cart-details">
                                  <h4 className="product-title">
                                    <DomLink
                                      to={{
                                        pathname: `/product/${items.product.id}`,
                                        state: { id: items.product.id },
                                      }}>
                                      {items.product?.name}
                                    </DomLink>
                                  </h4>

                                  <span className="cart-product-info">
                                    <span className="cart-product-qty">
                                      {items.quantity}
                                    </span>
                                    x
                                    {Intl.NumberFormat(`${language}-JD`, {
                                      style: 'currency',
                                      currency: currency.value,
                                    })
                                      .format(
                                        items.product.priceCurrency[
                                          currency.value
                                        ] * items.quantity,
                                      )
                                      .replace('.000', '')}
                                  </span>
                                </div>

                                <figure className="product-image-container">
                                  <DomLink
                                    className="product-image"
                                    to={{
                                      pathname: `/product/${items.product.id}`,
                                      state: { id: items.product.id },
                                    }}>
                                    <img
                                      src={`${PUBLICURI}${items.product.pictures[0]?.path}`}
                                      alt="product"
                                    />
                                  </DomLink>
                                </figure>
                                <Link
                                  style={{ cursor: 'pointer' }}
                                  className="btn-remove"
                                  title="Remove Product"
                                  onClick={() =>
                                    deleteFromCart(
                                      items.product,
                                      null,
                                      items.quantity,
                                    )
                                  }>
                                  <i className="icon-close"></i>
                                </Link>
                              </div>
                            ))
                          ) : (
                            <div>
                              <Translate id="No products yet." />
                            </div>
                          )}
                        </div>

                        <div className="dropdown-cart-total">
                          <span>
                            <Translate id="total" />
                          </span>

                          <span className="cart-total-price">
                            {Intl.NumberFormat(`${language}-JD`, {
                              style: 'currency',
                              currency: currency.value,
                            })
                              .format(cart.totalPrice)
                              .replace('.000', '')}
                          </span>
                        </div>

                        <div className="dropdown-cart-action">
                          <a href="/cart" className="btn btn-primary">
                            <Translate id="View Cart" />
                          </a>
                          <a
                            href="/checkout"
                            className="btn btn-outline-primary-2">
                            <span>
                              <Translate id="checkout" />
                            </span>
                            <i className="icon-long-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="jsx-522397250 drop-country-container">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingLeft: 20,
                      paddingTop: 10,
                    }}
                    onClick={() => seOpenFlag(!openFlag)}>
                    <div
                      style={{
                        backgroundImage: `url(${require(`../../Styles/images/flags/${currency.value}.png`)})`,
                        width: '30px',
                        height: '20px',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        marginRight: 5,
                      }}
                    />
                    <div className="flagUpperLabel">{currency.label}</div>
                  </div>
                  {openFlag && (
                    <div className="jsx-522397250 dropdownContainer">
                      {CountrySelector.map(items => (
                        <div className="jsx-522397250">
                          <button
                            onClick={() => {
                              seOpenFlag(!openFlag);
                              changeCurrencies(items);
                            }}
                            className="jsx-522397250 option">
                            <div
                              style={{
                                backgroundImage: `url(${require(`../../Styles/images/flags/${items.value}.png`)})`,
                                width: '30px',
                                height: '20px',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                marginRight: 5,
                              }}
                            />
                            <div className="jsx-522397250 optionName ">
                              {items.label}
                            </div>
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <Link
                  href="#"
                  onClick={changeLanguage}
                  className="wishlist-link"
                  style={{
                    marginLeft: '1.5rem',
                  }}>
                  {language === 'en' ? SVG.arabic : 'E'}
                </Link>
              </div>
            </div>
          </div>
        </header>
      )}
    </Translate>
  );
};
