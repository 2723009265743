import React from 'react';

import BG from '../../../Styles/images/page-header-bg.jpg';
import CatBG from '../../../Styles/images/backgrounds/cta/bg-7.jpg';

import Collapse from '../../../Components/Collapse';
import { Translate } from 'react-localize-redux';

export default () => (
  <main className="main faq-page">
    <div
      className="page-header text-center"
      style={{ backgroundImage: `url(${BG})` }}>
      <div className="container">
        <h1 className="page-title">
          F.A.Q<span>Pages</span>
        </h1>
      </div>
    </div>
    <nav aria-label="breadcrumb" className="breadcrumb-nav">
      <div className="container">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">
              <Translate id="home" />
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            FAQ
          </li>
        </ol>
      </div>
    </nav>

    <div className="page-content">
      <div className="container">
        <h2 className="title text-center mb-3">Shipping Information</h2>
        <div className="accordion accordion-rounded">
          <Collapse
            className="with-shadow"
            title="How I can manage my account? ">
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec
              odio. Quisque volutpat mattis eros. Nullam malesuada erat ut
              turpis. Suspendisse urna nibh, viverra non, semper suscipit,
              posuere a, pede. Donec nec justo eget felis facilisis fermentum.
              Aliquam porttitor mauris sit amet orci. Aenean dignissim
              pellentesque felis. Phasellus ultrices nulla quis nibh. Quisque a
              lectus. Donec consectetuer ligula vulputate sem tristique cursus.
              Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi.
            </p>
          </Collapse>
          <Collapse
            className="with-shadow"
            title="How will my parcel be delivered?">
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec
              odio. Quisque volutpat mattis eros. Nullam malesuada erat ut
              turpis. Suspendisse urna nibh, viverra non, semper suscipit,
              posuere a, pede. Donec nec justo eget felis facilisis fermentum.
              Aliquam porttitor mauris sit amet orci. Aenean dignissim
              pellentesque felis. Phasellus ultrices nulla quis nibh. Quisque a
              lectus. Donec consectetuer ligula vulputate sem tristique cursus.
              Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi.
            </p>
          </Collapse>

          <Collapse className="with-shadow" title="Do I pay for delivery?">
            <p>
              Breakfast agreeable incommode departure it an. By ignorant at on
              wondered relation. Enough at tastes really so cousin am of.
              Extensive therefore supported by extremity of contented. Is
              pursuit compact demesne invited elderly be. View him she roof tell
              her case has sigh. Moreover is possible he admitted sociable
              concerns. By in cold no less been sent hard hill.
            </p>
          </Collapse>
          <Collapse
            className="with-shadow"
            title="Will I be charged customs fees?">
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec
              odio. Quisque volutpat mattis eros. Nullam malesuada erat ut
              turpis. Suspendisse urna nibh, viverra non, semper suscipit,
              posuere a, pede. Donec nec justo eget felis facilisis fermentum.
              Aliquam porttitor mauris sit amet orci. Aenean dignissim
              pellentesque felis. Phasellus ultrices nulla quis nibh. Quisque a
              lectus. Donec consectetuer ligula vulputate sem tristique cursus.
              Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi.
            </p>
          </Collapse>
        </div>

        <h2 className="title text-center mb-3">Orders and Returns</h2>
        <div className="accordion accordion-rounded">
          <Collapse className="with-shadow" title="Tracking my order">
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec
              odio. Quisque volutpat mattis eros. Nullam malesuada erat ut
              turpis. Suspendisse urna nibh, viverra non, semper suscipit,
              posuere a, pede. Donec nec justo eget felis facilisis fermentum.
            </p>
          </Collapse>

          <Collapse
            className="with-shadow"
            title=" I haven’t received my order">
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec
              odio. Quisque volutpat mattis eros. Nullam malesuada erat ut
              turpis. Suspendisse urna nibh, viverra non, semper suscipit,
              posuere a, pede. Donec nec justo eget felis facilisis fermentum.
            </p>
          </Collapse>
          <Collapse className="with-shadow" title=" How can I return an item?">
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec
              odio. Quisque volutpat mattis eros. Nullam malesuada erat ut
              turpis. Suspendisse urna nibh, viverra non, semper suscipit,
              posuere a, pede. Donec nec justo eget felis facilisis fermentum.
            </p>
          </Collapse>
        </div>

        <h2 className="title text-center mb-3">Payments</h2>
        <div className="accordion accordion-rounded">
          <Collapse
            className="with-shadow"
            title="What payment types can I use?">
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec
              odio. Quisque volutpat mattis eros. Nullam malesuada erat ut
              turpis. Suspendisse urna nibh, viverra non, semper suscipit,
              posuere a, pede. Donec nec justo eget felis facilisis fermentum.
              Aliquam porttitor mauris sit amet orci. Aenean dignissim
              pellentesque felis. Phasellus ultrices nulla quis nibh. Quisque a
              lectus. Donec consectetuer ligula vulputate sem tristique cursus.
              Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi.
            </p>
          </Collapse>

          <Collapse className="with-shadow" title="Can I pay by Gift Card?">
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec
              odio. Quisque volutpat mattis eros. Nullam malesuada erat ut
              turpis. Suspendisse urna nibh, viverra non, semper suscipit,
              posuere a, pede. Donec nec justo eget felis facilisis fermentum.
              Aliquam porttitor mauris sit amet orci. Aenean dignissim
              pellentesque felis. Phasellus ultrices nulla quis nibh. Quisque a
              lectus. Donec consectetuer ligula vulputate sem tristique cursus.
              Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi.
            </p>
          </Collapse>
        </div>
      </div>
    </div>

    <div
      className="cta cta-display bg-image pt-4 pb-4"
      style={{ backgroundImage: `url(${CatBG})` }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-9 col-xl-7">
            <div className="row no-gutters flex-column flex-sm-row align-items-sm-center">
              <div className="col">
                <h3 className="cta-title text-white">
                  If You Have More Questions
                </h3>
                <p className="cta-desc text-white">
                  Quisque volutpat mattis eros
                </p>
              </div>

              <div className="col-auto">
                <a href="/contact-us" className="btn btn-outline-white">
                  <span>CONTACT US</span>
                  <i className="icon-long-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
);
