import React from 'react';
import { Col } from 'reactstrap';

import { Translate } from 'react-localize-redux';
import BG from '../../../Styles/../Styles/images/page-header-bg.jpg';
import ProductCard from '../../../Components/ProductCard';

export default ({ setFilter, products, DomLink }) => (
  <main className="main  all-product">
    <div
      className="page-header text-center"
      style={{ backgroundImage: `url(${BG})` }}>
      <div className="container-fluid">
        <h1 className="page-title">
          <Translate id="allproducts" />
          <span>
            <Translate id="shop" />
          </span>
        </h1>
      </div>
    </div>
    <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
      <div className="container-fluid">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">
              <Translate id="home" />
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Translate id="products" />
          </li>
        </ol>
      </div>
    </nav>

    <div className="page-content">
      <div className="container-fluid new-arrivals all-product">
        <div className="toolbox">
          <div className="toolbox-left">
            {/* <a
              href="#r"
              className="sidebar-toggler"
              onClick={() => setFilter(true)}>
              <i className="icon-bars"></i>Filters
            </a> */}
          </div>

          <div className="toolbox-center">
            <div className="toolbox-info"></div>
          </div>

          <div className="toolbox-right"></div>
        </div>

        <div className="tab-content">
          <div className="tab-pane p-0 fade active in">
            <div className="products">
              <div className="row">
                {products.length > 0 ? (
                  products.map((product, index1) => (
                    <Col md={4} lg={3} xl={2} xs={6} key={index1}>
                      <ProductCard product={product} DomLink={DomLink} />
                    </Col>
                  ))
                ) : (
                  <div>
                    <Translate id="No products yet." />
                  </div>
                )}
              </div>

              {products.length < 0 && (
                <div className="load-more-container text-center">
                  <a href="#r" className="btn btn-outline-darker btn-load-more">
                    <Translate id="More Products" />{' '}
                    <i className="icon-refresh"></i>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
);
