import React, { useState } from 'react';
import Link from '@material-ui/core/Link';
import SweetAlert from 'react-bootstrap-sweetalert';

import BG from '../../../Styles/images/page-header-bg.jpg';
import ProfileDashboard from './ProfileDashboard';
import ProfileProduct from './ProfileProduct';
import ProfileAccount from './ProfileAccount';
import ProfileOrder from './ProfileOrders';

import { Translate } from 'react-localize-redux';

export default ({
  user,
  dashboard,
  logout,
  deleteProduct,
  language,
  orders,
  currency,
}) => {
  const [state, setState] = useState({
    dashboard: true,
    products: false,
    account: false,
    orders: false,
  });

  const changeTabs = tab => {
    ['dashboard', 'products', 'account', 'orders'].forEach(tabs => {
      setState(prevState => ({ ...prevState, [tabs]: tabs === tab }));
    });
  };

  const [openModal, setModal] = useState(false);
  const [openModalDone, setModalDone] = useState(false);

  const hideAlert = () => {
    setModal(false);
    setModalDone(false);
  };

  const logoutAlert = () => {
    hideAlert();
  };

  return (
    <main className="main profile">
      <Translate>
        {({ translate }) => (
          <>
            <SweetAlert
              show={openModal}
              warning
              title={translate('Are you sure?')}
              showCancel
              cancelBtnText={translate('Close')}
              confirmBtnText={translate('Yes')}
              onCancel={hideAlert}
              onConfirm={() => {
                logout();
                setModalDone(true);
              }}
            />
            <SweetAlert
              show={openModalDone}
              success
              title={translate('Logout')}
              confirmBtnText={translate('Okay')}
              onCancel={hideAlert}
              onConfirm={logoutAlert}
            />
          </>
        )}
      </Translate>
      <div
        className="page-header text-center"
        style={{ backgroundImage: `url(${BG})` }}>
        <div className="container">
          <h1 className="page-title">
            <Translate id="My Account" />
          </h1>
        </div>
      </div>
      <nav aria-label="breadcrumb" className="breadcrumb-nav mb-3">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">
                {' '}
                <Translate id="home" />
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <Translate id="My Account" />
            </li>
          </ol>
        </div>
      </nav>

      <div className="page-content">
        <div className="dashboard">
          <div className="container">
            <p
              style={{
                fontSize: 22,
              }}>
              <Translate id="Hello" />{' '}
              <span className="font-weight-normal text-dark">
                {user.account.firstName}
              </span>
            </p>
            <div className="row">
              <aside className="col-md-4 col-lg-3">
                <ul
                  className="nav nav-dashboard flex-column mb-3 mb-md-0"
                  role="tablist">
                  <li
                    className={
                      state.dashboard ? 'nav-item active' : 'nav-item '
                    }>
                    <Link
                      className="nav-link"
                      style={{ cursor: 'pointer' }}
                      onClick={() => changeTabs('dashboard')}>
                      <Translate id="dashboard" />
                    </Link>
                  </li>
                  <li
                    className={
                      state.products ? 'nav-item active' : 'nav-item '
                    }>
                    <Link
                      className="nav-link"
                      style={{ cursor: 'pointer' }}
                      onClick={() => changeTabs('products')}>
                      <Translate id="myProducts" />
                    </Link>
                  </li>
                  <li
                    className={state.orders ? 'nav-item active' : 'nav-item '}>
                    <Link
                      className="nav-link"
                      style={{ cursor: 'pointer' }}
                      onClick={() => changeTabs('orders')}>
                      <Translate id="MyOrders" />
                    </Link>
                  </li>
                  <li
                    className={state.account ? 'nav-item active' : 'nav-item '}>
                    <Link
                      className="nav-link"
                      style={{ cursor: 'pointer' }}
                      onClick={() => changeTabs('account')}>
                      <Translate id="Account Details" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      style={{ cursor: 'pointer' }}
                      className="nav-link"
                      onClick={() => setModal(true)}>
                      <Translate id="Sign Out" />
                    </Link>
                  </li>
                </ul>
              </aside>

              <div className="col-md-8 col-lg-9">
                <div className="tab-content">
                  {state.dashboard && (
                    <ProfileDashboard
                      name={user.account.firstName}
                      dashboard={dashboard?.dashboard}
                      productsLength={dashboard?.products?.length}
                    />
                  )}

                  {state.products && (
                    <ProfileProduct
                      products={dashboard?.products}
                      deleteProduct={deleteProduct}
                    />
                  )}

                  {state.account && <ProfileAccount />}

                  {state.orders && (
                    <ProfileOrder
                      orders={dashboard?.orders}
                      language={language}
                      currency={currency}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
