import React from 'react';
import { PUBLICURI } from '../../../Configs';

import { Translate } from 'react-localize-redux';

export default ({ orders, language, currency }) =>
  orders.length > 0 ? (
    <>
      <div>
        <a href="/all-product" className="btn btn-outline-primary-2">
          <span>
            <Translate id="goshopping" />
          </span>
          <i className="icon-long-arrow-right"></i>
        </a>
      </div>
      <div className="page-content">
        <div className="container" style={{ width: '100%' }}>
          {orders.map((order, orderIndex) => (
            <table className="table table-wishlist table-mobile">
              <thead>
                <tr>
                  <th>
                    <Translate id="product" />
                  </th>
                  <th>
                    <Translate id="price" />
                  </th>
                  <th>
                    <Translate id="Total Price" />
                  </th>
                  <th>
                    <Translate id="status" />
                  </th>
                </tr>
              </thead>
              <tbody key={orderIndex}>
                {order.items.map((item, index) => (
                  <tr key={index}>
                    <td className="product-col">
                      <div className="product">
                        <figure className="product-media">
                          <a href={`/product/${item.product?.id}`}>
                            <img
                              src={`${PUBLICURI}${item.product?.pictures[0].path}`}
                              alt="Product"
                            />
                          </a>
                        </figure>

                        <h3 className="product-title">
                          <a
                            href={`/product/${item.product?.id}`}>{`${item?.product?.name}`}</a>
                        </h3>
                      </div>
                    </td>
                    <td className="price-col">
                      {Intl.NumberFormat(`${language}-JD`, {
                        style: 'currency',
                        currency: currency.value,
                      })
                        .format(item.product?.priceCurrency[currency.value])
                        .replace('.000', '')}
                    </td>
                    <td className="stock-col">
                      <span className="product-title">{order?.total}</span>
                    </td>
                    <td className="stock-col">
                      <span
                        className={
                          order?.status === 'Pending'
                            ? 'out-of-stock'
                            : 'in-stock'
                        }>
                        {order?.status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))}
        </div>
      </div>
    </>
  ) : (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          flexDirection: 'column',
        }}>
        <div className="empty-list-img"></div>
        <p>
          <Translate id="No order has been made yet." />
        </p>
        <a
          href="/all-product"
          className="btn btn-outline-dark-2 btn-block mb-4 shopping-cart-btn"
          style={{ width: '1vw' }}>
          <span>
            <Translate id="goshopping" />
          </span>
        </a>
      </div>
    </>
  );
