import gql from 'graphql-tag';
import { PRODUCT_GQL } from '../Containers/AllProducts/AllProducts-gql';

export const USER_GQL = `
  id
  facebook
  google
  storyCredit
  storyCreditOnHold
  hasNotification
  account {
    id
    lang
    firstName
    lastName
    country
    email
    mobile
    token
  }
  createdAt
`;

export const GET_CATEGORIES = gql`
  query categories {
    categories {
      id
      name {
        ar
        en
      }
    }
  }
`;

export const GET_CART = gql`
  query findCartByUser($user: ID!) {
    findCartByUser(user: $user) {
      productLength
      cart {
        quantity
        product {
          ${PRODUCT_GQL}
        }
      }
    }
  }
`;

export const USER_DETAILS = gql`
  query userById($id: ID) {
    userById(id: $id) {
      ${USER_GQL}
    }
  }
`;
