import React from 'react';

import { Translate } from 'react-localize-redux';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, CardBody, Col, Row } from 'reactstrap';

const data = [
  { value: 100, fill: '#c96' },
  { value: 10, fill: '#eeeeee' },
];

export default ({ name, dashboard, productsLength }) => (
  <>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}>
      <div
        style={{
          marginBottom: 10,
          paddingLeft: '10px',
          paddingRight: '10px',
        }}>
        <a href="/new-product" className="btn btn-primary">
          <span>
            <Translate
              id={productsLength > 0 ? 'CREATE PRODUCT' : 'AddNewDress'}
            />
          </span>
          <i className="icon-long-arrow-right"></i>
        </a>
      </div>
    </div>
    <Row>
      <Col md={6} xl={6} lg={6} sm={12} xs={12}>
        <Card>
          <CardBody className="dashboard__health-chart-card">
            <div className="card__title">
              <h5 className="bold-text card__title-center">
                <Translate id="products" />
              </h5>
            </div>
            <div className="dashboard__health-chart">
              <ResponsiveContainer height={180}>
                <PieChart>
                  <Pie
                    data={data}
                    dataKey="value"
                    cy={85}
                    innerRadius={80}
                    outerRadius={90}
                  />
                </PieChart>
              </ResponsiveContainer>
              <div
                className="dashboard__health-chart-info"
                style={{
                  top: 'calc(50% - 35px)',
                }}>
                <p className="dashboard__health-chart-number">
                  {dashboard?.products}
                </p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col md={6} xl={6} lg={6} sm={12} xs={12}>
        <Card>
          <CardBody className="dashboard__health-chart-card">
            <div className="card__title">
              <h5 className="bold-text card__title-center">
                <Translate id="Orders" />
              </h5>
            </div>
            <div className="dashboard__health-chart">
              <ResponsiveContainer height={180}>
                <PieChart>
                  <Pie
                    data={data}
                    dataKey="value"
                    cy={85}
                    innerRadius={80}
                    outerRadius={90}
                  />
                </PieChart>
              </ResponsiveContainer>
              <div
                className="dashboard__health-chart-info"
                style={{
                  top: 'calc(50% - 35px)',
                }}>
                <p className="dashboard__health-chart-number">
                  {dashboard?.orders}
                </p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col md={6} xl={6} lg={6} sm={12} xs={12}>
        <Card>
          <CardBody className="dashboard__health-chart-card">
            <div className="card__title">
              <h5 className="bold-text card__title-center">
                <Translate id="Total views" />
              </h5>
            </div>
            <div className="dashboard__health-chart">
              <ResponsiveContainer height={180}>
                <PieChart>
                  <Pie
                    data={data}
                    dataKey="value"
                    cy={85}
                    innerRadius={80}
                    outerRadius={90}
                  />
                </PieChart>
              </ResponsiveContainer>
              <div
                className="dashboard__health-chart-info"
                style={{
                  top: 'calc(50% - 35px)',
                }}>
                <p className="dashboard__health-chart-number">
                  {dashboard?.orders}
                </p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col md={6} xl={6} lg={6} sm={12} xs={12}>
        <Card>
          <CardBody className="dashboard__health-chart-card">
            <div className="card__title">
              <h5 className="bold-text card__title-center">
                <Translate id="Products sold" />
              </h5>
            </div>
            <div className="dashboard__health-chart">
              <ResponsiveContainer height={180}>
                <PieChart>
                  <Pie
                    data={data}
                    dataKey="value"
                    cy={85}
                    innerRadius={80}
                    outerRadius={90}
                  />
                </PieChart>
              </ResponsiveContainer>
              <div
                className="dashboard__health-chart-info"
                style={{
                  top: 'calc(50% - 35px)',
                }}>
                <p className="dashboard__health-chart-number">
                  {dashboard?.orders}
                </p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>

    {/* <div className="dashboard-content">
      <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-success border-success">
        <div className="widget-chart-flex">
          <CountUp
            start={0}
            end={dashboard?.products}
            separator=""
            decimals={0}
            decimal=""
            prefix=""
            duration="10"
          />
        </div>
        <h6 className="widget-subheading mb-0 opacity-5">
          {' '}
          <Translate id="products" />
        </h6>
      </div>
      <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-success border-success">
        <div className="widget-chart-flex">
          <CountUp
            start={0}
            end={dashboard?.orders}
            separator=""
            decimals={0}
            decimal=""
            prefix=""
            duration="10"
          />
        </div>
        <h6 className="widget-subheading opacity-5">
          <Translate id="Orders" />
        </h6>
      </div>
      <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-success border-success">
        <div className="widget-chart-flex">
          <CountUp
            start={0}
            end={dashboard?.orders}
            separator=""
            decimals={0}
            decimal=""
            prefix=""
            duration="10"
          />
        </div>
        <h6 className="widget-subheading opacity-5">
          <Translate id="Total views" />
        </h6>
      </div>
      <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-success border-success">
        <div className="widget-chart-flex">
          <CountUp
            start={0}
            end={dashboard?.orders}
            separator=""
            decimals={0}
            decimal=""
            prefix=""
            duration="10"
          />
        </div>
        <h6 className="widget-subheading opacity-5">
          <Translate id="Products sold" />
        </h6>
      </div>
    </div>
     */}
  </>
);
