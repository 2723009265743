import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring/web.cjs';
import { Translate } from 'react-localize-redux';

import Video from '../../../Styles/Videos/ZaidAbulaila4.mp4';

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export default ({ modal, toggle }) => (
  <Modal
    aria-labelledby="spring-modal-title"
    aria-describedby="spring-modal-description"
    open={modal}
    className="modal cash-modal"
    onClose={toggle}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}>
    <Fade in={modal} className="fade-in">
      <div className="modal-dialog video-modal" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              <Translate id="Video" />
            </h5>
            <button
              type="button"
              className="close"
              onClick={toggle}
              aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="video-container">
              <video controls autoPlay="autoplay" muted>
                <source src={Video} type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
            </div>
          </div>
          <div className="modal-footer">
            <div
              style={{
                display: 'flex',
                marginTop: '10px',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <button
                onClick={toggle}
                type="button"
                className="btn btn-secondary">
                <Translate id="Close" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  </Modal>
);
