import React from 'react';
import { connect } from 'react-redux';

import { Translate } from 'react-localize-redux';

const MobileMenu = ({
  setOpenMenu,
  openMenu,
  configs: { loggedin, category },
  account: { productLength },
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <>
          <div
            onClick={() => setOpenMenu(false)}
            className={`mobile-menu-overlay ${
              openMenu ? 'open-mobile-menu-overlay' : ''
            }`}></div>

          <div
            className={`mobile-menu-container mobile-menu-light ${
              openMenu ? 'open-mobile-menu' : ''
            }`}>
            <div className="mobile-menu-wrapper">
              <span
                className="mobile-menu-close"
                onClick={() => setOpenMenu(false)}>
                <i className="icon-close"></i>
              </span>

              <nav className="mobile-nav">
                <ul className="mobile-menu">
                  {loggedin ? (
                    <>
                      <li>
                        <a href="/profile">
                          <Translate id="Account" />
                        </a>
                      </li>
                      <li>
                        <a href="/new-product">
                          <Translate
                            id={
                              productLength > 0
                                ? 'CREATE PRODUCT'
                                : 'AddNewDress'
                            }
                          />
                        </a>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <a href="/login">
                          <Translate id="Login" />
                        </a>
                      </li>
                      <li>
                        <a href="/signup">
                          <Translate id="Signup" />
                        </a>
                      </li>
                    </>
                  )}
                </ul>
              </nav>

              <div className="social-icons">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/laylscom/"
                  className="social-icon social-facebook"
                  title="Facebook">
                  <i className="icon-facebook-f"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/laylscom/"
                  className="social-icon social-instagram"
                  title="Instagram">
                  <i className="icon-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </Translate>
  );
};

const mapStateToProps = state => ({
  configs: state.Configs,
  account: state.Account,
});

export default connect(mapStateToProps)(MobileMenu);
