import gql from 'graphql-tag';

export const USER_NOTIFICATION = gql`
  query userNotifications($id: ID) {
    userNotifications(id: $id) {
      id
      createdAt
      notification {
        body {
          ar
          en
        }
        title {
          ar
          en
        }
        read
      }
    }
  }
`;

export const READ_NOTIFICAIOTN = gql`
  mutation readNotification($id: ID) {
    readNotification(id: $id)
  }
`;
