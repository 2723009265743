import React from 'react';
import PhoneInput from 'react-phone-input-2';
import ar from 'react-phone-input-2/lang/ar.json';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { useMutation } from 'react-apollo';
import { toast } from 'react-toastify';

import { Translate } from 'react-localize-redux';

import { loginUser } from '../../../Action';
import { UPDATEACCOUNT } from '../Profile-gql';

const mapDispathToProps = {
  updateUser: loginUser,
};

const mapToProps = state => ({
  account: state.Account,
});

export default connect(
  mapToProps,
  mapDispathToProps,
)(({ updateUser, account: { user, language } }) => {
  const formik = useFormik({
    initialValues: {
      firstName: user.account.firstName,
      lastName: user.account.lastName,
      mobile: user.account.mobile,
      email: user.account.email,
      country: user.account.country,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: () => {},
  });
  const [updateAccount] = useMutation(UPDATEACCOUNT);

  const { values, handleChange, handleSubmit, setFieldValue } = formik;

  const update = () => {
    updateAccount({
      variables: {
        user: {
          id: user.id,
          facebook: user?.facebook,
          google: user?.google,
          account: {
            id: user.account.id,
            firstName: values.firstName,
            lastName: values.lastName,
            mobile: values.mobile,
            email: values.email,
            currentPassword: values.currentPassword,
            password: values.newPassword,
          },
        },
      },
    })
      .then(async ({ data: { updateAccount } }) => {
        if (updateAccount) {
          updateUser(updateAccount);
          await localStorage.setItem('token', updateAccount.account.token);
        }
      })
      .catch(error =>
        toast.error(
          error.graphQLErrors.length > 0 ? (
            error.graphQLErrors[0].message
          ) : (
            <Translate id="somethingWentWrong" />
          ),
        ),
      );
  };

  const handleSubmits = e => {
    handleSubmit();
    e.preventDefault();
    if (values.currentPassword) {
      if (values.newPassword === values.confirmPassword) {
        update();
      }
    } else {
      update();
    }
  };
  return (
    <form onSubmit={handleSubmits}>
      <div className="row">
        <div className="col-sm-6">
          <label>
            <Translate id="firstname" />
          </label>
          <input
            type="text"
            className="form-control"
            required
            name="firstName"
            onChange={handleChange}
            value={values.firstName}
          />
        </div>

        <div className="col-sm-6">
          <label>
            <Translate id="lastname" />
          </label>
          <input
            type="text"
            className="form-control"
            required
            name="lastName"
            onChange={handleChange}
            value={values.lastName}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <label htmlFor="lastName">
            <Translate id="mobile" />
          </label>
          <PhoneInput
            className="form-control"
            country={values.country}
            onChange={(e, country) =>
              e.length < 15 &&
              setFieldValue('mobile', e) &&
              setFieldValue('country', country.countryCode)
            }
            localization={language === 'ar' && ar}
            value={values.mobile}
            excludeCountries={['il']}
            required
          />
        </div>
        <div className="col-sm-6">
          <label>
            <Translate id="email" />
          </label>
          <input
            type="email"
            className="form-control"
            required
            name="email"
            onChange={handleChange}
            value={values.email}
          />
        </div>
      </div>

      <label>
        {' '}
        <Translate id="Current password" />
      </label>
      <input
        type="password"
        className="form-control"
        name="currentPassword"
        onChange={handleChange}
        value={values.currentPassword}
      />

      <label>
        <Translate id="New password" />
      </label>
      <input
        type="password"
        className="form-control"
        name="newPassword"
        onChange={handleChange}
        value={values.newPassword}
      />

      <label>
        <Translate id="Confirm new password" />
      </label>
      <input
        type="password"
        className="form-control mb-2"
        name="confirmPassword"
        onChange={handleChange}
        value={values.confirmPassword}
      />

      <button
        type="submit"
        className="btn btn-outline-primary-2"
        style={{ marginTop: '10px' }}>
        <span>
          <Translate id="SAVE CHANGES" />
        </span>
        <i className="icon-long-arrow-right"></i>
      </button>
    </form>
  );
});
