import gql from 'graphql-tag';
import { USER_GQL } from '../../Navigation/Router-gql';

export const DASHBOARD = gql`
  query userDashboard($id: ID) {
    userDashboard(id: $id) {
      dashboard {
        products
        orders
      }
      products {
        id
        price
        approve
        preview
        oldPrice
        onHold
        qty
        name
        selectedColor
        type
        color
        priceCurrency {
          jod
          usd
          egp
          sar
        }
        oldPriceCurrency {
          jod
          usd
          egp
          sar
        }
        category {
          id
          name {
            ar
            en
          }
        }
        pictures {
          path
        }
      }
      orders {
        id
        total
        status
        items {
          id
          product {
            id
            price
            oldPrice
            onHold
            qty
            selectedColor
            type
            color
            name
            priceCurrency {
              jod
              usd
              egp
              sar
            }
            oldPriceCurrency {
              jod
              usd
              egp
              sar
            }
            category {
              id
              name {
                ar
                en
              }
            }
            pictures {
              path
            }
          }
          qty
          total
        }
      }
      sale {
        id
        product {
          id
          price
          oldPrice
          onHold
          qty
          selectedColor
          type
          color
          priceCurrency {
            jod
            usd
            egp
            sar
          }
          oldPriceCurrency {
            jod
            usd
            egp
            sar
          }
          category {
            id
            name {
              ar
              en
            }
          }
          pictures {
            path
          }
        }
        qty
        total
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation logout($id: ID) {
    logout(id: $id)
  }
`;

export const UPDATEACCOUNT = gql`
  mutation updateUser($user: UpdateUserInput) {
    updateUser(user: $user) {
      ${USER_GQL}
    }
  }
`;

export const APPROVEORDER = gql`
  mutation approveProduct($id: ID) {
    approveProduct(id: $id)
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($id: ID) {
    deleteProduct(id: $id)
  }
`;
