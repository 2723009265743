import React from 'react';
import { useMutation } from 'react-apollo';
import { connect } from 'react-redux';

import CheckoutDetails from './Components/CheckoutDetails';
import { CREATEORDER } from './Checkout-gql';
import { emptyCart } from '../../Action';
import Container from '../../Components/Container';

const mapStateToProps = state => ({
  cart: state.Cart,
  account: state.Account,
});

const mapDispatchToProps = {
  claerCart: emptyCart,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(({ cart, account, claerCart, history }) => {
  const [createOrder] = useMutation(CREATEORDER);

  return (
    <Container>
      <CheckoutDetails
        cart={cart}
        account={account}
        createOrder={createOrder}
        claerCart={claerCart}
        history={history}
      />
    </Container>
  );
});
