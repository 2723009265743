import React from 'react';

import moment from 'moment';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { SVG } from '../../Styles/SVG/SVG';
import BlackLogo from '../../Styles/images/logo-en-layls-gold.png';
import BlackLogoAR from '../../Styles/images/logo-ar-layls-gold.png';

const mapStateToProps = state => ({
  account: state.Account,
  configs: state.Configs,
});

export default connect(mapStateToProps)(({ account: { language } }) => (
  <footer className="footer footer-dark">
    <div className="footer-middle">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-lg-3">
            <div className="widget widget-about">
              <img
                src={language === 'en' ? BlackLogo : BlackLogoAR}
                className="footer-logo"
                alt="Footer Logo"
                width="105"
                height="25"
              />
              <p>
                <Translate id="sell" />, <Translate id="change" />,{' '}
                <Translate id="buy" />
              </p>

              <div className="social-icons">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/laylscom/"
                  className="social-icon"
                  title="Facebook">
                  <i className="icon-facebook-f"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/laylscom/"
                  className="social-icon"
                  title="Instagram">
                  <i className="icon-instagram"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-lg-3">
            <div className="widget">
              <h4 className="widget-title">
                <Translate id="AboutUs" />
              </h4>

              <ul className="widget-list">
                <li>
                  <a
                    href="/contact-us"
                    style={{
                      color: '#c96',
                    }}>
                    <Translate id="Are you a supplier" />
                  </a>
                </li>
                <li>
                  <a href="/about-us">
                    <Translate id="About Layls" />
                  </a>
                </li>
                {/* <li>
                    <a href="/faq">FAQ</a>
                  </li> */}
                <li>
                  <a href="/contact-us">
                    <Translate id="ContactUs" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-sm-6 col-lg-3">
            <div className="widget">
              <h4 className="widget-title">
                <Translate id="Customer Service" />
              </h4>

              <ul className="widget-list">
                <li>
                  <a href="/return-policy">
                    <Translate id="ReurnPolicy" />
                  </a>
                </li>
                {/* <li>
                  <a href="#r">Terms and conditions</a>
                </li> */}
                <li>
                  <a href="/privacy-policy">
                    <Translate id="useragreement" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="footer-bottom">
      <div className="container">
        <p className="footer-copyright">
          <Translate id="All Rights Reserved." /> <Translate id="Copyright" /> ©{' '}
          {moment().format('YYYY')} Layls.com
        </p>
        <figure className="footer-payments">
          <div
            style={{
              marginRight: 10,
              color: '#fff',
            }}>
            {SVG.cashDelivery}
          </div>
          <div
            style={{
              marginRight: 10,
              color: '#fff',
            }}>
            {SVG.visaDelivery}
          </div>
        </figure>
      </div>
    </div>
  </footer>
));
